import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {AnimationController, IonicModule, IonicRouteStrategy} from '@ionic/angular';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {PipesModule} from './pipes/pipes.module';

import {registerLocaleData} from '@angular/common';
import localeCl from '@angular/common/locales/es-CL';
import {ResenaModalPage} from './pages/resena-modal/resena-modal.page';
import {ResenaModalPageModule} from './pages/resena-modal/resena-modal.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoaderService} from './services/translate-loader.service';
import {ComponentsModule} from './components/components.module';
import {ServiceWorkerModule, SwUpdate} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule} from 'angularx-social-login';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from 'ng-recaptcha';
import {versions} from '../environments/versions';
import {QRCodeModule} from 'angular2-qrcode';
import {MyHammerConfig} from './hammer-config';
import {RollbarErrorHandler, rollbarFactory, RollbarService} from './services/rollbar.service';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers, Storage } from '@ionic/storage';

registerLocaleData(localeCl);

import {AmplifyUIAngularModule} from '@aws-amplify/ui-angular';
import Amplify from 'aws-amplify';
import awsconfig from '../aws-exports';
 

Amplify.configure(awsconfig);

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// console.log(versionInfo);
console.log(versions.hash);
console.log(environment.name);

@NgModule({
    declarations: [AppComponent],
    entryComponents: [ResenaModalPage],
    imports: [
        IonicStorageModule.forRoot({
            name: '_qrmenu1',
            driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
          }),
        BrowserModule,
        IonicModule.forRoot({navAnimation: _ => new AnimationController().create()}),
        AppRoutingModule,
        HttpClientModule,
        PipesModule,
        ComponentsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                // useFactory: (createTranslateLoader),
                useClass: TranslateLoaderService,
                deps: [HttpClient]
            }
        }),
        SocialLoginModule,
        ResenaModalPageModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.platform === 'pwa' && environment.production,
            registrationStrategy: 'registerImmediately'
        }),
        // ServiceWorkerModule.register('OneSignalSDKWorker.js', {enabled: environment.production}),
        AmplifyUIAngularModule,
        RecaptchaV3Module,
        QRCodeModule,
    ],
    providers: [
        SwUpdate,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(
                            '1081183396258-n2gbsqbqcp9uuvl4qhncu95u7j5h75ft.apps.googleusercontent.com'),
                    },
                    {
                        id: FacebookLoginProvider.PROVIDER_ID,
                        provider: new FacebookLoginProvider('297972394967919'),
                    },
                ],
            } as SocialAuthServiceConfig,
        },
        {provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LcHi9AZAAAAANfJI9QvyFXsd2ZN5KsDevGyE9-j'},
        //{provide: ErrorHandler, useClass: RollbarErrorHandler},
        //{provide: RollbarService, useFactory: rollbarFactory},
        {provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
