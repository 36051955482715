import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {IonSlides, NavController} from "@ionic/angular";

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements OnInit {


  @Output() viewChanged = new EventEmitter<number>();
  @Input() public tienda : any;
  /**
   * The change event that will be broadcast to the parent component when the user interacts with the component's
   * <ion-button> element
   * @public
   * @property change
   * @type {EventEmitter}
   */
  @Output()
  change : EventEmitter<string> = new EventEmitter<string>();


  /**
   * Determines and stores the accordion state (I.e. opened or closed)
   * @public
   * @property isMenuOpen
   * @type {boolean}
   */
  @Input() isMenuOpen : boolean = false;

  constructor(private navCtrl: NavController,) { }

  ngOnInit() {}

  /**
   * Allows the accordion state to be toggled (I.e. opened/closed)
   * @public
   * @method toggleAccordion
   * @returns {none}
   */
  public toggleAccordion(): void {
    //this.isMenuOpen = !this.isMenuOpen;
      this.viewChanged.emit(this.isMenuOpen ? null: this.tienda.id);
  }


  /**
   * Allows the value for the  element to be broadcast to the parent component
   * @public
   * @method broadcastName
   * @returns {none}
   */
  public broadcastName(name: string): void {
    this.change.emit(name);
  }
  
  goToStore(tienda){
    this.navCtrl.navigateForward(['/' , tienda.slugManual ? tienda.slugManual : tienda.slug  ])
  }

}
