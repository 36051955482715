import {Injectable} from '@angular/core';
import {DataService} from './data.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {UiService} from './ui.service';
import {AuthenticationService} from './authentication.service';
import * as moment from 'moment';
import {EventsService} from './events.service';
import {Orden, PageView} from '../interfaces/interfaces';
// import {FileTransfer, FileUploadOptions, FileTransferObject} from '@ionic-native/file-transfer/ngx';
// import {File} from "@ionic-native/file/ngx";
// import {FileChooser} from '@ionic-native/file-chooser/ngx';

import {Plugins} from '@capacitor/core';
import {environment} from '../../environments/environment';
const {Network} = Plugins;

const URL = environment.apiUrl;

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    token: string = null;

    constructor(private http: HttpClient,
                private dataService: DataService,
                private uiService: UiService,
                private authService: AuthenticationService,
                private eventsService: EventsService,
                // private transfer: FileTransfer,
                // private file: File
    ) {
    }

    async login(data, retries = 0) {
        console.log('LOGIN:', data);
        if (!await this.checkConnection()) {
            return false;
        }

        const email = data.email;
        const source = data.source;

        return new Promise(resolve => {

            this.http.post(`${URL}/login`, data)
                .subscribe(
                    async resp => {
                        console.log('LOGIN RESPONSE:', resp);
                        const respObject: any = resp;

                        const invitado = (email === 'api@takeat.cl' ? true : false);
                        const usuarioTienda = (source.indexOf('-tienda') !== -1 ? true : false);
                        // @ts-ignore
                        await this.dataService.setStorageData('invitado', invitado);
                        await this.dataService.setStorageData('usuarioTienda', usuarioTienda);
                        await this.guardarData(respObject);
                        let NoCambiar = false;
                            if (data.NoCambiar===true){
                                NoCambiar = true;
                          }
                        await this.guardarToken(respObject.token, invitado, NoCambiar);
                        if (invitado) {
                            this.eventsService.publish('user:loginInvitado', {});
                        }
                        // await this.guardarPersona(resp['persona']);
                        // await this.guardarColaboradores(resp['colaboradores']);
                        // await this.guardarUnidades(resp['unidades']);
                        resolve(true);
                    },
                    async (err) => {
                        await this.processError(err, 'login', null, data, retries);
                        this.token = null;
                        resolve(false);
                    });

        });

    }

    // async cargarToken2() {
    //     let idx = 0;
    //     let exit = false;
    //     while (!exit){
    //         idx++;
    //         this.executeToken();
    //         if (this.token || idx > 3){
    //             exit = true;
    //         }
    //         else{
    //             setTimeout(this.executeToken, 1000);
    //         }
    //     }
    // }

    async cargarToken(intentos = 0) {
        console.log('api-cargar-token');
        this.token = await this.dataService.getStorageData('token') || null;
       
        // console.log(this.token);
        if (!this.token) {
            this.token = await this.dataService.getStorageData('tokenInvitado') || null;
            console.log(this.token);

            if (!this.token) {
                console.log(intentos);
                if (intentos === 0) {
                    setTimeout(async () => {
                        const newIntentos = intentos + 1;
                        console.log(newIntentos);
                        await this.cargarToken(newIntentos);
                    }, 500);
                } else {
                    console.log('sin-token');
                    this.eventsService.publish('app:sin-token');
                }
            }
        }
    }

    async guardarToken(token: string, invitado,NoCambiar=false) {
        this.token = token;
        if (!invitado) {
            await this.authService.login(token,NoCambiar);
        } else {
            await this.dataService.setStorageData('tokenInvitado', token);
        }
    }

    async guardarData(resp) {
        const now = moment();
        await this.dataService.setStorageData('cargaDate', now.format('DD-MM-YYYY HH:mm:ss'));
        if (typeof resp.cupones !== 'undefined') {
            // console.log( now.format('DD-MM-YYYY HH:mm:ss'));
            //
            await this.dataService.setStorageData('usuario', resp.usuario);
            await this.dataService.setStorageData('oneclicks', resp.oneclicks);
            await this.dataService.setStorageData('mercadoPagoCards', resp.mercadoPagoCards);
            await this.dataService.setStorageData('direcciones', resp.direcciones);
            await this.dataService.storeCupones(resp.cupones);
        }
        if (typeof resp.tienda !== 'undefined') {
            const tienda = resp.tienda;
            if (tienda.slugManual && tienda.slugManual !== '') {
                tienda.slug = tienda.slugManual;
            }
            console.log('guardar tieda', tienda);
            await this.dataService.setStorageData('tienda', tienda);
            await this.dataService.setStorageData('tiendaWaitList', tienda);
            await this.dataService.setStorageData('slugQR', tienda.slug);
        }

        if (typeof resp.pedidos !== 'undefined') {
            await this.dataService.storePedidos(resp.pedidos);
        }

        if (typeof resp.ordenes !== 'undefined') {
            await this.dataService.storeOrdenes(resp.ordenes, false, 'login');
        }

        if (typeof resp.cupones !== 'undefined') {
            await this.dataService.storeCupones(resp.cupones);
        }

        if (typeof resp.notificaciones !== 'undefined') {
            await this.dataService.storeNotificaciones(resp.notificaciones);
        }

        console.log('pub data:update');
        this.eventsService.publish('data:update', {});
    }

    async guardarDataGenerico(responseData) {
        
        for (const key of Object.keys(responseData)) {
       
            const value = responseData[key];
            if (key !== 'mensaje') {
                if (key === 'pedidos') {
                    await this.dataService.storePedidos(value);
                } else if (key === 'ordenes') {
                    await this.dataService.storeOrdenes(value);
                } else {
                    console.log(key, value)
                    await this.dataService.setStorageData(key, value);
                }
            }
        }

        // console.log('pub data:update');
        // this.eventsService.publish('data:update');
    }

    async getTiendaSlug(slug:string): Promise<any> {
         if (!await this.checkConnection(false)) {
            return false;
        }

        await this.cargarToken();
        if (!this.token || this.token === '') {
            console.log('Sin token');
            await this.uiService.dismissLoading();
            // this.uiService.presentToast('Sin token');
            return false;
        }
        return new Promise(resolve => {
            const headers = new HttpHeaders({
                Authorization: 'Bearer ' + this.token
            });
            this.http.get(`${URL}/jwt/tienda/slug/${slug}`, {headers})
                .subscribe(
                    async resp => {
                        const respObject: any = resp;
                        console.log(respObject);
                        resolve(respObject);
                    },
                    async (err) => {
                        resolve(err);
                    }
            );
        });
    }

    async get(url, retries = 0): Promise<boolean> {
        console.log('GET:', url);
        if (!await this.checkConnection(false)) {
            return false;
        }

        await this.cargarToken();
        if (!this.token || this.token === '') {
            console.log('Sin token');
            await this.uiService.dismissLoading();
            // this.uiService.presentToast('Sin token');
            return false;
        }

        return new Promise<boolean>(resolve => {
            const headers = new HttpHeaders({
                Authorization: 'Bearer ' + this.token
            });

            // console.log(`${URL}` + url, headers);

            this.http.get(`${URL}` + url, {headers})
                .subscribe(async resp => {
                        console.log('GET RESPONSE:', url, resp);
                        await this.dataService.setData('postResponse', resp);
                        // return resp;
                        // @ts-ignore
                        if (typeof resp.tiendas !== 'undefined') {
                            // await this.dataService.storeContratoApoyo(resp.contratoApoyo);
                            // @ts-ignore
                            await this.dataService.storeTiendas(resp.tiendas);
                        }

                        // @ts-ignore
                        if (typeof resp.categorias !== 'undefined') {
                            // await this.dataService.storeContratoApoyo(resp.contratoApoyo);
                            // @ts-ignore
                            await this.dataService.storeTienda(resp);
                        }
                        //
                        if (url === '/jwt/oneclick/') {
                            await this.dataService.setStorageData('oneclicks', resp);
                        }

                        // @ts-ignore
                        if (typeof resp.ordenQRPago !== 'undefined') {
                            // await this.dataService.storeContratoApoyo(resp.contratoApoyo);
                            // @ts-ignore
                            await this.dataService.setStorageData('ordenQRPago', resp.ordenQRPago);
                        }

                        if (['/jwt/direccion/'].indexOf(url) !== -1 || url.startsWith('/jwt/tienda/multiple/') || url.startsWith('/jwt/mercadopago')) {
                            await this.guardarDataGenerico(resp);
                        }

                        if (['/jwt/espera/list'].indexOf(url) !== -1) {
                            await this.guardarDataGenerico(resp);
                        }
                        

                        if (url.startsWith('/espera')) {
                            await this.guardarDataGenerico(resp);
                        }
                        if (url.indexOf('aforo') !== -1) {
                            await this.guardarDataGenerico(resp);
                        }
                        if (url.indexOf('cupon') !== -1) {
                            await this.guardarDataGenerico(resp);
                        }
                        if (url.indexOf('tienda/cliente') !== -1) {
                            await this.guardarDataGenerico(resp);
                        }

                        //
                        // if (typeof resp.planDiaD !== 'undefined') {
                        //   const paciente = await this.dataService.getStorageData('paciente');
                        //   paciente.diaD = resp.diaD;
                        //   await this.dataService.setStorageData('paciente', paciente);
                        //
                        //   await this.dataService.storePlanDiaD(resp.planDiaD);
                        // }
                        //
                        // if (typeof resp.sindromeEjercicio !== 'undefined') {
                        //   await this.dataService.storeSindromeEjercicio(resp.sindromeEjercicio);
                        // }
                        //
                        // if (typeof resp.gatillantes !== 'undefined') {
                        //   await this.dataService.setStorageData('gatillantes', resp.gatillantes);
                        //   await this.dataService.setStorageData('cigarrosAbstinencia', resp.cigarrosAbstinencia);
                        // }

                        resolve(true);
                    },
                    async (err) => {
                        await this.processError(err, 'get', url, null, retries);
                        resolve(false);
                    }
                );
        });
    }

    async post(url, data, retries = 0): Promise<boolean> {
        console.log('POST:', url, data);
        if (!await this.checkConnection(false)) {
            return false;
        }

        await this.cargarToken();
        if (!this.token || this.token === '') {
            return false;
        }

        return new Promise<boolean>(resolve => {
            const headers = new HttpHeaders({
                Authorization: 'Bearer ' + this.token
            });

            this.http.post(`${URL}` + url, data, {headers})
                .subscribe(async resp => {
                        console.log('POST RESPONSE:', url, resp);
                        const respObject: any = resp;
                        await this.dataService.setData('postResponse', resp);

                        if (url === '/jwt/cupon/agregar') {
                            await this.dataService.storeCupones(resp);
                        } else if (url === '/jwt/pedido/' || url === '/jwt/pedido/new') {
                            resolve(respObject);
                            // await this.dataService.setStorageData('pedidos', resp);
                        } else if (url === '/jwt/usuario/data') {
                            await this.guardarData(resp);
                        } else if (url === '/social-auth') {
                            const invitado = false;
                            let NoCambiar = false;
                            if (data.NoCambiar===true){
                                NoCambiar = true;
                            }
                            await this.guardarToken(respObject.token, invitado, NoCambiar);
                            await this.dataService.setStorageData('invitado', invitado);
                            await this.guardarData(resp);
                        } else if (url === '/phone/verify-code') {
                            const invitado = false;
                            let NoCambiar = false;
                            if (data.NoCambiar===true){
                                NoCambiar = true;
                            }
                            await this.guardarToken(respObject.token, invitado,NoCambiar);
                            await this.dataService.setStorageData('invitado', invitado);
                            await this.guardarData(resp);
                        }

                        if (url !== '/social-auth' && url !== '/jwt/usuario/data') {
                            if (typeof respObject.pedidos !== 'undefined') {
                                await this.dataService.storePedidos(respObject.pedidos);
                            }

                            if (typeof respObject.ordenes !== 'undefined') {
                                const resena = typeof respObject.mostrarResena !== 'undefined' && respObject.mostrarResena;
                                await this.dataService.storeOrdenes(respObject.ordenes, resena, url);
                            }

                            if (typeof respObject.cupones !== 'undefined') {
                                await this.dataService.storeCupones(respObject.cupones);
                            }

                            if (typeof respObject.notificaciones !== 'undefined') {
                                await this.dataService.storeNotificaciones(respObject.notificaciones);
                            }

                            if (typeof respObject.usuario !== 'undefined') {
                                await this.dataService.setStorageData('usuario', respObject.usuario);
                            }
                        }

                        if (['/jwt/direccion/form'].indexOf(url) !== -1 || url.startsWith('/jwt/direccion/form') || url === '/jwt/usuario/editar' || url.startsWith('/jwt/mercadopago')) {
                            await this.guardarDataGenerico(resp);
                        }

                        if (url.startsWith('/espera')) {
                            await this.guardarDataGenerico(resp);
                        }
                        if (url == 'jwt/'){

                        }
                        resolve(true);
                    },
                    async (err) => {
                        await this.processError(err, 'post', url, data, retries);
                        resolve(false);
                    }
                );
        });
    }

    async processError(err, method, url, data, retries) {
        console.error(err);

        await this.uiService.dismissLoading();
        if (err.status === 401) {
            await this.dataService.setStorageData('tokenInvitado', null);
            this.eventsService.publish('user:logoutApp', false);
            this.dataService.setData('error401', true);
        } else if (err.status === 0 || err.status === 504) {
            if (retries > 2) {
                await this.uiService.presentAlert('Hubo un problema de comunicación con el servidor, por favor reintente.', 'Error');
            } else {
                retries++;
                switch (method) {
                    case 'login':
                        await this.login(data, retries);
                        break;
                    case 'refreshData':
                        await this.refreshData(retries);
                        break;
                    case 'get':
                        await this.get(url, retries);
                        break;
                    case 'postx':
                        await this.post(url, data, retries);
                        break;
                    case 'put':
                        await this.put(url, data, retries);
                        break;
                    case 'delete':
                        await this.delete(url, retries);
                        break;
                }
            }
        } else {
            await this.uiService.presentErrorAlert(err);
        }
    }

    async put(url, data, retries = 0): Promise<boolean> {
        console.log('PUT:', url, data);
        if (!await this.checkConnection(false)) {
            return false;
        }

        await this.cargarToken();
        if (!this.token || this.token === '') {
            return false;
        }

        return new Promise<boolean>(resolve => {
            const headers = new HttpHeaders({
                Authorization: 'Bearer ' + this.token
            });

            this.http.put(`${URL}` + url, data, {headers})
                .subscribe(async resp => {
                        console.log('PUT RESPONSE:', url, resp);

                        await this.guardarDataGenerico(resp);
                        resolve(true);
                    },
                    async (err) => {
                        await this.processError(err, 'put', url, data, retries);
                        resolve(false);
                    }
                );
        });
    }

    async delete(url, retries = 0): Promise<boolean> {
        console.log('DELETE:', url);
        if (!await this.checkConnection(false)) {
            return false;
        }

        await this.cargarToken();
        if (!this.token) {
            return false;
        }

        return new Promise<boolean>(resolve => {
            const headers = new HttpHeaders({
                Authorization: 'Bearer ' + this.token
            });

            this.http.delete(`${URL}` + url, {headers})
                .subscribe(async resp => {
                        console.log('DELETE RESPONSE:', url, resp);

                        await this.guardarDataGenerico(resp);
                        resolve(true);
                    },
                    async (err) => {
                        await this.processError(err, 'delete', url, null, retries);
                        resolve(false);
                    }
                );
        });
    }

    async refreshData(retries = 0): Promise<boolean> {
        if (!await this.checkConnection()) {
            return false;
        }

        await this.cargarToken();

        return new Promise<boolean>(resolve => {
            const headers = new HttpHeaders({
                Authorization: 'Bearer ' + this.token
            });

            this.http.get(`${URL}/jwt/usuario/data`, {headers})
                .subscribe(async resp => {
                        console.log(resp);
                        await this.guardarData(resp);
                        resolve(true);
                    },
                    async (err) => {
                        await this.processError(err, 'refreshData', null, null, retries);
                        // this.token = null;
                        resolve(false);
                    }
                );
        });
    }

    async isConnected() {
        const status = await Network.getStatus();
        const conntype = status.connectionType;

        // console.log(conntype);
        return conntype && conntype !== 'unknown' && conntype !== 'none';
    }

    async checkConnection(showMessage = true) {
        const tipo = await this.dataService.getStorageData('tipo');
        let connected = true;

        if (tipo !== 'browser-serve' && tipo !== 'web') {
            connected = await this.isConnected();
            if (!connected && showMessage) {
                const message = 'Sin conexión';
                await this.uiService.dismissLoading();
                await this.uiService.presentToast(message);
            }
        }

        return connected;
    }

    setPageView(pageView: PageView) {
        return this.http.post<any>(`${URL}/pageviews/add`, pageView).toPromise();
    }

    setPageViewMultiple(pageView: PageView[]) {
        const data = {
            views: pageView
        };
        return this.http.post<any>(`${URL}/pageviews/multiple`, data).toPromise();
    }

    async updateVisits() {
        const ss = await this.dataService.getStorageData('session_id');
        const us = await this.dataService.getStorageData('usuario');
        const data = {
            user: us.id,
            uid: ss
        };
        return this.http.post<any>(`${URL}/pageviews/update`, data).toPromise();
    }

    async getPedidoQR(qr:any) {
        await this.cargarToken();
        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + this.token
        });
        return this.http.post<any>(`${URL}/jwt/pedido/byqr/${qr}`, {}, {headers}).toPromise();
    }

}
