import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FacebookLoginProvider, GoogleLoginProvider, SocialAuthService, SocialUser} from 'angularx-social-login';
import {DataService} from '../../services/data.service';
import {ApiService} from '../../services/api.service';
import {UiService} from '../../services/ui.service';
import {EventsService} from '../../services/events.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-social-login',
    templateUrl: './social-login.component.html',
    styleUrls: ['./social-login.component.scss'],
})
export class SocialLoginComponent implements OnInit, OnDestroy {
    @Input() showRegistro = true;
    @Input() isModal = false;
    subscriptions: (Subscription | any)[] = [];
    user: SocialUser;
    loggedIn: boolean;
    first: boolean = true;
    logoutSubscription: Subscription;
    googleEnable = true;

    constructor(private authService: SocialAuthService,
                private dataService: DataService,
                private apiService: ApiService,
                private eventsService: EventsService,
                private uiService: UiService) {

    }

    signInWithGoogle(): void {
         
            this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
            this.googleEnable = false;
         
    }

    signInWithFB(): void {
        this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
    }

    signOut(): void {
        this.authService.signOut(true);
        this.logoutSubscription.unsubscribe();
    }


    ngOnInit() {
        console.log('ismodal', this.isModal)
        this.initData();
    }

    ngOnDestroy() {
        console.log('social destroy');
        this.subscriptions.forEach((subscription: Subscription, index: number) => {
            subscription.unsubscribe();
        });
    }

    async initData() {
        console.log('social subscribe');
        this.subscriptions.push(this.authService.authState.subscribe((user) => {
            this.user = user;
            this.loggedIn = (user != null);
            console.log(user);

            if (this.loggedIn) {
                this.signIn(user);
            }
        }));

        this.subscriptions.push(this.authService.initState.subscribe(() => {
        }, console.error, () => {
            console.log('all providers are ready');
        }));

        this.logoutSubscription = this.eventsService.subscribe('user:logout', () => {
            if (this.loggedIn) {
                this.signOut();
            }
        });
    }

    async signIn(user: SocialUser) {
        const loading = await this.uiService.presentLoading('Iniciando sesión...');

        const tipo = await this.dataService.getStorageData('tipo');
        const pushToken = await this.dataService.getStorageData('pushToken');
        const pushSubscribed = await this.dataService.getStorageData('pushSubscribed');
        const sessionId = await this.dataService.getStorageData('sessionId');

        const id = user.id;
        const email = user.email;
        const firstName = user.firstName;
        const lastName = user.lastName;
        const authToken = user.idToken || user.authToken;
        // const authToken = 'testerror';
        const provider = user.provider;
        const source = 'qr-menu-app';
        const NoCambiar = this.isModal;
        const lite = true;
        const data = {id, email, firstName, lastName, authToken, provider, source, tipo, pushToken, pushSubscribed, sessionId, NoCambiar, lite};
        const result = await this.apiService.post('/social-auth', data);
        if (result) {
            this.apiService.updateVisits();
            console.log('pub user:login');
            this.eventsService.publish('user:login', result);
            // this.router.navigate(['restaurantes']);
        }
        await loading.dismiss();
    }

}
