import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-modal-agregados',
  templateUrl: './modal-agregados.component.html',
  styleUrls: ['./modal-agregados.component.scss'],
})
export class ModalAgregadosComponent implements OnInit {

  dragging: any;

  @Output() modalState = new EventEmitter<any>();
  @Input() state: string;

  constructor() { }

  ngOnInit() {}

  @ViewChild('content') content: ElementRef

  @HostListener('document:click', ['$event'])
  andClickEvent(event) {
    if (!this.content.nativeElement.contains(event.target)) {
      if(this.state == 'open')
        this.modalState.emit('close');
    }
  }

}
