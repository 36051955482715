import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProductoRecomendado, RecomendationService} from '../../services/recomendation-service.service';
import {DataService} from '../../services/data.service';

@Component({
    selector: 'app-recomendaciones-hot',
    templateUrl: './recomendaciones-hot.component.html',
})
export class RecomendacionesHotComponent implements OnInit {
    @Output() getProducts: EventEmitter<any> = new EventEmitter();
    @Input() store: number;
    @Input() disabled: boolean;
    products: ProductoRecomendado[] = [];

    constructor(private dataService: DataService,
                private recomendationService: RecomendationService) {
    }

    async ngOnInit() {
        console.log('recomendados', this.products);
        if (this.store) {
            this.recomendationService.hotsRecomendation(this.store).then((response) => {
                this.products = response;
                console.log('recomendados response', this.products);
                // return;
                this.getProducts.emit(this.products);
            });
        }
    }

}
