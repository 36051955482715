import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-filtro-carta',
  templateUrl: './filtro-carta.component.html',
  styleUrls: ['./filtro-carta.component.scss'],
})
export class FiltroCartaComponent implements OnInit {

  filtros: any[];
  precios: any[];
  constructor() { }

  ngOnInit() {
    this.filtros = [
      {
        name: 'Zona Geografica',
        items: ['ITALIANA','ORIENTAL','CHILENA','MEXICANA','LATINA']
      },
      {
        name: 'Alérgenos',
        items: ['GLUTEN','CRUSTACEO','HUEVO','PESCADO','LECHE','NUECES','SULFITO','MANUSOYA']
      }
    ]

    this.precios = [
        {
          name: '$',
          min: 0,
          max: 5000
        },
      {
        name: '$$',
        min: 5000,
        max: 10000
      },
      {
        name: '$$',
        min: 10000,
        max: 999999999999
      }
    ]
  }

}
