import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import { environment } from 'src/environments/environment';
import {AuthGuard} from './guards/auth.guard';

const routesReserva: Routes = [ 

   
    {
        path: 'reservas/:slug',
        loadChildren: () => import('./pages/reservas/reservas.module').then( m => m.ReservasPageModule)
    },
    {
        path: ':slug/:codigo',
        loadChildren: () => import('./pages/reserva-navidad/reserva-navidad.module').then( m => m.ReservaNavidadPageModule)
    },
    {
        path: ':slug',
        loadChildren: () => import('./pages/reserva-navidad/reserva-navidad.module').then( m => m.ReservaNavidadPageModule)
    },
    {
        path: 'mis-reservas/:id/:telefono/:fecha',
        loadChildren: () => import('./pages/mis-reservas/mis-reservas.module').then( m => m.MisReservasPageModule)
    },
    {
        path: '**',
        loadChildren: () => import('./pages/reserva-navidad/reserva-navidad.module').then( m => m.ReservaNavidadPageModule),
        pathMatch: 'full'
    },
];

const routes: Routes = [
    // {
    //     path: '',
    //     redirectTo: 'home',
    //     pathMatch: 'full'
    // },

    {
        path: '',
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
    },
    {
        path: 'landing-mesa/:slug/:codigo',
        loadChildren: () => import('./pages/landing-mesa/landing-mesa.module').then(m => m.LandingMesaPageModule)
    },
    {
        path: 'folder/:id',
        loadChildren: () => import('./folder/folder.module').then(m => m.FolderPageModule)
    },
    {
        path: 'check/:type',
        loadChildren: () => import('./pages/check-code/check-code.module').then( m => m.CheckCodePageModule)
    },
    {
        path: 'waiting',
        loadChildren: () => import('./pages/waiting/waiting.module').then( m => m.WaitingPageModule)
    },
    {
        path: 'gamificacion/:tipo',
        loadChildren: () => import('./pages/gamificacion/gamificacion.module').then( m => m.GamificacionPageModule)
      },
    {
        path: 'waiting-list',
        loadChildren: () => import('./pages/waiting-list/waiting-list.module').then( m => m.WaitingListPageModule)
    },
   {
       path: 'softlogin',
       loadChildren: () => import('./pages/softlogin/softlogin.module').then( m => m.SoftloginPageModule)
   },
    {
        path: 'softlogin-email',
        loadChildren: () => import('./pages/softlogin-email/softlogin-email.module').then( m => m.SoftloginEmailPageModule)
    },
    {
        path: 'softlogin/:type',
        loadChildren: () => import('./pages/softlogin-pass/softlogin-pass.module').then( m => m.SoftloginPassPageModule)
    },
   {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
    },
    {
        path: 'login/:option',
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
    },
    {
        path: 'olvide-contrasena',
        loadChildren: () => import('./pages/olvide-contrasena/olvide-contrasena.module').then(m => m.OlvideContrasenaPageModule)
    },
    {
        path: 'olvide-contrasena/:option',
        loadChildren: () => import('./pages/olvide-contrasena/olvide-contrasena.module').then(m => m.OlvideContrasenaPageModule)
    },
    // {
    //     path: 'restaurantes',
    //     loadChildren: () => import('./pages/tienda-lista/tienda-lista.module').then(m => m.TiendaListaPageModule)
    // },
    {
        path: 'item-form/:entity/:id',
        loadChildren: () => import('./pages/item-form/item-form.module').then(m => m.ItemFormPageModule)
    },
    {
        path: 'item-lista',
        loadChildren: () => import('./pages/item-lista/item-lista.module').then(m => m.ItemListaPageModule)
    },
    {
        path: 'qr-scanner',
        loadChildren: () => import('./pages/qr-scanner/qr-scanner.module').then(m => m.QrScannerPageModule)
    },
    {
        path: 'mesa-asociar/:slug/:codigo',
        loadChildren: () => import('./pages/mesa-asociar/mesa-asociar.module').then(m => m.MesaAsociarPageModule)
    },
    {
        path: 'registro',
        loadChildren: () => import('./pages/registro/registro.module').then(m => m.RegistroPageModule)
    },
    {
        path: 'secured',
        canActivate: [AuthGuard],
        loadChildren: () => import('./secured/secured-routing.module').then(m => m.SecuredRoutingModule)
    },

    {
        path: 'carrito-pedido',
        loadChildren: () => import('./pages/carrito-pedir/carrito-pedir.module').then( m => m.CarritoPedirPageModule)
    },
    {
        path: 'carrito-pedido/:id',
        loadChildren: () => import('./pages/carrito-pedir/carrito-pedir.module').then( m => m.CarritoPedirPageModule)
    },
    {
        path: 'carrito-pagar',
        loadChildren: () => import('./pages/carrito-pagar/carrito-pagar.module').then( m => m.CarritoPagarPageModule)
    },
    {
        path: 'carrito-pagar/:id',
        loadChildren: () => import('./pages/carrito-pagar/carrito-pagar.module').then( m => m.CarritoPagarPageModule)
    },
    {
        path: 'orden-lista',
        loadChildren: () => import('./pages/orden-lista/orden-lista.module').then(m => m.OrdenListaPageModule)
    },
    {
        path: 'orden-lista/:slug',
        loadChildren: () => import('./pages/orden-lista/orden-lista.module').then(m => m.OrdenListaPageModule)
    },
    {
        path: 'orden-ver',
        loadChildren: () => import('./pages/orden-ver/orden-ver.module').then(m => m.OrdenVerPageModule)
    },
    {
        path: 'orden-ver/:id',
        loadChildren: () => import('./pages/orden-ver/orden-ver.module').then(m => m.OrdenVerPageModule)
    },
    {
        path: 'orden-crear',
        loadChildren: () => import('./pages/carrito-pagar/carrito-pagar.module').then( m => m.CarritoPagarPageModule)
    },
    {
        path: 'orden-crear/:id',
        loadChildren: () => import('./pages/carrito-pagar/carrito-pagar.module').then( m => m.CarritoPagarPageModule)
    },
    {
        path: 'orden-pago',
        loadChildren: () => import('./pages/orden-pago/orden-pago.module').then( m => m.OrdenPagoPageModule)
    },
    {
        path: 'cupon-lista',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/cupon-lista/cupon-lista.module').then(m => m.CuponListaPageModule)
    },
    {
        path: 'pedido-lista',
        loadChildren: () => import('./pages/pedido-lista/pedido-lista.module').then(m => m.PedidoListaPageModule)
    },
    {
      path: 'pedidos-home',
      loadChildren: () => import('./pages/pedidos/pedidos.module').then( m => m.PedidosPageModule)
    },
    {
        path: 'pedidos-tablet',
        loadChildren: () => import('./pages/pedidos-tablet/pedidos-tablet.module').then( m => m.PedidosTabletPageModule)
    },
    {
        path: 'pedidos-historial',
        loadChildren: () => import('./pages/pedidos-historial/pedidos-historial.module').then( m => m.PedidosHistorialPageModule)
    },
    {
        path: 'reserva-navidad/:slug',
        loadChildren: () => import('./pages/reserva-navidad/reserva-navidad.module').then( m => m.ReservaNavidadPageModule)
    },
    {
        path: 'reservas',
        loadChildren: () => import('./pages/reservas/reservas.module').then( m => m.ReservasPageModule)
    },
    {
        path: 'notificaciones',
        loadChildren: () => import('./pages/notificaciones/notificaciones.module').then(m => m.NotificacionesModule)
    },
    {
        path: 'notificacion-lista',
        loadChildren: () => import('./pages/notificacion-lista/notificacion-lista.module').then(m => m.NotificacionListaPageModule)
    },
    {
        path: ':slug/carta-slides/:id',
        loadChildren: () => import('./pages/producto-slides/producto-slides.module').then(m => m.ProductoSlidesPageModule)
    },
    {
        path: ':slug/carta',
        loadChildren: () => import('./pages/producto-lista/producto-lista.module').then(m => m.ProductoListaPageModule)
    },
    {
        path: ':slug/restaurantes',
        loadChildren: () => import('./pages/tienda-lista/tienda-lista.module').then(m => m.TiendaListaPageModule)
    },
    /*{
        path: 'old/:slug',
        loadChildren: () => import('./pages/tienda-ver/tienda-ver.module').then(m => m.TiendaVerPageModule)
    },
    {
        path: 'old/:slug/:codigo',
        loadChildren: () => import('./pages/tienda-ver/tienda-ver.module').then(m => m.TiendaVerPageModule)
    },*/
    {
      path: ':slug',
      loadChildren: () => import('./pages/tienda-home/tienda-home.module').then( m => m.TiendaHomePageModule)
    },
    {
      path: ':slug/:codigo',
      loadChildren: () => import('./pages/tienda-home/tienda-home.module').then( m => m.TiendaHomePageModule)
    },
    // {
    //     path: 'resena-modal',
    //     loadChildren: () => import('./pages/resena-modal/resena-modal.module').then(m => m.ResenaModalPageModule)
    // },
    // {
    //     path: 'pedido-ver',
    //     loadChildren: () => import('./pages/pedido-ver/pedido-ver.module').then( m => m.PedidoVerPageModule)
    // },
    {
        path: '**',
        redirectTo: '',
        pathMatch: 'full'
    },
    {
        path: 'habitacion-form',
        loadChildren: () => import('./pages/habitacion-form/habitacion-form.module').then(m => m.HabitacionFormPageModule)
    },
    {
        path: 'erut-form',
        loadChildren: () => import('./pages/erut-form/erut-form.module').then(m => m.ErutFormPageModule)
    },
  {
    path: 'buscar-home',
    loadChildren: () => import('./pages/buscar-home/buscar-home.module').then( m => m.BuscarHomePageModule)
  },
  {
    path: 'producto-detalle',
    loadChildren: () => import('./pages/producto-detalle/producto-detalle.module').then( m => m.ProductoDetallePageModule)
  },
  {
    path: 'carrito-compra',
    loadChildren: () => import('./pages/carrito-compra/carrito-compra.module').then( m => m.CarritoCompraPageModule)
  },
  {
    path: 'gamificacion-registro',
    loadChildren: () => import('./pages/gamificacion-registro/gamificacion-registro.module').then( m => m.GamificacionRegistroPageModule)
  },
  {
    path: 'nuevo-pedido',
    loadChildren: () => import('./pages/nuevo-pedido/nuevo-pedido.module').then( m => m.NuevoPedidoPageModule)
  },
  {
    path: 'pedido-detalle',
    loadChildren: () => import('./pages/pedido-detalle/pedido-detalle.module').then( m => m.PedidoDetallePageModule)
  },
  {
    path: 'informe-cliente',
    loadChildren: () => import('./pages/informe-cliente/informe-cliente.module').then( m => m.InformeClientePageModule)
  }, 

  {
    path: 'compartir',
    loadChildren: () => import('./pages/compartir/compartir.module').then( m => m.CompartirPageModule)
  },   {
    path: 'otra-tienda',
    loadChildren: () => import('./pages/otra-tienda/otra-tienda.module').then( m => m.OtraTiendaPageModule)
  },
  {
    path: ':slug/tienda/info',
    loadChildren: () => import('./pages/tienda-info/tienda-info.module').then( m => m.TiendaInfoPageModule)
  },
  {
    path: 'reservas',
    loadChildren: () => import('./pages/reservas/reservas.module').then( m => m.ReservasPageModule)
  },

];

@NgModule({
    imports: [
        RouterModule.forRoot(environment.type == 'reserva' ? routesReserva : routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
