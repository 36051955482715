import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { Reserva, Tienda } from 'src/app/interfaces/interfaces';
import { DataService } from 'src/app/services/data.service';
import { ReservaService } from 'src/app/services/reserva.service';

@Component({
  selector: 'app-formulario-reserva',
  templateUrl: './formulario-reserva.component.html',
  styleUrls: ['./formulario-reserva.component.scss'],
})
export class FormularioReservaComponent implements OnInit {
  @Input() tienda: Tienda;
  @Input() fecha: any;
  @Input() tipo: any;
  @Input() data: any;
  form: FormGroup;
  reserva : Reserva =  {
    tienda: 0,
    usuario: 0,
    lugar: 0,
    tipoReserva: 0,
    fecha: null,
    duracion: 0,
    estado : "",
    precio: 0,
    observaciones: "",
    personas: 1,
    telefono: "",
    nombre: "",
    correo: "",
    rut: "",
    mesa: 0,
    aceptaRecibirInfo: true
  }
  submitted = false;
  tipoReservas : any[];
  lugares: any[];
  mesas: any[];
  rutValido: boolean;
  loading: boolean;

  constructor(
    private reservaService: ReservaService,
    private modalController: ModalController,
    private dataService: DataService,
    private formBuilder: FormBuilder,
    private alertController: AlertController,
  ) { }

  async ngOnInit() {    

    this.tipoReservas = await this.reservaService.getTipoReservas(this.tienda.id);
    this.lugares = await this.reservaService.getLugares(this.tienda.id);
    this.mesas = await this.reservaService.getMesas(this.tienda.id);
    this.form = this.formBuilder.group({
      nombre:       ['', [Validators.required, Validators.minLength(3)]],
      correo:       ['', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      telefono:     ['', [Validators.required, Validators.pattern('^[0-9]+$'), Validators.minLength(9), Validators.maxLength(9)]],
      rut:          ['', Validators.required],
      fecha:        [this.fecha, [Validators.required]],
      tienda:       [this.tienda.id, [Validators.required]],
      tipoReserva:  [this.tipoReservas[0].id, [Validators.required]],
      aceptaRecibirInfo: [true],
      observaciones:[''],
      personas:     [1],
      estado:       [''],
      lugar:        [this.tipo == 'lugares' ? this.data.id : null],
      mesa:         [this.tipo == 'mesas' ? this.data.id : null],
      codTel:       56
    });
    
    this.form.get('correo').valueChanges.subscribe((event) => {
      this.form.get('correo').setValue(event.replace(/ /g, '').toLowerCase(), {emitEvent: false});
   })

  }

  get errorCtr() {
    return this.form.controls;
  }

  async onSubmit(reserva: Reserva){
    this.submitted = true;
    console.log(this.form.value);
    
    if (this.form.valid && this.rutValido) {
      this.loading = true;
      reserva.fecha = moment(this.fecha).format('DD-MM-YYYY HH:mm');
      reserva.telefono = `${this.form.value.codTel}${this.form.value.telefono}`;
      const resp: any = await this.reservaService.reserva(reserva);
      console.log(resp);
      if (resp.success) {
        this.close('reload');
      }
      if (!resp.ok) {
        this.alertReservas('No se pudo agendar tu reserva',  resp.error.failed);
      }
      this.loading = false;
      return;
    }
    this.loading = false;

    console.log(reserva); // just to check if it worked 
  }

  async close(status){
    await this.modalController.dismiss({status});
  }

  validaRut() {
    
    let rutCompleto = this.form.value.rut;
    console.log(rutCompleto);
		if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutCompleto ))
			return false;
		var tmp 	= rutCompleto.split('-');
		var digv	= tmp[1]; 
		var rut 	= tmp[0];
		if ( digv == 'K' ) digv = 'k' ;
    console.log((this.dv(rut) == digv ));
    this.rutValido = (this.dv(rut) == digv );
    console.log(this.rutValido);
    
		return (this.dv(rut) == digv );
	}

  dv(T){
		var M=0,S=1;
		for(;T;T=Math.floor(T/10))
			S=(S+T%10*(9-M++%6))%11;
		return S?S-1:'k';
	}
  async alertReservas(title, str) {
    const alert = await this.alertController.create({
      header: title,
      message: str,
      buttons: ['OK']
    });
    await alert.present();
  }
}
