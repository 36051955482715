import {Component, Input, OnInit} from '@angular/core';
import {ProductoRecomendado} from "../../services/recomendation-service.service";
import {NavController} from "@ionic/angular";

@Component({
  selector: 'app-producto-recomendado',
  templateUrl: './producto-recomendado.component.html',
  styleUrls: ['./producto-recomendado.component.scss'],
})
export class ProductoRecomendadoComponent implements OnInit {

  @Input() producto: ProductoRecomendado;
  @Input() disabled: boolean;
  constructor(private navCtrl: NavController,) { }

  ngOnInit() {}

  goToProduct(){
    //if(!this.disabled)
      this.navCtrl.navigateForward('/item-form/producto/'+this.producto.itemId,{animated:false})
  }

}
