import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Banner } from 'src/app/interfaces/interfaces';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-productos-home',
  templateUrl: './productos-home.component.html',
  styleUrls: ['./productos-home.component.scss'],
})
export class ProductosHomeComponent implements OnInit {
  @Input() prod: any;
  @Input() bannersIds:any; 
  @Input() tienda:any; 
  @Output() viewProducto = new EventEmitter<number>();
  @Output() viewCarrito = new EventEmitter<number>();
  @Input() expanded: boolean;
  decimales = '1.0-0';
  locale = 'es-CL';
  simboloMoneda = '$';
  constructor(private dataService: DataService) { }

  ngOnInit() {
   
  }
  ionViewDidEnter() {
     
  }
  onImgError(event) { 
    event.target.src = this.tienda.fotoProductoNameS3 ? this.tienda.fotoProductoNameS3 : this.tienda.logoNameS3;
  }
  verProducto(prod){
    this.viewProducto.emit(prod);
  }
  addCarrito(prod){
    this.viewCarrito.emit(prod)
  }
  async bannerLink(banner: Banner) {
    if (banner.link){
      if (banner.link,banner.link.includes('item-form')){
        let prodlink = banner.link.split("/");
        console.log(prodlink,prodlink.length)
        let producto = await this.dataService.getProducto(prodlink[prodlink.length-1]);
        this.viewProducto.emit(producto);
        return;
      }
    }
    this.dataService.bannerLink(banner);
 }
isAbierto(entity, option = 'tienda') {
  return this.dataService.isAbierto(entity, option);
}
}
