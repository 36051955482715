import {AfterViewInit, Component, Inject, OnDestroy, OnInit, Renderer2} from '@angular/core';
import * as uuid from 'uuid';

import {
    AlertController,
    IonRouterOutlet,
    MenuController,
    ModalController,
    NavController,
    Platform
} from '@ionic/angular';
import {DataService} from './services/data.service';
import {AuthenticationService} from './services/authentication.service';
import * as moment from 'moment';
import {UiService} from './services/ui.service';
import {ApiService} from './services/api.service';
import {Router} from '@angular/router';
import {EventsService} from './services/events.service';
import {Alternativa, Appearance, Item, Orden, PageView, PageViewInfo, Pedido, Seleccion, Tienda, Usuario} from './interfaces/interfaces';
import {ResenaModalPage} from './pages/resena-modal/resena-modal.page';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../environments/environment';
import {firebase} from '@firebase/app';
import {APIService} from './API.service';
import {interval, Subscription} from 'rxjs';
import {SwUpdate} from '@angular/service-worker';
import {DOCUMENT, Location} from '@angular/common';

import {Observable, Observer, fromEvent, merge} from 'rxjs';
import {map} from 'rxjs/operators';
import PubSub from '@aws-amplify/pubsub';
import {versions} from '../environments/versions';
import {RecomendationService} from './services/recomendation-service.service';
import {UtilService} from './services/util.service';
import { Plugins } from '@capacitor/core';
import { NotificacionesPage } from './pages/notificaciones/notificaciones.page';

const S3 = 'https://d4i7e0edqqgwm.cloudfront.net/documents/';
//import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';


@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
    subscriptions: (Subscription | any)[] = [];
    iotSubscriptions = {
        usuario: null,
        tienda: null,
    };
    rtlSide = 'left';
    token;
    invitado;
    usuario: Usuario;
    first = false;
    usuarioTienda = false;
    sessionId;
    todos: Array<any>;
    tienda: Tienda;
    estadoConexion = 'Connected';
    internetConnection;
    pushSubscribed;
    intervalConnection: any;
    sinToken = false;
    production = environment.name;
    updatesString;
    version = versions.hash;
    currentUrl = '';
    tipo;
    cat:any = [];
    menuCat:boolean = false;
    private w3c = 1250;
    private w2c = 900;
    private backgroundArauco = '#FAA702';
    private backgroundQR = '#fcfcfb';
    public appearence = new Appearance();

    public selectedIndex = 0;
    public appPages = [];
    private pageViewInfo: PageViewInfo;

    // $: any;

    constructor(
        @Inject(DOCUMENT) private readonly doc: Document,
        private platform: Platform,
        private dataService: DataService,
        private authenticationService: AuthenticationService,
        private navCtrl: NavController,
        private router: Router,
        private menuCtrl: MenuController,
        private eventsService: EventsService,
        private uiService: UiService,
        private apiService: ApiService,
        private modalController: ModalController,
        private translate: TranslateService,
        private awsAPIService: APIService,
        public updates: SwUpdate,
        private renderer: Renderer2,
        private location: Location,
        private utils: UtilService,
        private recomendationService: RecomendationService
        // private routerOutlet: IonRouterOutlet
    ) {
        
        // console.log(window.location.href);
        this.currentUrl = window.location.href;
        // updates.available.subscribe(event => {
        //     console.log('current version is', event.current);
        //     console.log('available version is', event.available);
        // });
        // updates.activated.subscribe(event => {
        //     console.log('old version was', event.previous);
        //     console.log('new version is', event.current);
        // });
        //
        // updates.available.subscribe(event => {
        //     updates.activateUpdate().then(() => this.updateApp());
        // });
        // console.log(this.updates);
        if (updates.isEnabled) {
            // interval(1000 * 1 * 10).subscribe(() => updates.checkForUpdate()
            //     .then(() => {
            //         this.updatesString = 'checking for updates';
            //         console.log('checking for updates');
            //     }));

            updates.available.subscribe(event => {
                this.updatesString = 'A newer version is now available.';
                updates.activateUpdate().then(() => this.updateApp());
            });
            updates.checkForUpdate()
                .then(() => {
                    this.updatesString = 'checking for updates';
                    console.log('checking for updates');
                });
        } else {
            this.updatesString = 'Not enabled';
        }

        this.appearence.enable = !this.platform.is('hybrid') || !this.platform.is('cordova');


        this.initializeApp();

        if (this.appearence.enable) {
            // this.appearance();
            this.handleBackButtonPress();
            this.addResizeSubscription();
            this.addRouterSubscription();
        }
       
        
    }
    


    updatePrecio(producto, item) {
        let precio = parseFloat(producto.precio);
        producto.selecciones.forEach((seleccion: Seleccion, index: number) => {
            const iSeleccion = item.selecciones[seleccion.id];
            let checkeds = 0;
            // console.log(iSeleccion);
            seleccion.alternativas.forEach((alternativa: Alternativa, aIdx: number) => {
                const iAlternativa = item.alternativas[alternativa.id];
                if (!seleccion.multiple) {
                    // tslint:disable-next-line:radix
                    if (parseInt(iSeleccion.value) === iAlternativa.id) {
                        iAlternativa.cantidad = 1;
                    } else {
                        iAlternativa.cantidad = 0;
                    }
                }
                console.log('repetirPedido', iAlternativa);
                // tslint:disable-next-line:radix
                // if (iAlternativa.checked) {
                //     precio += alternativa.precio;
                //     checkeds++;
                // }
                precio  =  precio + ((alternativa.precio) * iAlternativa.cantidad);
                checkeds += iAlternativa.cantidad;
            });
            iSeleccion.checkeds = checkeds;
        });
        item.precio = precio;
    }

    getParameterByName(name, url = window.location.href) {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
   async ngAfterViewInit() {
        this.menuCtrl.enable(true, 'main-content');
        await this.dataService.setStorageData('telReserva',null);
        let qrp = this.getParameterByName('qrp');

        
            if (qrp!=null && qrp!=undefined && qrp!=''){
                await this.dataService.setStorageData('compartirID','');
                await this.dataService.setStorageData('compartirURL',qrp);
                 
            }

            let tel = this.getParameterByName('t');
            
        
            if (tel!=null && tel!=undefined && tel!=''){ 
                console.log('tel', tel)
                await this.dataService.setStorageData('telReserva',atob(tel));
                 
            }

        console.log('await this.menuCtrl.enable(true);');
        // This element never changes.
        // let ionapp = document.getElementsByTagName('ion-app')[0];
        //
        // window.addEventListener('keyboardDidShow', async (event) => {
        //     // Move ion-app up, to give room for keyboard
        //     let kbHeight: number = event['keyboardHeight'];
        //     let viewportHeight: number = $(window).height();
        //     let inputFieldOffsetFromBottomViewPort: number = viewportHeight - $(':focus')[0].getBoundingClientRect().bottom;
        //     let inputScrollPixels = kbHeight - inputFieldOffsetFromBottomViewPort;
        //
        //     // Set margin to give space for native keyboard.
        //     ionapp.style['margin-bottom'] = kbHeight.toString() + 'px';
        //
        //     // But this diminishes ion-content and may hide the input field...
        //     if (inputScrollPixels > 0) {
        //         // ...so, get the ionScroll element from ion-content and scroll correspondingly
        //         // The current ion-content element is always the last. If there are tabs or other hidden
        //         // ion-content elements, they will go above.
        //         let ionScroll = await $('ion-content').last()[0].getScrollElement();
        //         setTimeout(() => {
        //             $(ionScroll).animate({
        //                 scrollTop: ionScroll.scrollTop + inputScrollPixels
        //             }, 300);
        //         }, 300); // Matches scroll animation from css.
        //     }
        // });
        // window.addEventListener('keyboardDidHide', () => {
        //     // Move ion-app down again
        //     // Scroll not necessary.
        //     ionapp.style['margin-bottom'] = '0px';
        // });
    }

    updateApp() {
        // 1debugger;
        this.checkWelcome();
        window.location.reload();
        this.updatesString = 'The app is updating right now';
        console.log('The app is updating right now');

    }

    ngOnInit() {
        this.menuCtrl.enable(true, 'main-content');
        // if (this.updates.isEnabled) {
        //     this.updates.available.subscribe(event => {
        //         console.log('A newer version is now available. Refresh the page now to update the cache');
        //         this.updates.activateUpdate().then(() => this.updateApp());
        //     });
        //     this.updates.checkForUpdate();
        // }
        // importScripts('https://www.gstatic.com/firebasejs/5.7.3/firebase-app.js');
        // importScripts('https://www.gstatic.com/firebasejs/5.7.3/firebase-messaging.js');
        // firebase.initializeApp(environment.firebase);

        // const messaging = firebase.messaging()


        const path = window.location.pathname.split('folder/')[1];
        if (path !== undefined) {
            this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
        }
        this.dataSubscribe();

        this.createOnline$().subscribe(isOnline => {
            console.log('Internet connection:', isOnline);

            if (!this.internetConnection && isOnline && this.estadoConexion !== 'Connected') {
                console.log('Reconnected, reloading!');
                // 1debugger;
                this.checkWelcome();
                window.location.reload();
            }
            this.internetConnection = isOnline;
        });

        let intentosIot = 0;
        this.intervalConnection = setInterval(async () => {
            console.log('Internet connection:', this.internetConnection, this.estadoConexion);
            if (this.internetConnection && this.estadoConexion !== 'Connected') {
                intentosIot++;
                console.log(intentosIot);
                if ((this.usuarioTienda && intentosIot > 0) || intentosIot > 1) {
                    console.log('No connection, reloading!');
                    // 1debugger;
                    this.checkWelcome();
                    //window.location.reload();
                }
            }
        }, 60000);


        // setTimeout(function() {
        //     console.log('Reloading', this.translate);
        //
        //     this.translate.reloadLang('es');
        // }, 3000);

        // hola = aaa;
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription: Subscription, index: number) => {
            subscription.unsubscribe();
        });

        if (this.intervalConnection) {
            clearInterval(this.intervalConnection);
        }
        this.intervalConnection = null;
    }

    initializeApp() {
        this.platform.ready().then(async () => {
            await this.dataService.init();
       
            await this.setTema();
            // Observable.fromEvent(window, 'keyboardWillShow').subscribe(() => {
            //     alert('Keyboard is now open');
            // });
            // this.dataService.clearStorageData();
            // this.platform.keyboardDidShow.subscribe(ev => {
            //     // const { keyboardHeight } = ev;
            //     alert('ks');
            //     // Do something with the keyboard height such as translating an input above the keyboard.
            // });
            // window.addEventListener('keyboardDidShow', () => {
            //     alert("Keyboard is Shown");
            // });
            // window.addEventListener('ionKeyboardDidShow', () => {
            //     alert("Keyboard is Shown");
            // });
            this.dataService.setData('platform', this.platform);

            // this.OneSignal = window['OneSignal'] || [];
            // await this.loadData();
            await this.cargarToken();

            
            await this.tiendaSubscribe(this.tienda);

            let OSName = 'Unknown';
            if (window.navigator.userAgent.indexOf('Windows NT 10.0') !== -1) {
                OSName = 'Windows 10';
            }
            if (window.navigator.userAgent.indexOf('Windows NT 6.2') !== -1) {
                OSName = 'Windows 8';
            }
            if (window.navigator.userAgent.indexOf('Windows NT 6.1') !== -1) {
                OSName = 'Windows 7';
            }
            if (window.navigator.userAgent.indexOf('Windows NT 6.0') !== -1) {
                OSName = 'Windows Vista';
            }
            if (window.navigator.userAgent.indexOf('Windows NT 5.1') !== -1) {
                OSName = 'Windows XP';
            }
            if (window.navigator.userAgent.indexOf('Windows NT 5.0') !== -1) {
                OSName = 'Windows 2000';
            }
            if (window.navigator.userAgent.indexOf('Mac') !== -1) {
                OSName = 'Mac/iOS';
            }
            if (window.navigator.userAgent.indexOf('X11') !== -1) {
                OSName = 'UNIX';
            }
            if (window.navigator.userAgent.indexOf('Linux') !== -1) {
                OSName = 'Linux';
            }

            await this.dataService.setStorageData('OSName', OSName);

            let first = true;
            await this.pushData(first);
            first = false;

            const cargaDate = await this.dataService.getStorageData('cargaDate');
            const now = moment();
            if (!cargaDate) {
                await this.dataService.setStorageData('cargaDate', now.format('DD-MM-YYYY HH:mm:ss'));
            }
            const fechaCarga = moment(cargaDate, 'DD-MM-YYYY HH:mm:ss');
            const duration = moment.duration(now.diff(fechaCarga));
            const hours = duration.asHours();
            console.log('Recarga: ', hours, now, fechaCarga);

            if (hours > 4) {
                const loading = await this.uiService.presentLoading('Cargando datos...');
                const sessionId = await this.dataService.getStorageData('sessionId');
                const source = this.invitado ? 'admin' : 'qr-menu-app';
                const lite = true;
                const data = {source, sessionId, lite};
                console.log(data);
                const result = await this.apiService.post('/jwt/usuario/data', data);
                // if (result){
                //     this.eventsService.publish('data:update', null);
                // }
                loading.dismiss();
            }

            this.authenticationService.authenticationState.subscribe(async state => {
                // console.log(state);
                if (this.first) {
                    console.log(state);
                    if (state) {
                        // this.router.navigate(['main', 'home']);
                        // this.router.navigate(['restaurantes']);
                    }
                } else {
                    this.first = true;
                }

            });

            // listen to the service worker promise in index.html to see if there has been a new update.
// condition: the service-worker.js needs to have some kind of change - e.g. increment CACHE_VERSION.
//             window['isUpdateAvailable']
//                 .then(isAvailable => {
//                     if (isAvailable) {
//                         this.uiService.presentToast('Nueva versión disponible! Por favor recarga la página.');
//                         // const toast = this.toastCtrl.create({
//                         //   message: 'New Update available! Reload the webapp to see the latest juicy changes.',
//                         //   position: 'bottom',
//                         //   showCloseButton: true,
//                         // });
//                         // toast.present();
//                     }
//                 });

            // console.log(window.OneSignal);
            // window.OneSignal.getIds(function(ids) {
            //   alert(ids);
            //   // dataService.setStorageData('pushToken', ids.userId);
            //   // dataService.setStorageData('tipo', tipo);
            // });

            // window['oneSignalSubscribe']
            //     .then(userId => {
            //         console.log(userId);
            //     });
            // this.awsAPIService.ListTodos().then((evt) => {
            //     this.todos = evt.items;
            //     console.log(this.todos);
            // });
            //
            // this.awsAPIService.OnCreateTodoListener.subscribe((evt) => {
            //     const data = (evt as any).value.data.onCreateTodo;
            //     this.todos = [...this.todos, data];
            //     console.log(this.todos);
            // });

            console.log('translate', this.translate);
            // this.translate.setDefaultLang('es');

            const currentLang = await this.dataService.getStorageData('currentLang') || 'es';
            console.log('currentLang', currentLang);
            // await this.translate.reloadLang(currentLang);
            await this.translate.use(currentLang);

            // await this.processFondo();

        });
    }

    async pushSubscribedUpdate() {
        const loading = await this.uiService.presentLoading('Enviando datos...');

        const tipo = await this.dataService.getStorageData('tipo');
        const pushToken = await this.dataService.getStorageData('pushToken');
        const pushSubscribed = await this.dataService.getStorageData('pushSubscribed');
        const sessionId = await this.dataService.getStorageData('sessionId');

        const data = {tipo, pushToken, pushSubscribed, sessionId};
        console.log(data);
        const result = await this.apiService.post('/jwt/usuario/push-subscribed', data);
        loading.dismiss();

        if (result) {
            // this.eventsService.publish('usuario:update', null);
            // this.uiService.presentToast('Guardado exitósamente');
            // this.navCtrl.navigateBack('/main/home', {animated: true});
        }
    }

    async loginInvitado() {
        const tokenInvitado = await this.dataService.getStorageData('tokenInvitado');
        // console.log(tokenInvitado);
        if (!tokenInvitado || tokenInvitado === '') {
            // const loading = await this.uiService.presentLoading('Iniciando sesión...');

            const tipo = await this.dataService.getStorageData('tipo');
            const pushToken = await this.dataService.getStorageData('pushToken');
            const pushSubscribed = await this.dataService.getStorageData('pushSubscribed');
            const sessionId = await this.dataService.getStorageData('sessionId');

            const email = 'api@takeat.cl';
            const password = '1234';
            const source = 'admin';
            const lite = true;
            const isNew = true;
            const data = {email, password, source, tipo, pushToken, pushSubscribed, sessionId, lite, isNew};
            console.log(data);
            const valido = await this.apiService.login(data);
            // await loading.dismiss();
            await this.cargarToken();
            // this.router.navigate(['restaurantes']);
            // this.router.navigate(['login']);
        }
    }

    /*async addScript() {
        return new Promise<Event>((resolve, reject) => {
            const scriptURL = 'https://cdn.onesignal.com/sdks/OneSignalSDK.js';
            console.log(scriptURL);
            const head = this.doc.getElementsByTagName('head')[0];
            const script = this.doc.createElement('script');
            script.type = 'text/javascript';
            script.onload = resolve;
            script.onerror = reject;
            script.src = scriptURL;
            head.appendChild(script);
        });
    }*/

    async pushData(first: boolean) {
        let tipo = 'hybrid';
        console.log(this.platform.platforms());

        // window.plugins.OneSignal.getIds(function(ids) {
        //   // alert(ids.userId);
        //   dataService.setStorageData('pushToken', ids.userId);
        //   dataService.setStorageData('tipo', tipo);
        // });

        const sessionId = await this.dataService.getStorageData('sessionId');
        /*FingerprintJS.load({token: 'zksD3cl9B5YWjkhxZcGH'})
        .then(fp => fp.get())
        .then(result => console.log(result.visitorId))*/

   
        
        if (!sessionId) {
            await this.dataService.setStorageData('sessionId', this.uuidv4());
           
        }

        if (this.platform.is('cordova')) {
            if (this.platform.is('ios')) {
                tipo = 'ios';
            } else if (this.platform.is('android')) {
                tipo = 'android';
            }
            // OneSignal Code start:
            // Enable to debug issues:
            // window['plugins'].OneSignal.setLogLevel({logLevel: 4, visualLevel: 4});

            if (first) {
                const notificationOpenedCallback = (notificationData) => {
                    console.log('notificationOpenedCallback: ' + JSON.stringify(notificationData));
                    // alert('notificationOpenedCallback: ' + JSON.stringify(notificationData));
                    const focus = notificationData.notification.isAppInFocus;
                    // alert(focus);
                    if (!focus) {
                        // this.uiService.presentAlert(jsonData.notification.payload.body, jsonData.notification.payload.title);
                        if (typeof notificationData.payload.additionalData !== 'undefined' && typeof notificationData.payload.additionalData.events !== 'undefined') {
                            for (const event of notificationData.payload.additionalData.events) {
                                this.eventsService.publish(event);
                            }
                        }
                    }
                };

                // Set your iOS Settings
                const iosSettings = {};
                iosSettings['kOSSettingsKeyAutoPrompt'] = false;
                iosSettings['kOSSettingsKeyInAppLaunchURL'] = false;

                window['plugins'].OneSignal
                    .startInit('e1b902fd-cca9-4198-b3cd-e897fcfb77e6')
                    .handleNotificationOpened(notificationOpenedCallback)
                    .handleNotificationReceived((notificationData) => {
                        // alert("Notification Received:\n" + JSON.stringify(notificationData));
                        // console.log('Notification Received: ', notificationData);
                        console.log('Notification Received: ', notificationData.payload.additionalData);
                        if (typeof notificationData.payload.additionalData !== 'undefined' && typeof notificationData.payload.additionalData.events !== 'undefined') {
                            for (const event of notificationData.payload.additionalData.events) {
                                this.eventsService.publish(event);
                            }
                        }
                    })
                    .iOSSettings(iosSettings)
                    .inFocusDisplaying(window['plugins'].OneSignal.OSInFocusDisplayOption.Notification)
                    .endInit();

                // The promptForPushNotificationsWithUserResponse function will show the iOS push notification prompt. We recommend removing the following code and instead using an In-App Message to prompt for notification permission (See step 6)
                window['plugins'].OneSignal.promptForPushNotificationsWithUserResponse((accepted) => {
                    console.log('User accepted notifications: ' + accepted);
                });
            }

            const dataService = this.dataService;

            window['plugins'].OneSignal.getIds((ids) => {
                console.log(ids.userId);
                dataService.setStorageData('pushSubscribed', true);
                dataService.setStorageData('pushToken', ids.userId);
                dataService.setStorageData('tipo', tipo);
            });

        } else {
            if (!environment.production) {
                tipo = 'web';
            } else {
                tipo = 'web';
            }

            const OSName = await this.dataService.getStorageData('OSName');
            // if (false && (OSName !== 'Mac/iOS' || (!this.platform.is('ipad') && !this.platform.is('iphone')))) {
            //     // importScripts('https://www.gstatic.com/firebasejs/5.7.3/firebase-app.js');
            //
            //     // await this.addScript();
            //     const OneSignal = window.OneSignal || [];
            //     // console.log(OneSignal);
            //     OneSignal.push(async () => {
            //         const initOptions: any = {
            //             // tslint:disable-next-line:max-line-length
            //             appId: this.currentUrl.indexOf('araucofoodie.cl') !== -1 ? '226d5e6a-5692-4599-ae6d-6fc060893ea3' : environment.oneSignalId,
            //             promptOptions: {
            //                 slidedown: {
            //                     enabled: true,
            //                     autoPrompt: true,
            //                     timeDelay: 5,
            //                     // pageViews: 3
            //                     actionMessage: 'Para ver el estado de tu pedido, favor acepta notificaciones',
            //                     acceptButtonText: 'Activar',
            //                     cancelButtonText: 'Cancelar',
            //                 }
            //             },
            //             notifyButton: {
            //                 enable: true,
            //                 displayPredicate: () => {
            //                     return OneSignal.isPushNotificationsEnabled()
            //                         .then((isPushEnabled) => {
            //                             /* The user is subscribed, so we want to return "false" to hide the Subscription Bell */
            //                             return !isPushEnabled;
            //                         });
            //                 },
            //             }
            //         };
            //
            //         if (!environment.production) {
            //             initOptions.allowLocalhostAsSecureOrigin = true;
            //         }
            //
            //         // console.log(res);
            //
            //         const res = await OneSignal.init(initOptions);
            //         console.log(res);
            //         // OneSignal.showNativePrompt();
            //
            //         await OneSignal.isPushNotificationsEnabled().then(async (isSubscribed) => {
            //             this.pushSubscribed = isSubscribed;
            //             await this.dataService.setStorageData('pushSubscribed', isSubscribed);
            //         });
            //
            //         await OneSignal.getUserId().then(async (userId) => {
            //             console.log('OneSignal User ID:', userId);
            //             // (Output) OneSignal User ID: 270a35cd-4dda-4b3f-b04e-41d7463a2316
            //             await this.dataService.setStorageData('pushToken', userId);
            //             // await this.loginInvitado();
            //         });
            //
            //         await OneSignal.on('subscriptionChange', async (isSubscribed) => {
            //             this.pushSubscribed = isSubscribed;
            //             console.log('The users subscription state is now:', isSubscribed);
            //             if (isSubscribed) {
            //                 await OneSignal.getUserId().then(async (userId) => {
            //                     console.log('OneSignal User ID:', userId);
            //                     // (Output) OneSignal User ID: 270a35cd-4dda-4b3f-b04e-41d7463a2316
            //                     await this.dataService.setStorageData('pushToken', userId);
            //                 });
            //             }
            //             await this.dataService.setStorageData('pushSubscribed', isSubscribed);
            //             await this.pushSubscribedUpdate();
            //         });
            //
            //         await OneSignal.on('notificationDisplay', async (event) => {
            //             console.log('OneSignal notification displayed:', event);
            //         });
            //
            //         // OneSignal.once('notificationDisplay', (event) => {
            //         //     console.log(event);
            //         // });
            //         //
            //         // OneSignal.on('notificationDisplay', (event) => {
            //         //     console.log(event);
            //         // });
            //
            //         await this.pushSubscribedUpdate();
            //     });
            // }

            // OneSignal.push(() => {
            //     OneSignal.on('notificationDisplay', (event) => {
            //         console.warn('OneSignal notification displayed:', event);
            //     });
            //
            //     //This event can be listened to via the `on()` or `once()` listener
            // });
            // OneSignal.on('notificationDisplay', (event) => {
            //     console.warn('OneSignal notification displayed:', event);
            // });

            // OneSignal.sendTags({
            //     session: sessionId
            // });

            // await this.dataService.setStorageData('pushToken', '0');
            // You're testing in browser, do nothing or mock the plugins' behaviour.
            //
            // var url: string = 'assets/mock-images/image.jpg';
        }

        await this.dataService.setStorageData('tipo', tipo);
        this.tipo = tipo;
        console.log(this.tipo);
        // this.tipo = 'ios';

        // if (!environment.production && window.location.hostname !== 'localhost') {
        // }
        await this.loginInvitado();
        // console.log(window.location.hostname);

    }

    uuidv4() {
        // @ts-ignore
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            // tslint:disable-next-line:no-bitwise
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    async loadData() {

        // await this.dataService.getPersona().then(value => {
        //   this.persona = value;
        // });
        // // console.log(this.persona);
        //
        // await this.dataService.getUnidades().then(value => {
        //   this.unidades = value;
        // });
        // console.log(this.unidades);
    }

    async logout(logout = true) {
        // await this.menuCtrl.close();

        const loading = await this.uiService.presentLoading('Cargando datos...');
        let result = true;
        if (logout) {
            const pushToken = await this.dataService.getStorageData('pushToken');
            const data = {pushToken};
            console.log(data);
            result = await this.apiService.post('/jwt/usuario/logout', data);
        }

        if (result) {
            await loading.dismiss();
            await this.authenticationService.logout();
            await this.dataService.clearStorageData();
            this.token = null;
            this.invitado = null;
            this.usuario = null;
            this.usuarioTienda = null;
            // await this.loginInvitado();
            // this.navCtrl.navigateBack('/restaurantes', {animated: true});
            // await this.dataService.goHome(); 
            this.eventsService.publish('user:logout');
            // await this.menuCtrl.close();
            // await this.menuCtrl.enable(true, 'main-content');
            await this.cargarToken();
            // this.eventsService.publish('user:logout', null);
            /*this.menuCtrl.close();

            this.storage.set('token', null).then((value) => {
                this.nav.setRoot(LoginPage);
            }).catch((empty) => {
                //this.checkValue(empty);
            });*/
        }
    }

    private async checkWelcome(short = false) {
        if (window.localStorage) {
            const mindate = moment().add(short ? -2 : -10, 'seconds').toDate().getTime().toString();
            const wt = window.localStorage.getItem('welcometour');
            if (wt && wt > mindate) {
                await window.localStorage.removeItem('welcometour');
            }
        }
    }

    async cargarToken() {

        console.log('cargar-token');
        this.token = await this.dataService.getStorageData('token') || await this.dataService.getStorageData('tokenInvitado') || null;
        if ((this.token && this.sinToken) || this.dataService.getData('error401')) {
            // return;
            console.log('New token, reloading!');
            // 1debugger;
            await this.checkWelcome();
            if (this.dataService.getData('error401')) {
                window.location.reload();
            }
        }

        this.invitado = await this.dataService.getStorageData('invitado');
        console.log(this.invitado);
        this.usuario = await this.dataService.getStorageData('usuario');
        this.usuarioTienda = await this.dataService.getStorageData('usuarioTienda');
        this.sessionId = await this.dataService.getStorageData('sessionId');
        this.tienda = await this.dataService.getStorageData('tienda');
        console.log('this.tienda', this.tienda)
        if (this.tienda){
            this.cat = await this.dataService.getStorageData('categorias');
            console.log('cat main', this.cat)
        }
        const OSName = await this.dataService.getStorageData('OSName');
        // if (false && (OSName !== 'Mac/iOS' || (!this.platform.is('ipad') && !this.platform.is('iphone')))) {
        //     // this.OneSignal = window['OneSignal'] || [];
        //     const OneSignal = window.OneSignal || [];
        //     // console.log(OneSignal);
        //     // if (this.usuario) {
        //     // OneSignal.setExternalUserId(this.usuario.id);
        //     // OneSignal.setEmail(this.usuario.email);
        //     // }
        //     OneSignal.push(() => {
        //
        //         if (this.usuario) {
        //             // OneSignal.setEmail(this.usuario.email);
        //             OneSignal.setExternalUserId(this.usuario.id);
        //             OneSignal.sendTags({
        //                 email: this.usuario.email,
        //                 first_name: this.usuario.nombre,
        //                 last_name: this.usuario.apellido,
        //             });
        //         } else {
        //             OneSignal.sendTags({
        //                 session: this.sessionId
        //             });
        //         }
        //     });
        // }

        console.log(this.usuarioTienda);
        if (this.usuarioTienda) {
            this.appPages = [
                {
                    title: 'Notificaciones',
                    sub_title: 'Lista de notificaciones',
                    url: '/notificacion-lista',
                    icon: 'assets/imgs/pedido_noti.svg'
                },
                {
                    title: 'my_orders',
                    sub_title: 'current_past_orders',
                    url: '/pedidos-home',
                    icon: 'assets/icons/order.svg'
                },
                {
                    title: 'waiting',
                    sub_title: 'current_past_orders',
                    url: '/waiting-list',
                    icon: 'assets/icons/clock.svg'
                },
                {
                    title: 'our_menu',
                    sub_title: 'list_restaurants',
                    url: this.tienda ? ('/' + this.tienda.slug + '/carta') : '',
                    icon: 'assets/icons/ver_carta.svg'
                },
                {
                    title: 'order_history',
                    sub_title: 'Historial de pedidos',
                    url: '/pedidos-historial',
                    icon: 'assets/icons/order.svg'
                },

            ];
        } else {
            this.appPages = [
                {
                    title: 'home',
                    sub_title: 'list_restaurants',
                    url: '/', 
                },
                {
                    title: 'my_orders',
                    sub_title: 'current_past_orders',
                    url: '/orden-lista',
                    icon: 'assets/imgs/36historial.svg'
                },
                {
                    title: 'Tarjetas',
                    sub_title: 'user_wallet_for_quick_payment',
                    url: '/secured/oneclick-lista',
                    icon: 'assets/imgs/37tarjetas.svg'
                }, 
                {
                    title: 'Direcciones',
                    sub_title: 'user_wallet_for_quick_payment',
                    url: '/secured/direccion-lista',
                    icon: 'assets/imgs/38direcciones.svg'
                },
                // {
                //   title: 'bookmarked',
                //   sub_title: 'list_of_restros_you_bookmarked',
                //   url: '/bookmark',
                //   icon: 'zmdi zmdi-favorite ion-text-start'
                // },
                {
                    title: 'promotions',
                    sub_title: 'promotions_list',
                    url: '/cupon-lista',
                    icon: 'assets/imgs/39cupones.svg'
                },
                // {
                //     title: 'Notificaciones',
                //     sub_title: 'Lista de notificaciones',
                //     url: '/notificacion-lista',
                //     icon: 'zmdi zmdi-notifications ion-text-start'
                // },
                // {
                //   title: 'languges',
                //   sub_title: 'select_preferred_language',
                //   url: '/select-language',
                //   icon: 'zmdi zmdi-globe ion-text-start'
                // },
                // {
                //   title: 'contact_us',
                //   sub_title: 'let_us_know_your_queries_feedbacks',
                //   url: '/contact',
                //   icon: 'zmdi zmdi-comment-text ion-text-start'
                // }
            ];
        }

        // if (!this.token) {
        //     this.router.navigate(['/restaurantes']);
        // }
        // else {
        //
        //     this.router.navigate(['login']);
        // }

        if (this.iotSubscriptions.usuario) {
            this.iotSubscriptions.usuario.unsubscribe();
            this.iotSubscriptions.usuario = null;
        }

        const usuarioId = this.usuario ? this.usuario.id : this.sessionId;
        const topics = ['messages:usuario-' + usuarioId];
        // const mesaId = this.orden ? this.orden.mesaId : null;
        // if (mesaId) {
        //     topics.push('pedidos:update:mesa-' + mesaId);
        // }
        console.log(topics);
        this.iotSubscriptions.usuario = PubSub.subscribe(topics).subscribe({
            next: async (data) => {
                console.log('Message received', data, data.value);
                // this.reloadOrdenes();
                if (data.value.msg === 'Diferencia total') {
                    console.log('diff');
                    console.log('tienda-reload');
                    const slugQR = await this.dataService.getStorageData('slugQR');
                    if (slugQR) {
                        await this.apiService.get('/jwt/tienda/' + slugQR);
                    }
                }
            },
            error: error => {
                console.error(error);
                this.eventsService.publish('pubsub:error', error);
            },
            complete: () => console.log('Done'),
        });


        this.addPageView(this.usuario, this.tienda);
        setInterval(() => {
            this.savePageViews();
        }, 60*1000);


        if (this.usuarioTienda) {
            await this.setTiendaConnected();
            setInterval(async () => {
                await this.setTiendaConnected();
            }, 60 * 1000);
        }

    }

    async setTema() {
        // console.log(this.currentUrl);
        // if (window.self !== window.top) {
        this.renderer.addClass(document.body, 'external-web');
        // }

        if (this.currentUrl.indexOf('araucofoodie.cl') !== -1) {
            this.renderer.removeClass(document.body, 'qr-menu');
            this.renderer.addClass(document.body, 'parque-arauco');
        } else {
            const tema = await this.dataService.getStorageData('tema');
            if (!tema) {
                this.renderer.removeClass(document.body, 'parque-arauco');
                this.renderer.addClass(document.body, 'qr-menu');
            } else {
                this.renderer.removeClass(document.body, 'qr-menu');
                if (tema !== 'loading') {
                    this.renderer.addClass(document.body, tema);
                } else {
                    this.renderer.removeClass(document.body, 'parque-arauco');
                }
            }
        }
    }

    async tiendaUpdate(tienda?: Tienda) {

        // console.log('tiendaUpdate');
        // return;
        // const slugQR = await this.dataService.getStorageData('slugQR') || '';
        if (!tienda) {
            return;
        }
        this.cat = await this.dataService.getStorageData('categorias');
        console.log('cat main', this.cat)
        
        const slug = tienda.slug;
        const tema = tienda.tema;
        await this.dataService.setStorageData('tema', tema);
        await this.dataService.setStorageData('tema', tema);
        const decimales = tienda.decimalesMoneda ? '1.2-2' : '1.0-0';
        await this.dataService.setStorageData('decimales', decimales);
        const locale = tienda.decimalesMoneda ? 'en-US' : 'es-CL';
        await this.dataService.setStorageData('locale', locale);
        const simboloMoneda = tienda.simboloMoneda || '$';
        // console.log(simboloMoneda);
        await this.dataService.setStorageData('simboloMoneda', simboloMoneda);
        await this.setTema();

        const telefono = simboloMoneda === 'S/' ? '51912345678' : '56912345678';
        await this.dataService.setStorageData('telefono', telefono);

        const oldSlug = await this.dataService.getStorageData('slugQR') || null;
        console.log('app tienda:update', slug, oldSlug);
        if (oldSlug !== slug) {
            await this.dataService.setStorageData('slugQR', slug);
            const currentLang = await this.dataService.getStorageData('currentLang') || 'es';
            console.log('currentLang', currentLang);

            // this.translate.resetLang(currentLang);
            // this.translate.use(currentLang);

            this.translate.reloadLang(currentLang).subscribe(async () => {
                console.log(`Successfully initialized '${currentLang}' language.'`);
                // this.translate.resetLang(currentLang);

                /* Consultar  esta maniobra
                const auxLang = currentLang === 'es' ? 'en' : 'es';
                this.translate.use(auxLang).subscribe(async () => {
                    console.log(auxLang);
                    await this.translate.use(currentLang);
                    console.log('useLang', currentLang);
                }, err => {
                    console.error(`Problem with '${currentLang}' language initialization.'`);
                }, () => {
                    // resolve(null);
                });

               */

                // await this.translate.use(currentLang);
                // console.log('useLang', currentLang);
            }, err => {
                console.error(`Problem with aa '${currentLang}' language initialization.'`);
            }, () => {
                // resolve(null);
            });
            // console.log('reloadLang', currentLang);
            // await this.translate.use(currentLang);
            // console.log('useLang', currentLang);
        }

        await this.cargarToken();
        // await this.appearance();
        // console.log('translator', this.translate);
        // this.translate.useLoader
        // const currentLang = await this.dataService.getStorageData('currentLang') || 'es';
        // await this.translate.reloadLang(currentLang);
        // // await this.translate.use('en');
        // await this.translate.use(currentLang);
        // console.log('reloaded language', currentLang, slugQR);

        await this.appearance();
        await this.tiendaSubscribe(tienda);
    }

    async tiendaSubscribe(tienda: Tienda) {
        console.log('tienda subscribe', tienda);
        if (this.iotSubscriptions.tienda) {
            this.iotSubscriptions.tienda.unsubscribe();
            this.iotSubscriptions.tienda = null;
        }

        if (!tienda) {
            return;
        }

        const topics = ['tienda:update:tienda-' + tienda.id];
        console.log(topics);
        this.iotSubscriptions.tienda = PubSub.subscribe(topics).subscribe({
            next: async (data) => {
                console.log('Message received', data, data.value);
                if (data.value.msg === 'Update carta') {
                    console.log('tienda-reload');
                    await this.apiService.get('/jwt/tienda/' + tienda.slug);
                }
            },
            error: error => {
                console.error(error);
                this.eventsService.publish('pubsub:error', error);
            },
            complete: () => console.log('Done'),
        });
    }

    async dataSubscribe() {
        // await this.dacheckUsuarioTienda();
        this.subscriptions.push(this.eventsService.subscribe('user:login', () => {
            this.cargarToken();
        }));

        this.subscriptions.push(this.eventsService.subscribe('usuario:update', () => {
            this.cargarToken();
        }));

        this.subscriptions.push(this.eventsService.subscribe('language:change', (lang) => {
            console.log(lang);
            this.translate.use(lang);
        }));

        this.subscriptions.push(this.eventsService.subscribe('app:tema', async () => {
            await this.setTema();
        }));

        this.subscriptions.push(this.eventsService.subscribe('tienda:update', async (tienda: Tienda) => {
            await this.tiendaUpdate(tienda);
        }));
        this.subscriptions.push(this.eventsService.subscribe('vermenu:cat', async (tienda: Tienda) => {
           this.menuCat = true;
           this.menuCtrl.open()
        }));
        this.subscriptions.push(this.eventsService.subscribe('user:logoutApp', (logout) => {
            this.logout(logout);
        }));

        this.subscriptions.push(this.eventsService.subscribe('app:sin-token', () => {
            this.sinToken = true;
        }));

        this.subscriptions.push(this.eventsService.subscribe('resena:modal', async () => {
            const ordenResena = await this.dataService.getStorageData('ordenResena');
            console.log(ordenResena);
            if (ordenResena) {
                await this.modalResena(ordenResena);
                await this.dataService.setStorageData('ordenResena', null);
            }
        }));

        this.subscriptions.push(this.eventsService.subscribe('orden:correo', () => {
            this.correoOrdenes();
        }));

        this.subscriptions.push(this.eventsService.subscribe('pubsub:error', (error) => {
            this.estadoConexion = typeof error.error.errorMessage !== 'undefined' ? error.error.errorMessage : error.error;
        }));
    }

    async modalResena(orden: Orden) {
        const isModalOpened = await this.modalController.getTop();
        console.log(isModalOpened);
        if (typeof isModalOpened === 'undefined') {
            const modal = await this.modalController.create({
                component: ResenaModalPage,
                cssClass: 'resena-modal',
                swipeToClose: true,
                // presentingElement: this.routerOutlet.nativeEl,
                presentingElement: await this.modalController.getTop(),
                componentProps: {
                    orden
                }
            });
            await modal.present();

            const {data} = await modal.onWillDismiss();
            console.log(data);
            // return ;

            if (typeof data !== 'undefined') {
                orden = data.orden;
            }

            let result = false;
            for (const ordenChild of orden.ordenes) {
                let ordenData: any = {};
                let enviarData = true;
                if (typeof data !== 'undefined') {
                    ordenData = {
                        // id: ordenChild.id,
                        resenaModal: ordenChild.resenaModal,
                        resena: {
                            calificacion: ordenChild.calificacion,
                            comentarios: ordenChild.comentarios,
                        },
                        pedidos: []
                    };

                    // if (!ordenChild.calificacion) {
                    //     enviarData = false;
                    // }

                    ordenChild.pedidos.forEach((pedido: Pedido, pIdx: number) => {
                        const pedidoData = {
                            items: []
                        };
                        const items = [];
                        pedido.items.forEach((item: Item, aIdx: number) => {
                            const itemData = {
                                resena: {
                                    calificacion: item.calificacion,
                                }
                            };

                            // if (!item.calificacion) {
                            //     enviarData = false;
                            // }

                            pedidoData.items.push(itemData);
                        });
                        ordenData.pedidos.push(pedidoData);
                    });

                } else {
                    enviarData = false;
                }

                if (!enviarData) {
                    ordenData = {};
                    ordenChild.resenaModal = true;
                    // ordenData.resena = null;
                }
                ordenData.resenaModal = orden.resenaModal;
                console.log(ordenChild);

                if (!this.usuario) {
                    ordenData.session = this.sessionId;
                }

                console.log(ordenData);
                const loading = await this.uiService.presentLoading('Enviando datos...');
                result = await this.apiService.put('/jwt/orden/edit/' + ordenChild.id + '/resena', ordenData);
                console.log(result);
                await loading.dismiss();
            }

            console.log(result);
            if (result) {
                // const response = await this.dataService.getData('postResponse');
                // console.log(response);
                // await this.dataService.storeOrdenes(response.ordenes);
                await this.dataService.goHome(); 
            }

        }

    }

    // my_profile() {
    //   this.navCtrl.navigateRoot(['./myprofile']);
    // }
    async menuTapped(page) {
        if (page.url === '/') {
            await this.dataService.goHome(); 
        } else {
            await this.router.navigate([page.url]);
        }
        await this.menuCtrl.close();
    }

    async close(){
        await this.menuCtrl.close();
    }
    // createTodo() {
    //     this.awsAPIService.CreateTodo({
    //         name: 'ionic',
    //         description: 'testing'
    //     });
    // }

    async correoOrdenesTapped() {
        await this.uiService.presentAlertConfirm('Se enviará un correo a ' + this.usuario.email + ' con el resumen de las órdenes de día. Desea continuar?', 'Atención', 'orden:correo');
    }

    async correoOrdenes() {
        const loading = await this.uiService.presentLoading('Enviando datos...');

        const usuario = await this.dataService.getStorageData('usuario');

        const data = {usuarioId: usuario.id};
        console.log(data);
        const result = await this.apiService.post('/jwt/orden/correo-planilla', data);
        await loading.dismiss();

        if (result) {
            // this.eventsService.publish('usuario:update', null);
            await this.uiService.presentAlert('Correo enviado exitósamente');
            // this.navCtrl.navigateBack('/main/home', {animated: true});
        }
    }

    async reloadOrdenData() {
        // const loading = await this.uiService.presentLoading('Cargando datos...');
        const data: any = this.usuario ? {usuarioId: this.usuario.id} : {sessionId: this.sessionId};
        // const ordenesVirtual = await this.dataService.getStorageData('ordenesVirtual') || [];
        const mesa = await this.dataService.getStorageData('mesa');
        data.mesa = mesa ? mesa.id : null;
        console.log(data);
        await this.apiService.post('/jwt/orden/lite', data);
        // loading.dismiss();
    }

    createOnline$() {
        return merge<boolean>(
            fromEvent(window, 'offline').pipe(map(() => false)),
            fromEvent(window, 'online').pipe(map(() => true)),
            new Observable((sub: Observer<boolean>) => {
                sub.next(navigator.onLine);
                sub.complete();
            }));
    }

    contacto() {
        const correoContactoApp = this.tienda.correoContactoApp || 'informaciones@qrmenu.cl';
        const isEmail = correoContactoApp.includes('@') ? 'mailto:' : '';
        this.uiService.presentAlert('En caso de reclamos o soporte favor comunicarse a  <a href="' + isEmail + correoContactoApp + '">' + correoContactoApp + '</a>', 'Contacto');
    }

    addResizeSubscription() {
        if (this.appearence.subs) {
            this.appearence.subs.unsubscribe();
        }
        this.platform.resize.subscribe(async () => {
            await this.appearance();
        });
    }

    addRouterSubscription() {
        this.checkWelcome(true);
        if (this.appearence.subr) {
            this.appearence.subr.unsubscribe();
        }
        this.appearence.subr = this.router.events.subscribe(async (val: any) => {
            if (val && val.url && (!this.appearence.lastUrl || val.url !== this.appearence.lastUrl)) {
                this.appearence.lastUrl = val.url;
                console.log('URL CAMBIO', this.appearence.lastUrl);
                await this.appearance();
            }
        });
    }

    async appearance() {

        if (this.appearence.enable) {
            try {
                const vh = window.innerHeight * 0.01;
                document.documentElement.style.setProperty('--vh', `${vh}px`);
                this.usuarioTienda = await this.dataService.getStorageData('usuarioTienda');
                console.log('this.usuarioTienda', this.usuarioTienda)
                if (this.appearence.safari === null) {
                    const ua = navigator.userAgent.toLowerCase();
                    this.appearence.safari = ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1;
                }

                this.appearence.width = this.platform.width();
                this.appearence.height = this.platform.height();
                this.appearence.ratio = this.appearence.width / this.appearence.height;


                if (window.location.hostname !== this.appearence.host) {
                    this.appearence.host = document.body && this.utils.hasSomeParentTheClass(document.body, 'parque-arauco') ? 'arauco' : 'qrmenu';
                }
                if (this.router.url) {
                    // const parts = this.router.url.split("/");
                    const tienda = await this.dataService.getStorageData('tienda');

                    if (this.usuarioTienda) {
                        this.appearence.path = null;
                        this.appearence.background = null;
                        this.renderer.addClass(document.body, 'usuario-tienda');
                    } else {
                        this.renderer.removeClass(document.body, 'usuario-tienda');
                        this.appearence.path = (tienda) ? (tienda.slugManual || tienda.slug) : '/';
                        this.menuCtrl.enable(true, 'main-content');

                        this.appearence.background = this.appearence.host === 'arauco' ? this.backgroundArauco : this.backgroundQR;

                        if (tienda && tienda.backgroundColor) {
                            this.appearence.background = tienda.backgroundColor;
                        }

                    }

                    this.appearence.leftCol = tienda && tienda.backgroundLeftS3 ? `url(${tienda.backgroundLeftS3})` : `url(/assets/backgrounds/${this.appearence.host}/left.png)`;
                    if (this.appearence.width >= this.w3c && this.appearence.ratio > 1.5) {
                        this.appearence.rightCol = tienda && tienda.backgroundRightS3 ? `url(${tienda.backgroundRightS3})` : `url(/assets/backgrounds/${this.appearence.host}/right.png)`;
                        this.menuCtrl.enable(true);
                    } else if (this.appearence.width >= this.w2c && this.appearence.ratio > 1) {
                        this.appearence.rightCol = null;
                        this.menuCtrl.enable(true);
                    } else {
                        this.menuCtrl.enable(true, 'main-content');
                        this.appearence.path = null;
                        this.appearence.background = null;
                    }

                }

            } catch (e) {
                console.log(e);
            }
        }


    }

    async handleBackButtonPress() {
        this.location.subscribe(async redirect => {
            if (redirect.pop === true) {
                const tienda = await this.dataService.getStorageData('tienda');
                const parts = this.router.url.split('/');
                if (parts.length >= 2) {
                    
                    /*

                        //parts[1]!='landing-mesa'  && 
                        //parts[1]!='mesa-asociar'   &&  
                        //parts[1]!='waiting'  && 
                        //parts[1]!=''   && 
                        
                        */

                    if (parts[1]!='item-form' && 
                        parts[1]!='login' && 
                        parts[1]!='item-lista' && 
                        parts[1]!='secured' && 
                        parts[1]!='orden-ver' && 
                        parts[1]!= 'cupon-lista' && 
                        parts[1]!= 'orden-lista' && 
                        parts[1]!= 'orden-crear' && 
                        parts[1]!= 'softlogin' && 
                        parts[1]!= 'carrito-pagar' && 
                        parts[1]!= 'carrito-pedido'){

                        console.log('parts tienda', parts)
                        const tt = await this.dataService.getTiendaBySlug(parts[1]);
                        this.tiendaUpdate(tt);

                    }
                }
            }
        });
    }

    private async setTiendaConnected() {
        const result = await this.apiService.post('/jwt/tienda/connected', {});
    }

    private async savePageViews() {
        if (this.pageViewInfo && this.pageViewInfo.views instanceof Array && this.pageViewInfo.views.length > 0) {
            await this.apiService.setPageViewMultiple(this.pageViewInfo.views);
            this.pageViewInfo.views = [];
        }
    }

    private async addPageView(usuario?: Usuario, tienda?: Tienda) {

        let dv = await this.dataService.getStorageData('device_id');
        let ss = await this.dataService.getStorageData('session_id');
        let vc = await this.dataService.getStorageData('vencimiento_sesion');
        // si la diferencia entre fechas es positiva, entonces limpio la variable de sesion.
        if (moment().diff(moment(vc), 'second') > 0) {
            vc = null;
            ss = null;
        }

        if (!this.pageViewInfo) {
            if (!dv || !ss || !vc) {
                dv = dv || uuid.v4();
                ss = ss || uuid.v4();
                vc = moment().add(10, 'm').toDate().getTime();
                this.dataService.setStorageData('device_id', dv);
                this.dataService.setStorageData('session_id', ss);
                this.dataService.setStorageData('vencimiento_sesion', vc);
            }
            this.pageViewInfo = new PageViewInfo(dv, ss);
        }

        if (this.pageViewInfo.subscription) {
            this.pageViewInfo.subscription.unsubscribe();
        }

        this.pageViewInfo.subscription = this.router.events.subscribe(async (val: any) => {
            if (val && val.url && (!this.pageViewInfo.last || val.url !== this.pageViewInfo.last)) {

                vc = moment().add(10, 'm').toDate().getTime();
                this.dataService.setStorageData('vencimiento_sesion', vc);

                this.pageViewInfo.last = val.url;
                const [page, producto] = this.urlConverter(val.url);
                if (page) {
                    const t = new PageView();
                    t.datetime = (new Date()).getTime();
                    t.page = page;
                    t.store = tienda ? tienda.id : null;
                    t.user = usuario ? usuario.id : null;
                    t.product = producto;
                    t.uid = this.pageViewInfo.uid;
                    t.device = this.pageViewInfo.device;
                    if (!this.pageViewInfo.views) {
                        this.pageViewInfo.views = [];
                    }
                    this.pageViewInfo.views.push(t);
                }
            }
        });

    }

    private urlConverter(url: string) {
        const parts = url.split('/');
        console.log('pageview', parts,parts.length)
        if (parts && parts.length) {
            if (parts.length === 3) {
                return [parts[2]];
            }
            if (parts.length === 4) {
                if (parts[2] === 'producto') {
                    return [parts[2], Number(parts[3])];
                }
                if (parts[2] === 'carta-slides') {
                    return ['slide-producto', Number(parts[3])];
                }

            }
             if (parts.length === 2){
                if (parts[1] === 'waiting') {
                    return ['waiting'];
                }
             }
        }
        return [];
    }

    async alertInput() {
        await this.dataService.alertInput();
    }

    async notificaciones(){
        const modal = await this.modalController.create({
            component: NotificacionesPage,
            swipeToClose: true,
            mode:'ios',
            presentingElement: await this.modalController.getTop(),
        });
        await modal.present();
    }

    async ordenes(){
        //await this.router.navigate(['/pedido-lista']);
        await this.router.navigate(['/pedidos-home']);
        await this.menuCtrl.close();
    }

    async listaEspera(){
        await this.router.navigate(['/waiting-list']);
        await this.menuCtrl.close();
    }

    async terminos(){
        window.location.href = 'https://policies.google.com/privacy';
    }
    perfil(){
        if (this.invitado === false && this.usuario){
            this.menuCtrl.close();
            this.router.navigate(['/secured', 'usuario-form']);
        }else{
            this.menuTapped({url: '/login'})
        }

    }

    closeMenuRapido(){
        
            this.menuCat = false;
      
       
        
    }
    toCat(item){
       
        this.menuCtrl.close();
        this.eventsService.publish('irA:categoria', item);
    }
    toSubCat(i,item){
        
        this.menuCtrl.close();
        this.eventsService.publish('irA:subcategoria', {i:i,index:item});
    }
}
