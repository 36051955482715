import {Component, Input, OnInit} from '@angular/core';
import {Orden} from '../../interfaces/interfaces';
import {ModalController} from '@ionic/angular';
import * as moment from 'moment';
import 'moment/locale/es';

@Component({
    selector: 'app-resena-modal',
    templateUrl: './resena-modal.page.html',
    styleUrls: ['./resena-modal.page.scss'],
})
export class ResenaModalPage implements OnInit {
    @Input() orden: Orden;
    ordenParent: Orden;

    constructor(private modalCtrl: ModalController) {
    }

    ngOnInit() {
        console.log(this.orden);
        this.ordenParent = this.orden;
    }

    async guardar() {
        console.log(this.ordenParent);
        this.ordenParent.resenaModal = true;
        await this.modalCtrl.dismiss({
            orden: this.ordenParent
        });
    }

    moment(fecha: string) {
        return moment(fecha);
    }
}
