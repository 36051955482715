import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IonRouterOutlet, ModalController } from '@ionic/angular';
import { ProductoDetallePage } from 'src/app/pages/producto-detalle/producto-detalle.page';
import { DataService } from 'src/app/services/data.service';
import { ProductoRecomendado, RecomendationService } from 'src/app/services/recomendation-service.service';
import { IonSlides } from '@ionic/angular';

@Component({
  selector: 'app-para-ti',
  templateUrl: './para-ti.component.html',
  styleUrls: ['./para-ti.component.scss'],
})
export class ParaTiComponent implements OnInit {
  slideOpts = { 
    duration: 2000,
    on: {
      beforeInit() {
        const swiper = this;
        swiper.classNames.push(`${swiper.params.containerModifierClass}fade`);
        const overwriteParams = {
          slidesPerView: 1,
          slidesPerColumn: 1,
          slidesPerGroup: 1,
          watchSlidesProgress: true,
          spaceBetween: 0,
          virtualTranslate: true,
        };
        swiper.params = Object.assign(swiper.params, overwriteParams);
        swiper.params = Object.assign(swiper.originalParams, overwriteParams);
      },
      setTranslate() {
        const swiper = this;
        const { slides } = swiper;
        for (let i = 0; i < slides.length; i += 1) {
          const $slideEl = swiper.slides.eq(i);
          const offset$$1 = $slideEl[0].swiperSlideOffset;
          let tx = -offset$$1;
          if (!swiper.params.virtualTranslate) tx -= swiper.translate;
          let ty = 0;
          if (!swiper.isHorizontal()) {
            ty = tx;
            tx = 0;
          }
          const slideOpacity = swiper.params.fadeEffect.crossFade
            ? Math.max(1 - Math.abs($slideEl[0].progress), 0)
            : 1 + Math.min(Math.max($slideEl[0].progress, -1), 0);
          $slideEl
            .css({
              opacity: slideOpacity,
            })
            .transform(`translate3d(${tx}px, ${ty}px, 0px)`);
        }
      },
      setTransition(duration) {
        const swiper = this;
        const { slides, $wrapperEl } = swiper;
        slides.transition(duration);
        if (swiper.params.virtualTranslate && duration !== 0) {
          let eventTriggered = false;
          slides.transitionEnd(() => {
            if (eventTriggered) return;
            if (!swiper || swiper.destroyed) return;
            eventTriggered = true;
            swiper.animating = false;
            const triggerEvents = ['webkitTransitionEnd', 'transitionend'];
            for (let i = 0; i < triggerEvents.length; i += 1) {
              $wrapperEl.trigger(triggerEvents[i]);
            }
          });
        }
      },
    }
  };
  @Input() recomendaciones;
  @Input() tienda;
  @ViewChild('slider', { static: true }) slider: IonSlides;
  slideIndex = 0;
  public progress = 0;
  public timer = 0;
  disableTimer:boolean = false;
  color1:string = '#252F38';
  color2:string = '#730C99';
  modo:any = 1;
  constructor(   
    private dataService: DataService,
    private recomendationService: RecomendationService,
    private modalController: ModalController) {
      console.log('parati',this.tienda)
      this.modo = 1;
      
   }
   ionViewDidEnter() {
    this.slider.update()
    this.barTimer();
    
  }

  barTimer() {
    let interval = setInterval(() => {
 
      this.timer += 0.05;
      if (this.timer >= 1) {
       
        clearInterval(interval);
        this.slider.slideNext();
      }
    }, 100);
  }

  ngOnInit() {
    console.log('parati',this.tienda)
    if (this.tienda.paraTiColor1!=null && this.tienda.paraTiColor1!=undefined){
      this.color1 = this.tienda.paraTiColor1;
    }
    if (this.tienda.paraTiColor2!=null && this.tienda.paraTiColor2!=undefined){
      this.color2 = this.tienda.paraTiColor2;
    }    
    if (this.tienda.paraTiTipo!=null && this.tienda.paraTiTipo!=undefined){
      this.modo = this.tienda.paraTiTipo*1;
    }    
 
    console.log('recomendaciones', this.recomendaciones);
     
    
  }
  next(){
    this.slider.slideNext()
  }
  onSlideChanged() {
    this.slider.getActiveIndex().then((index) => {
      console.log('Slide changed! Current index is', index);
      this.slideIndex  = index;
      
      if (this.disableTimer==false){
        this.timer = 0;
        this.barTimer();
      }
     
    });
  }

  async verProducto(item) {
    let producto = await this.dataService.getProducto(item.itemId);
    this.modalController.dismiss();
    const modal = await this.modalController.create({
      component: ProductoDetallePage,
      mode: 'ios',
      cssClass: 'my-custom-class',
      swipeToClose: true, 
      presentingElement: await this.modalController.getTop(), 
      componentProps: {
        producto: producto
      }
    });
    return await modal.present();
}
volver(){
  this.modalController.dismiss();
}
randomNumber(min, max) {
  console.log(Math.floor(Math.random() * (max - min) + min))
  return Math.floor(Math.random() * (max - min) + min);
}
}
