import {Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';
import {BehaviorSubject} from 'rxjs';
import {DataService} from './data.service';
import {Router} from '@angular/router';

const TOKEN_KEY = 'token';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    authenticationState = new BehaviorSubject(false);
    public redirectUrl: string;

    constructor(
        private dataService: DataService,
        private plt: Platform,
        private router: Router) {
        this.plt.ready().then(async () => {
            await this.checkToken();
        });
    }

    async checkToken(intentos = 0) {
        console.log('security-check-token', intentos);
        const token = await this.dataService.getStorageData(TOKEN_KEY);
        // console.log(token);
        if (token) {
            this.authenticationState.next(true);
        } else {
            if (intentos === 0) {
                setTimeout(async () => {
                    const newIntentos = intentos + 1;
                    console.log(newIntentos);
                    await this.checkToken(newIntentos);
                }, 500);
            } else {
                this.authenticationState.next(false);
            }
        }
    }

    async login(token,NoCambiar=false) {
        await this.dataService.setStorageData(TOKEN_KEY, token);
        this.authenticationState.next(true);

        await this.dataService.removeStorageData('direcciones');
        console.log(this.redirectUrl);
        if (this.redirectUrl) {
            if (NoCambiar==false){
                // this.navCtrl.navigateRoot(this.redirectUrl);
                await this.router.navigateByUrl(this.redirectUrl, {replaceUrl: true});
                // this.router.navigate([this.redirectUrl]);
                this.redirectUrl = null;
            }

        } else {
            const volverCarro = await this.dataService.getStorageData('volverCarro');
            if (NoCambiar==false){
                if (volverCarro) {
                    await this.dataService.setStorageData('volverCarro', false);
                        
                    await this.router.navigate(['/carrito-pagar']);
                 
                   
                    return;
                } else {
                    await this.goHome('login');
                    return;
                }
            }
            
        }
    }

    async logout() {
        await this.dataService.setStorageData(TOKEN_KEY, null);
        this.authenticationState.next(false);
        await this.goHome('logout');
    }

    isAuthenticated() {
        return this.authenticationState.value;
    }

    async goHome(option) {
        const slugQR = await this.dataService.getStorageData('slugQR');
        console.log(slugQR);
        const usuarioTienda = await this.dataService.getStorageData('usuarioTienda');
        if (usuarioTienda && option === 'login') {
            await this.router.navigateByUrl('/pedidos-home', {replaceUrl: true});//await this.router.navigateByUrl('/pedido-lista', {replaceUrl: true});
        } else if (slugQR) {
            await this.router.navigateByUrl('/' + slugQR + '/home', {replaceUrl: true});
        } else {
            if (window.location.href.indexOf('araucofoodie.cl') === -1) {
                await this.router.navigateByUrl('/' + slugQR + '/home', {replaceUrl: true});
            } else {
                await this.router.navigateByUrl('/parque-arauco', {replaceUrl: true});
            }
        }
        // this.navCtrl.navigateBack(slugQR ? ('/tienda-ver/' + slugQR) : '/restaurantes', {animated: false, replaceUrl: true});
        return;
    }
}
