import { Producto, Alternativa, Seleccion } from './../../interfaces/interfaces';
import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-detalle-comida',
  templateUrl: './detalle-comida.component.html',
  styleUrls: ['./detalle-comida.component.scss'],
})
export class DetalleComidaComponent implements OnInit, OnDestroy {
  @Input() producto : Producto;
  constructor(private modal: ModalController) { }

  ngOnInit() {
    console.log(this.producto);
    
  }

  back(){
    this.modal.dismiss();
  }

  add(){
    this.modal.dismiss(this.producto);
  }

  removeAlternative(alternativa: Alternativa){
    alternativa.cantidad -= 1; 
  }

  addAlternative(alternativa: Alternativa){
    alternativa.cantidad = alternativa.cantidad ? alternativa.cantidad += 1 : 1; 
    console.log(alternativa);
  }

  select(alternativa: Alternativa, seleccion){
    for (const a of seleccion.alternativas) {
      if (a.id !== alternativa.id) {
        a.checked = false;
        a.cantidad = 0;
      }else {
        a.cantidad = 1;
      }
    }
  }

  ngOnDestroy() {
    this.producto = null;
  }
}
