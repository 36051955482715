import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-para-ti-m2',
  templateUrl: './para-ti-m2.component.html',
  styleUrls: ['./para-ti-m2.component.scss'],
})
export class ParaTiM2Component implements OnInit {
  @Input() slide;
  decimales = '1.0-0';
  locale = 'es-CL';
  simboloMoneda = '$';
  constructor() { }

  ngOnInit() {}
  strip(html)
  {
     var tmp = document.implementation.createHTMLDocument("New").body;
     tmp.innerHTML = html;
     return tmp.textContent || tmp.innerText || "";
  }
}
