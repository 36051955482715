import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Alternativa, Producto, Seleccion} from "../../interfaces/interfaces";
import {ProductoRecomendado, RecomendationService} from "../../services/recomendation-service.service";
import {DataService} from "../../services/data.service";
import { ProductoDetallePage } from 'src/app/pages/producto-detalle/producto-detalle.page';
import { IonRouterOutlet, ModalController, ToastController } from '@ionic/angular';
import { EventsService } from 'src/app/services/events.service';
import { UiService } from 'src/app/services/ui.service';
 

@Component({
  selector: 'app-recomendaciones-pedido-new',
  templateUrl: './recomendaciones-pedido-new.component.html',
  styleUrls: ['./recomendaciones-pedido-new.component.scss'],
})
export class RecomendacionesPedidoNewComponent implements OnInit {
    @Output() getProducts: EventEmitter<any> = new EventEmitter();
    @Output() addCarr: EventEmitter<any> = new EventEmitter();
    @Output() addTemp: EventEmitter<any> = new EventEmitter();
    @Input() store: number;
    @Input() estaGuardado: boolean;
    @Input() tienda: any;
    @Input() productos: string;
    @Input() temporal: boolean;
    products: ProductoRecomendado[];
    noloading: boolean = true;

    constructor(
      private toastController: ToastController,
      private eventsService: EventsService,
      private uiService: UiService,
      private modalController: ModalController, 
      private dataService: DataService,
                private recomendationService: RecomendationService) {
    }

    async ngOnInit() {

      let productos = await this.dataService.getStorageData('productos');
      let prod2 = [];
      
      for (let k  in productos ) {
          if (productos[k].activo == true)
              prod2.push(productos[k])
          
      }
      let productos2 = prod2;

        if (this.productos && this.store) {
            this.recomendationService.shoppingCartRecomendation(this.store, this.productos).then((response) => {
              this.noloading = false;
                let products = response;
                this.products = [];
                for (let index = 0; index < productos2.length; index++) {
                  for (let index2 = 0; index2 < products.length; index2++) {
                      if (products[index2].itemId==productos2[index].id){
                        this.products.push(productos2[index])
                      }
                    
                  }
                  
                }
                console.log('this.products', this.products, productos2)
                this.getProducts.emit(this.products);
                    
            });
        }
    }
    goProd(item){
      this.getProducts.emit(item);
    }
    async verProducto(item) {
      this.modalController.dismiss();
      const modal = await this.modalController.create({
        component: ProductoDetallePage,
        mode: 'ios',
        cssClass: 'my-custom-class',
        swipeToClose: true, 
        componentProps: { 
          producto: item
        }
      });
      await modal.present();
      const { data } = await modal.onWillDismiss();
      console.log(data);
       
  }


   async addCarrito(producto){
      if (this.temporal===true){
        this.addCarr.emit(producto);
        return;
      }
        console.log('addCarrito', producto)
        if (producto.selecciones.length>0){ 
          
            this.verProducto(producto)
            return;
        }
        let item = {
            id: null,
            productoId: null,
            imagenName: null,
            tiendaId: null,
            tiendaSlug: null,
            tiendaNombre: null,
            tiendaMultiPago: false,
            nombre: null,
            precio: 0,
            descuento: 0,
            precioFinal: 0,
            cantidad: 0,
            observaciones: null,
            calificacion: null,
            modificadoTienda: null,
            // supero: false,
            selecciones: {},
            alternativas: {}
        };

        let Items = await this.dataService.getItems() || [];
        let indexProd = 0;
        for (let index = 0; index < Items.length; index++) {
            if (Items[index].productoId==producto.id && producto.selecciones.length==0){
                indexProd = index;
            }
            
        }
        if (indexProd==0){
            item.productoId = producto.id;
            item.id = Items.length;
            item.precio = producto.precio;
            item.descuento = producto.descuento;
            item.precioFinal = producto.precioFinal;
            item.nombre = producto.nombre;
            item.imagenName = producto.imagenName;
            item.tiendaId = this.tienda.id;
            item.tiendaSlug = this.tienda.slug;
            item.tiendaNombre = this.tienda.nombre;
            item.tiendaMultiPago = this.tienda.multiPago;
            item.cantidad = 1;;
        }else{
            item = Items[indexProd];
            item.cantidad++;
        }
       


        const selecciones = {};
        const alternativas = {};
        producto.selecciones.forEach((seleccion: Seleccion, index: number) => {
            selecciones[seleccion.id] = {
                id: seleccion.id,
                seleccionNombre: seleccion.nombre,
                checkeds: 0,
                value: null
            };
            seleccion.alternativas.forEach((alternativa: Alternativa, aIdx: number) => {
                alternativas[alternativa.id] = {
                    id: alternativa.id,
                    cantidad: 0,
                    seleccionNombre: seleccion.nombre,
                    nombre: alternativa.nombre,
                    precio: alternativa.precio
                };
            });
        });
        item.selecciones = selecciones;
        item.alternativas = alternativas;
        
        console.log('item actualizado',producto,  item);

        if (!this.isAbierto(this.tienda)) {
            await this.uiService.presentToast('Este restaurant se encuentra cerrado');
            return;
        }

        if (!this.isAbierto(item, 'producto') || !producto.disponible) {
            await this.uiService.presentToast('Este producto no se encuentra disponible');
            return;
        }
     
        
        await this.dataService.storeItem(item);
        this.eventsService.publish('items:update', null);

        const dataLayer = window['dataLayer'] || [];
        dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        dataLayer.push({
          event: "add_to_cart",
          ecommerce: {
            items: [{
              item_name: producto.nombre, // Name or ID is required.
              item_id: producto.id,
              price: producto.precioFinal, 
              item_category: producto.categoriaNombre, 
              index: item.id,
              quantity: item.cantidad,
              item_brand: this.tienda.nombre
            }]
          }
        });
        console.log('datalayer',dataLayer)
        
    }
    isAbierto(entity, option = 'tienda') {
      return this.dataService.isAbierto(entity, option);
  }

}
