import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Producto} from "../../interfaces/interfaces";
import {ProductoRecomendado, RecomendationService} from "../../services/recomendation-service.service";
import {DataService} from "../../services/data.service";

@Component({
    selector: 'app-recomendaciones-pedido',
    templateUrl: './recomendaciones-pedido.component.html',
})
export class RecomendacionesPedidoComponent implements OnInit {


    @Output() getProducts: EventEmitter<any> = new EventEmitter();
    @Input() store: number;
    @Input() productos: string;
    products: ProductoRecomendado[]

    constructor(private dataService: DataService,
                private recomendationService: RecomendationService) {
    }

    async ngOnInit() {
        if (this.productos && this.store) {
            this.recomendationService.shoppingCartRecomendation(this.store, this.productos).then((response) => {
                this.products = response;
                if(this.products && this.products.length)
                    this.getProducts.emit(this.products);
            });
        }
    }

}
