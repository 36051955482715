import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

import { DataService } from 'src/app/services/data.service';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-notificaciones',
  templateUrl: './notificaciones.page.html',
  styleUrls: ['./notificaciones.page.scss'],
})
export class NotificacionesPage implements OnInit {
  @Output() closeEvent = new EventEmitter<boolean>();
  @Input() isModal?: boolean = true;
  @Output() seeOrderEvent = new EventEmitter<any[]>();

  notificaiones = [];
  checked = false;
  constructor(
    private apiService: ApiService,
    private dataService: DataService,
    private modalController: ModalController,
  ) { }

  async ngOnInit() {
    this.notificaiones  = [];
    let ordenes = await this.dataService.getOrdenes();
    for (let index = 0; index < ordenes.length; index++) {
      for (let index2 = 0; index2 < ordenes[index].notificaciones.length; index2++) {
        ordenes[index].notificaciones[index2].orden =  ordenes[index];
        this.notificaiones.push(ordenes[index].notificaciones[index2])
        
      }
    }
    console.log('this.notificaiones',this.notificaiones)
    var hash = {};
    this.notificaiones = this.notificaiones.filter(function(current) {
      var exists = !hash[current.id];
      hash[current.id] = true;
      return exists;
    });

    //this.notificaiones = await this.dataService.getStorageData('notificaciones');
    this.notificaiones = this.notificaiones.filter((notificacion) => !notificacion.leida );
    console.log(this.notificaiones);
  }

  check(notificaction){
    notificaction.check = !notificaction.check;
  }

  checkedAll(){
    this.checked = !this.checked;

    for (const notification of this.notificaiones)
      notification.check = true;
  }
  async back(){
    if (this.isModal) {
      await this.modalController.dismiss();
    }else {
      this.closeEvent.emit(true);
    }
  }

  async delete() {
    console.log(this.notificaiones);

    const notifiacionesLeidas = this.notificaiones.filter((notificacion) => notificacion.check);
    console.log(notifiacionesLeidas);
    this.modalController.dismiss();

    if (notifiacionesLeidas.length > 0) {
      for (let index = 0; index < notifiacionesLeidas.length; index++) {
        delete notifiacionesLeidas[index].orden;
        
      }
      const data: any = {
          ids: notifiacionesLeidas
      };
      await this.apiService.post('/jwt/notificacion/leer', data);
      this.ngOnInit();
    }
    
  }

  close = () => {
    this.checked = false;
  }

  seeOrden(order){
    this.seeOrderEvent.emit(order)
  }
}
