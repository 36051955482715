import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {DataService} from "./data.service";
import {environment} from "../../environments/environment";

export type ProductoRecomendado = {
    itemId: string;
    itemImage: string;
    itemName: string;
    itemPrice: string;
    itemActive: boolean;
    itemDescripcion?: string;
};


@Injectable({
    providedIn: 'root'
})
export class RecomendationService {
    private url = environment.apiUrl + '/recomendacion' ;
    constructor(private http: HttpClient) {}

    async guestUserRecomendation(storeid) {
        return this.http.get<any>(`${this.url}/guess/${storeid}`).toPromise();
    }

    async registeredUserRecomendation(storeid, userid) {
        return this.http.get<any>(`${this.url}/user/${storeid}/${userid}`).toPromise()
    }

    async upsellRecomendation(storeid, item) {
        return this.http.get<any>(`${this.url}/upsell/${storeid}/${item}`).toPromise()
    }

    async shoppingCartRecomendation(storeid, itemids) {
        return this.http.get<any>(`${this.url}/shoppingcart/${storeid}/${itemids}`).toPromise()
    }
    async hotSales(slug) {
        return this.http.get<any>(`${this.url}/hotSales/${slug}`).toPromise()
    }

    async hotsRecomendation(storeid) {
        return this.http.get<any>(`${this.url}/hots/${storeid}`).toPromise()
    }

}
