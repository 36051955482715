import {Injectable} from '@angular/core';
import {ActionSheetController, AlertController, LoadingController, ToastController, Animation, createAnimation } from '@ionic/angular';
import {EventsService} from './events.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class UiService {
    loading: any;
    alertMessage = '';

    constructor(private alertController: AlertController,
                private toastController: ToastController,
                private loadingController: LoadingController,
                private eventsService: EventsService,
                private translateService: TranslateService) {
    }

    async presentAlert(message: string, header = null, event = null, cssClass = '') {

        if (typeof header === 'undefined' || !header) {
            header = 'Éxito';
        }

        // const isAlertOpened = await this.alertController.getTop();
        // console.log(isAlertOpened);
        if (this.alertMessage !== message) {
            this.alertMessage = message;

            const alert = await this.alertController.create({
                cssClass,
                message: this.translateService.instant(message) || message,
                header,
                backdropDismiss: false,
                buttons: [
                    {
                        text: 'OK',
                        handler: () => {
                            console.log('Confirm Okay');
                            this.alertMessage = '';
                            if (event) {
                                this.eventsService.publish(event, {});
                            }
                        }
                    }
                ]
            });

            await alert.present();
        }
    }

    async presentAlertConfirm(message: string, header, event) {
        const alert = await this.alertController.create({
            // cssClass: 'my-custom-class',
            header,
            message,
            buttons: [
                {
                    text: 'Cancelar',
                    role: 'cancel',
                    // cssClass: 'secondary',
                    handler: (blah) => {
                        console.log('Confirm Cancel: blah');
                    }
                }, {
                    text: 'Confirmar',
                    handler: () => {
                        console.log('Confirm Okay');
                        this.eventsService.publish(event);
                    }
                }
            ]
        });

        await alert.present();

        const {data} = await alert.onWillDismiss();
        console.log(data);
    }

    async presentClaveSupervisor(tienda, message: string, header, event, entity = null){
        const alert = await this.alertController.create({
            cssClass: 'my-custom-class',
            header,
            message,
            inputs: [
                {
                    name: 'claveSupervisor',
                    type: 'password',
                    attributes: {
                        maxlength: '50',
                    },
                    placeholder: 'Ingrese clave supervisor',
                }
            ],
            buttons: [
                {
                    text: 'Cancelar',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        console.log('Confirm Cancel');
                    }
                }, {
                    text: 'Confirmar',
                    handler: (alertData) => {
                        // console.log('Confirm Ok', alertData, this.tienda.claveSupervisor);
                        if (tienda.claveSupervisor && alertData.claveSupervisor !== tienda.claveSupervisor) {
                            this.presentAlert('Clave inválida!', 'Error');
                        } else {
                            // return;
                            this.eventsService.publish(event, entity);
                        }
                    }
                }
            ]
        });

        await alert.present();
    }

    async presentErrorAlert(error) {
        let message = '';
        if (typeof error.error !== 'undefined' && error.error) {
            if (typeof error.error.mensaje !== 'undefined') {
                message = error.error.mensaje;
            } else if (typeof error.error.errors !== 'undefined' && typeof error.error.errors.errors !== 'undefined') {
                message = error.error.errors.errors[0];
            } else if (typeof error.error.detail !== 'undefined') {
                message = error.error.detail;
            }
            else{
                message = error.message;
            }
        } else if (typeof error.message !== 'undefined') {
            message = error.message;
        } else {
            message = 'Error indeterminado, por favor vuelva a intentar más tarde.';
        }

        message = message.replace('<!--', '').replace('-->', '');
        console.log(message);
        const alert = await this.alertController.create({
            message: this.translateService.instant(message) || message,
            header: 'Error',
            buttons: ['OK']
        });

        await alert.present();
        await this.dismissLoading();

        if (message.indexOf('Refrescando...') !== -1) {
            window.location.reload();
        }
    }

    async presentToast(message: string, position: string = 'top') {
        type tOptions = 'top' | 'bottom' | 'middle';
        const lposition = position as tOptions;
        const toast = await this.toastController.create({
            message: this.translateService.instant(message) || message,
            position: lposition,
            duration: 4500,
            cssClass: 'custom-toast'
        });
        await toast.present();
    }

    async presentLoading(message: string) {
        const DURATION = 400;
        const enterAnimation = (baseEl: any) => {
        const leavingAnim = createAnimation()
        .addElement(baseEl.querySelector('ion-backdrop'))
        .duration(DURATION)
        .easing('ease-out')
        .fromTo('opacity', 1, 0);
        return leavingAnim;
        };
        console.log('present', message);

        message = '<div class="new-layout">\n' +
            '  <div class="loader">\n' +
            '    <div class="content">\n' +
            //'      <img class="logo" src="assets/nl/loading/logo-loading.png" alt="Loading">\n' +
            '      <img class="gif" src="assets/nl/loading/loader.gif" alt="Loading">\n' +
            '    </div>\n' +
            '  </div>\n' +
            '</div>\n';


        const loading = await this.loadingController.create({
            cssClass: 'app-loading',
            mode: 'md',
            showBackdrop: false,
            duration: 0,
            leaveAnimation: enterAnimation,
            message: message
        });
        await loading.present();
        this.loading = loading;

        return loading;
    }

    async dismissLoading() {

        if (typeof this.loading !== 'undefined') {
            console.log('dismiss');
            this.loading.dismiss();
        }
        return true;
    }
}
