export const environment = {
  production: true,
  platform: 'pwa',
  baseUrl: 'https://devadmin.qrmenu.cl',
  baseUrlApp: 'https://devapp.qrmenu.cl',
  // apiUrl: 'http://127.0.0.1:8000/api',
  apiUrl: 'https://admin.qrmenu.cl/api',
  name: 'reserva',
  type: 'reserva',
  oneSignalId: '3f0773b3-3fe0-4865-8d30-dcce5530829a',
  mercadoPagoPublicKey: 'APP_USR-5b278405-986c-4bf2-90c0-2fac9538ae2d',
  firebase: {
    apiKey: 'AIzaSyCKSw5VDTEDPuu0M9tv3T5WGigGTeXyKbc',
    authDomain: 'qr-menu-283613.firebaseapp.com',
    databaseURL: 'https://qr-menu-283613.firebaseio.com',
    projectId: 'qr-menu-283613',
    storageBucket: 'qr-menu-283613.appspot.com',
    messagingSenderId: '1081183396258',
    appId: '1:1081183396258:web:a62e5429ae9821e1f552fb',
    measurementId: 'G-E1ERTZQJ8L'
  }
};
