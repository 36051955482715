import {
    AfterViewInit,
    Component,
    OnInit,
    EventEmitter,
    Output,
    ViewChild,
    HostListener,
    SimpleChanges
} from '@angular/core';
import {Input, ElementRef, Renderer2} from '@angular/core';
import {DomController, Platform} from '@ionic/angular';
import {Banner, Orden, Tienda} from '../../interfaces/interfaces';
import {DataService} from '../../services/data.service';
import * as moment from 'moment';

@Component({
    selector: 'app-tienda-modal',
    templateUrl: './tienda-modal.component.html',
    styleUrls: ['./tienda-modal.component.scss'],
})
export class TiendaModalComponent implements OnInit {

    @Input() tienda: Tienda;
    @Input() fixed = false;
    @Input() ordenes;
    @Input() subsBanners: any;
    ordenActiva: string;
    activas: Orden[];
    tiendas: any;

    constructor() {
    }

    async ngOnInit() {
        await this.loadInfo();
    }

    async ngOnChanges(changes: SimpleChanges) {
        await this.loadInfo();
    }

    async loadInfo() {
        // console.log(this.tienda);
        this.tiendas = this.tienda ? Number(this.tienda.tiendasCount) : 0;

        if (this.ordenes && this.ordenes.length) {
            this.activas = this.ordenes.filter(
                orden => (!orden.cerrado && moment.duration(moment().diff(orden.fechaMoment)).asHours() < 72));

            if (this.activas.length) {
                this.ordenActiva = '#ff3600';
            } else {
                this.ordenActiva = '';
            }

        }

    }

}
