import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {ApiService} from '../../services/api.service';
import {DataService} from '../../services/data.service';
import {UiService} from '../../services/ui.service';
import {AlertController, ModalController, NavController, Platform} from '@ionic/angular';
import {EventsService} from '../../services/events.service';
import {
    Alternativa,
    Cupon,
    Direccion,
    Item,
    Mesa,
    Oneclick,
    Orden,
    Pedido,
    Producto,
    Seleccion,
    Tienda,
    Usuario
} from '../../interfaces/interfaces';
import {Subscription} from 'rxjs';
import {TooltipMsg} from '../../components/tooltip-msg/tooltip-msg.component';
import { CompartirPage } from '../compartir/compartir.page';
import { ProductoDetallePage } from '../producto-detalle/producto-detalle.page';
@Component({
  selector: 'app-carrito-compra',
  templateUrl: './carrito-compra.page.html',
  styleUrls: ['./carrito-compra.page.scss'],
})
export class CarritoCompraPage implements OnInit, OnDestroy {
  subscriptions: (Subscription | any)[] = [];
  items: Item[] = [];
  itemsTienda: Array<Item[]>;
  productos: Producto[] = [];
  selecciones: Seleccion[] = [];
  alternativas: Alternativa[] = [];
  tienda: Tienda;
  tiendaVacia: Tienda;
  usuario: Usuario;
  usuarioTienda;
  sessionId;
  mesas: Mesa[] = [];
  mesaId;
  mesa: Mesa;
  tipoEntrega: string = null;
  tiposEntrega: any[];
  itemEliminar: Item;
  decimales = '1.0-0';
  locale = 'es-CL';
  simboloMoneda = '$';
  productosIds: string;
  direcciones: Direccion[];
  direccionId;
  direccion: Direccion;
  telefono = '56912345678';
  tooltipPedido: TooltipMsg;

  pedido: Pedido = {
    id: null,
    mesaId: null,
    mesaNombre: null,
    usuarioId: null,
    usuarioNombre: null,
    tienda: null,
    tiendaId: null,
    tiendaSlug: null,
    tiendaNombre: null,
    session: null,
    monto: 0,
    descuento: 0,
    total: 0,
    tiempoEntrega: 0,
    tipoEntrega: null,
    observaciones: null,
    medioPago: null,
    estado: 'Recibido',
    items: [],
    propio: true,
    created: null,
    aceptado: null,
    rechazado: null,
    listo: null,
    entregado: null,
    pagado: null,
    cerrado: null,
    fechaMoment: null,
    usuario: null,
    celular: null,
    enviadoPOS: null,
    modificadoTienda: null,
    solicitaPago: null,
    habilitaPago: null
  };

  orden: Orden;
  abierto: boolean;
  keyboardVisible = false;
  pCelular;
  ordenes = [];
  tiendaIds = [];
  preTipoEntrega;
  tieneRecomendaciones:any = [];
  tokenValidacion:any = null;
  constructor(
      private modalController: ModalController,
      private alertController: AlertController,
      private modal:ModalController,
      private activatedRoute: ActivatedRoute,
      private apiService: ApiService,
      private dataService: DataService,
      private uiService: UiService,
      private navCtrl: NavController,
      private platform: Platform,
      private router: Router,
      private eventsService: EventsService,
      private cdr: ChangeDetectorRef) {
  }
  close(){
    this.modal.dismiss();
  }
  ngOnInit() {
      this.loadData();
      this.dataSubscribe();

      // this.eventsService.subscribe('pedidos:update', () => {
      //     this.loadData();
      // });
      this.tokenValidacion = this.uuidv4()
  }
  uuidv4() {
    // @ts-ignore
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        // tslint:disable-next-line:no-bitwise
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
 }
  goBack() {
      this.navCtrl.navigateRoot(this.tienda && this.tienda.slug ? '/' + this.tienda.slug + '/home' : '/');
  }

  async ionViewDidEnter() {
      this.loadData();
      this.ordenes = await this.dataService.getOrdenes();
  }
 async editar(item){
     console.log('item',item) 
     
    const modal = await this.modalController.create({
        component: ProductoDetallePage,
        mode: 'ios',
        cssClass: 'my-custom-class',
        swipeToClose: true,
        presentingElement: await this.modalController.getTop(), 
        componentProps: {
         itemCarrito: item,
         producto: await this.dataService.getProducto(item.productoId)
        }
      });
      await modal.present();
 }
  async doRefresh(event) {
    this.loadData();
    this.ordenes = await this.dataService.getOrdenes();
      event.target.complete();
 
  }
  ngOnDestroy() {
    this.eventsService.publish('items:update', null);
      this.subscriptions.forEach((subscription: Subscription, index: number) => {
          subscription.unsubscribe();
      });
  }

  async dataSubscribe() {

      this.subscriptions.push(this.eventsService.subscribe('data:update', () => {
          this.loadData(false);
      }));

      this.subscriptions.push(this.eventsService.subscribe('items:update', () => {
          this.loadData(false);
      }));

      this.subscriptions.push(this.eventsService.subscribe('user:login', () => {
          this.loadUserData();
          this.loadDireccionesData();
      }));

      this.subscriptions.push(this.eventsService.subscribe('user:logout', async () => {
          console.log('item-lista-logout');
          await this.loadUserData();
          await this.loadDireccionesData();
      }));

      this.subscriptions.push(this.eventsService.subscribe('mesa:update', () => {
          this.loadMesaData();
      }));

      this.subscriptions.push(this.eventsService.subscribe('item:eliminarCarro', () => {
          this.eliminarItem();
      }));

      this.subscriptions.push(this.eventsService.subscribe('direcciones:update', () => {
          this.loadDireccionesData();
      }));
  }

  async loadData(redirect = true) {
      this.items = await this.dataService.getItems();
      this.itemsTienda = await this.dataService.getItemsByTienda();
      console.log('items',this.items);
      console.log('itemsTienda', this.itemsTienda);
      console.log('anterior es items');

      // this.items = this.items.filter((item, index) => {
      //     return (item.tiendaId === this.tienda.id);
      // });
      this.tiendaVacia = null;
      this.tienda = await this.dataService.getStorageData('tienda');
      if (this.items.length === 0) {
          // if (redirect) {
          //     this.uiService.presentToast('Tu carro está vacío');
          //     console.log('nav2');
          //     this.dataService.goHome(); 
          // }
          await this.calcularTotal();
          return;
      }

      const firstItem: Item = this.items[0];

      this.productos = await this.dataService.getStorageData('productos');
      // this.tienda = await this.dataService.getTienda(firstItem.tiendaId);
      if (!this.tienda) {
          if (redirect) {
              this.uiService.presentToast('Item sin tienda, por favor reintente');
              await this.vaciarCarro();
              console.log('nav1');
              this.dataService.goHome(); 
          }
          console.log(firstItem);
          return;
      }
      this.ordenes = await this.dataService.getOrdenes();
      console.log(this.tienda);
      this.abierto = this.dataService.isAbierto(this.tienda);

      // if (this.tiposEntrega.length === 1) {
      //     this.pedido.tipoEntrega = this.tiposEntrega[0];
      // }

      this.orden = await this.dataService.getOrden();

      // this.tipoEntrega = await this.dataService.getStorageData('tipoEntrega' + this.tienda.id);
      // if (this.tipoEntrega) {
      //     this.pedido.tipoEntrega = this.tipoEntrega;
      // }

      await this.loadMesaData();
      await this.loadDireccionesData();

      this.tiposEntrega = [];
      console.log(this.tiposEntrega);

      this.usuarioTienda = await this.dataService.getStorageData('usuarioTienda');
      if (this.usuarioTienda) {
          this.usuario = await this.dataService.getStorageData('usuario');
          const mesaIds = this.usuario.mesaIds || [];

          const mesas = await this.dataService.getStorageData('mesas');
          const mesasArray = Object.keys(mesas).map((idx) => {
              return mesas[idx];
          });

          this.mesas = mesasArray.filter((mesa: Mesa, index) => {
              return (mesaIds.length === 0 || mesaIds.indexOf(mesa.id) !== -1);
          });

          const tipoEntrega = this.tienda.servirPrepago ? 'servirPrepago' : 'servir';
          this.tipoEntrega = tipoEntrega;
          this.tiposEntrega = [tipoEntrega];
      } else {
          this.preTipoEntrega = await this.dataService.getStorageData('preTipoEntrega');
          if (this.preTipoEntrega) {
              this.tipoEntrega = this.preTipoEntrega;
              if (this.tipoEntrega === 'despacho') {
                  await this.tipoEntregaChange();
              }
              this.tiposEntrega.push(this.tipoEntrega);
              const preDireccionId = await this.dataService.getStorageData('preDireccionId');
              if (preDireccionId) {
                  this.direccionId = preDireccionId;
                  await this.direccionChange();
              }
          } else {
              if (this.mesa && this.tienda.servir) {
                  const tipoEntrega = this.tienda.servirPrepago ? 'servirPrepago' : 'servir';
                  console.log(this.tienda);
                  this.tipoEntrega = tipoEntrega;
                  this.tiposEntrega = [tipoEntrega];
              } else {
                  if (this.tienda.llevar) {
                      this.tiposEntrega.push('llevar');
                  }
                  if (this.tienda.despacho && this.tienda.direccionStringCompleto) {
                      this.tiposEntrega.push('despacho');
                  }
                  if (this.tiposEntrega.length > 0) {
                      this.tipoEntrega = this.tiposEntrega[0];
                  }
              }
          }
      }
      console.log(this.tiposEntrega);


      const volver = await this.dataService.getStorageData('volverItemLista');
      if (volver) {
          await this.dataService.setStorageData('volverItemLista', false);
          this.tipoEntrega = volver;
      }

      this.tiposEntrega = [...new Set(this.tiposEntrega)];
      this.pedido.tipoEntrega = this.tipoEntrega;
      console.log(this.tipoEntrega, this.pedido.tipoEntrega, this.tiposEntrega);
      console.log(this.tipoEntrega || this.tiposEntrega.length === 1);

      const tiendaNombres = [];
      this.items.forEach((item: Item, aIdx: 0) => {
          if (tiendaNombres.indexOf(item.tiendaNombre) === -1) {
              tiendaNombres.push(item.tiendaNombre);
          }
      });

      this.orden.tiendaId = this.tienda.id;
      this.orden.tiendaNombre = tiendaNombres.join(' | ');
      this.orden.tiendaSlug = this.tienda.slug;

      this.pedido = await this.dataService.getStorageData('pedido' + this.tienda.id) || this.pedido;
      this.pedido.items = this.items;
      // if (this.orden.pedidos.length > 0){
      //     this.pedidos = this.orden.pedidos;
      // }

      // this.selecciones = await this.dataService.getStorageData('selecciones');
      // this.alternativas = await this.dataService.getStorageData('alternativas');
      // console.log(this.items);
      // console.log(this.productos, this.selecciones, this.alternativas);

      await this.loadUserData();
      await this.calcularTotal();

      this.decimales = await this.dataService.getStorageData('decimales');
      this.locale = await this.dataService.getStorageData('locale');
      this.simboloMoneda = await this.dataService.getStorageData('simboloMoneda');
      this.telefono = await this.dataService.getStorageData('telefono');

      this.productosIds = this.pedido.items.map((e) => {
          return e.productoId;
      }).join(',');

      this.ordenes = await this.dataService.getOrdenes();
  }

  async loadMesaData() {
      if (this.tienda) {
          this.mesa = await this.dataService.getStorageData('mesa' + this.tienda.id);
          console.log(this.mesa);
          if (this.mesa) {
              if (this.pedido) {
                  this.pedido.mesaId = this.mesa.id;
              }

              if (this.orden) {
                  this.orden.mesaId = this.mesa.id;
                  this.orden.mesaNombre = this.mesa.nombre;
              }
          }
          if (this.pedido) {
              this.pedido.tiendaId = this.tienda.id;
          }
          console.log(this.pedido);
      }

  }

  async loadDireccionesData() {
      this.direcciones = await this.dataService.getStorageData('direcciones') || null;
      if (!this.direcciones) {
          await this.apiService.get('/jwt/direccion/');
          this.direcciones = await this.dataService.getStorageData('direcciones') || [];
      }
  }

  async loadUserData() {
      this.usuario = await this.dataService.getStorageData('usuario');
      this.sessionId = await this.dataService.getStorageData('sessionId');

      if (this.usuario) {
          this.pedido.usuarioId = this.usuario.id;
          this.pedido.usuario = this.usuario;
      } else {
          this.pedido.session = this.sessionId;
      }

      this.pCelular = this.usuario ? this.usuario.celular : this.pedido.celular;
  }
  async descontar(id,tienda,producto){
    for (let index = 0; index < this.items.length; index++) {
        if (this.items[index].id==id && this.items[index].tiendaId==tienda && this.items[index].productoId==producto){

            this.items[index].cantidad--;

        }
        
    }
    this.calcularTotal();
    await this.dataService.setStorageData('items', this.items);
    
  }
  async aumentar(id,tienda,producto){
    for (let index = 0; index < this.items.length; index++) {
        if (this.items[index].id==id && this.items[index].tiendaId==tienda && this.items[index].productoId==producto){

            this.items[index].cantidad++;

        }
        
    }
    
    this.calcularTotal();
    await this.dataService.setStorageData('items', this.items);
    
   }
  async calcularTotal() {
      // this.orden.pedidos = [];
      this.pedido.monto = 0;
      this.pedido.descuento = 0;
      this.pedido.total = 0;
      this.tiendaIds = [];
      let multiPago = true;
      this.items.forEach((item: Item, aIdx: 0) => {
          this.pedido.monto += Number(item.precio) * item.cantidad;
          this.pedido.descuento += Number(item.descuento) * item.cantidad;
          // this.pedido.total += item.precioFinal * item.cantidad;
          if (this.tiendaIds.indexOf(item.tiendaId) === -1) {
              this.tiendaIds.push(item.tiendaId);
          }

          if (!item.tiendaMultiPago) {
              multiPago = false;
          }
      });

      if (!multiPago || this.mesa) {
          const uniqueItems = [...new Set(this.tiendaIds)];
          if (uniqueItems.length > 1) {
              await this.uiService.presentToast('Error de carro por favor reintente');
              await this.vaciarCarro();
              console.log('nav3');
              await this.navCtrl.navigateBack('/');
              return;
          }
      }

      this.pedido.total = this.pedido.monto - this.pedido.descuento;
      console.log(this.pedido);
  }

  async itemTapped(item: Item) {
      console.log(item);
      await this.router.navigate(['item-form/item/' + item.id]);
  }

  async pagar() {
      let error = null;
      if (this.pedido.tipoEntrega === 'despacho') {
          if (!this.direccionId) {
              error = 'Debes seleccionar una dirección válida';
          } else if (this.tienda.montoMinimoDespacho > 0 && this.pedido.total < this.tienda.montoMinimoDespacho) {
              error = 'El monto del carro es inferior al monto mínimo de despacho';
          }
      }

      if (!this.abierto) {
          error = 'Este restaurant se encuentra cerrado';
      }

      if (error) {
          await this.uiService.presentToast(error);
          return;
      }

      console.log('orden', this.pedido,this.orden)
      // const orden = await this.dataService.createOrden();
      if (this.pedido.tipoEntrega !== 'servirPrepago') {
          this.pedido.mesaId = null;
          this.pedido.mesaNombre = null;
      }
      this.orden.pedidos = [];
      this.orden.pedidos.push(this.pedido);
      this.orden.tipoEntrega = this.pedido.tipoEntrega;
      if (this.pedido.tipoEntrega === 'despacho') {
          this.orden.direccionId = this.direccionId;
          await this.dataService.setStorageData('ordenDireccion', this.direccion);
      }
      await this.dataService.setStorageData('orden', this.orden);


      console.log(this.usuario);
      if (!this.usuario) {
          await this.dataService.setStorageData('volverCarro', true);
          if (false) {
              await this.uiService.presentToast('Debes iniciar sesión para realizar un pedido con retiro o despacho');
              await this.router.navigate(['/login']);
          } else {
              this.modal.dismiss();
              await this.uiService.presentToast('Debes completar los datos para realizar un pedido con retiro o despacho');
              await this.router.navigate(['/softlogin']);
          }
          return;
      }
      
      // console.log(orden);
      this.modal.dismiss();
      await this.router.navigate(['/orden-crear']);
      return;
  }

  async enviar() {
      let error = null;
      if (!this.pedido.tipoEntrega) {
          error = 'Debes seleccionar una forma de entrega para continuar';
      } else if (this.pedido.tipoEntrega === 'servir') {
          // const pCelular = this.usuario ? this.usuario.celular : this.pedido.celular;
          if (this.usuarioTienda && !this.mesa) {
              error = 'Debes seleccionar una mesa para continuar';
          } else if (this.pCelular && this.pCelular.length > 0 && this.pCelular.length !== 11) {
              error = `Debes ingresar tu celular completo (${this.telefono})`;
          }
      }

      if (this.pCelular && this.pCelular.length > 0) {
          this.pedido.celular = this.pCelular;
      }
      console.log('this.pCelular', this.pCelular, this.pedido);
    
      if (error) {
          await this.uiService.presentToast(error);
          return;
      }

      // return;

      if (!this.mesa && this.pedido.tipoEntrega === 'servir') {
          await this.dataService.setStorageData('pedido' + this.tienda.id, this.pedido);
          await this.dataService.setStorageData('volverCarro', true);
          await this.router.navigate(['qr-scanner']);
          return;
      } else {
          console.log(this.pedido);
          const pedidoData = this.dataService.getPedidoData(this.pedido);

          console.log(this.orden, pedidoData);
          // return;

          const loading = await this.uiService.presentLoading('Enviando datos...');
          pedidoData.tokenValidacion = this.tokenValidacion ;
          const result = await this.apiService.post('/jwt/pedido/new', pedidoData);
          console.log(result);
          if (result) {
              await this.dataService.setStorageData('tipoEntrega' + this.tienda.id, this.pedido.tipoEntrega);
              this.eventsService.publish('pedidos:update', null);
              // this.pedidos.push(this.pedido);
              // await this.dataService.setStorageData('pedidos', this.pedidos);
              await this.vaciarCarro();

              // this.orden.pedidos.push(this.pedido);
              // await this.dataService.setStorageData('orden', this.orden);
              this.eventsService.publish('ordenes:update', null);
              await this.dataService.setStorageData('pedido' + this.tienda.id, null);
                this.modal.dismiss();
              if (this.usuarioTienda) {
                  await this.router.navigate(['/pedidos-home']);
              } else {
                  await this.uiService.presentAlert('Pedido realizado correctamente. ' + (this.pedido.celular ? 'Te notificaremos por WhatsApp sobre tu pedido.' : ''), 'Exito!');
                  await this.router.navigate(['/carrito-pedido/' + this.orden.mesaId + '-mesa']);
              }
              console.log(this.orden, pedidoData);
          }
          await loading.dismiss();


      }
  }

  async vaciarCarro() {
      this.items = [];
      await this.dataService.setStorageData('items', this.items);
      this.eventsService.publish('items:update', null);
  }

  async eliminarTapped(item) {
      this.itemEliminar = item;
      await this.uiService.presentAlertConfirm('Está seguro que desea eliminar este producto?', 'Atención', 'item:eliminarCarro');
  }
  onImgError(event) {
    event.target.src = this.tienda?.fotoProductoNameS3 ? this.tienda?.fotoProductoNameS3 : this.tienda?.logoName;
}
  async eliminarItem() {
      const item = this.itemEliminar;
      if (item) {
          // console.log(item, this.items);
          this.items.splice((Number(item.id)), 1);
          // console.log(this.items);
          this.items.forEach((aItem: Item, iKey: number) => {
              aItem.id = iKey;
          });
          // console.log(this.items);
          await this.dataService.setStorageData('items', this.items);
          this.eventsService.publish('items:update', null);

          

          const dataLayer = window['dataLayer'] || [];
          dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
          dataLayer.push({
            event: "remove_from_cart",
            ecommerce: {
              items: [{
                item_name: this.itemEliminar.nombre, // Name or ID is required.
                item_id: this.itemEliminar.productoId,
                price: this.itemEliminar.precioFinal, 
                item_category: this.itemEliminar.categoriaNombre, 
                index: this.itemEliminar.id,
                quantity: this.itemEliminar.cantidad,
                item_brand: this.tienda.nombre
              }]
            }
          });
          console.log('datalayer',dataLayer)
          this.itemEliminar = null;

      }
  }

  celularFormat(input) {
      if (input) {
          this.pCelular = input.replace(/[^\d]/g, '');
          // console.log($event);
      }
  }

  showRecomendacionesPedido(ev) {
      if (ev && ev.length > 0) {
          let message = 'Te gustaria algo mas? Te recomendamos';
          this.tooltipPedido = {
              classes: 'showing ion-float-right cbl jumps',
              content: `<span>${message}</span>`
          };
      }
  }


  async direccionChange() {
      const direccionId = this.direccionId;
      console.log(direccionId);
      if (direccionId === 'agregar-direccion') {
          this.direccionId = null;
          const invitado = await this.dataService.getStorageData('invitado');
          if (invitado) {
              await this.dataService.setStorageData('volverCarro', true);
              await this.uiService.presentToast('Debes iniciar sesión para poder agregar una dirección');
              await this.router.navigate(['login']);
          } else {
              console.log(this.pedido.tipoEntrega);
              await this.dataService.setStorageData('volverItemLista', this.pedido.tipoEntrega);
              await this.router.navigate(['/secured', 'direccion-form', 0]);
          }
      } else if (direccionId > 0) {
          this.direccion = this.direcciones.find(
              aDireccion => aDireccion.id === direccionId);
          console.log(this.direccion, direccionId, this.tiendaIds);
          for (const tiendaId of this.tiendaIds) {
              const tiendaArray = await this.dataService.getStorageData('tiendaArray' + tiendaId);
              const tienda = tiendaArray.tiendas.find(
                  aTienda => aTienda.id === tiendaId);
              console.log(tienda, 'tienda' + tiendaId, tienda.zonaDespacho);
              if (tienda.zonaDespacho) {
                  const classifyPoint = require('robust-point-in-polygon');
                  const zonaDespachoArray = JSON.parse(tienda.zonaDespacho);
                  const polygon = [];
                  for (const zonaDespachoData of zonaDespachoArray) {
                      polygon.push([zonaDespachoData.lng, zonaDespachoData.lat]);
                  }
                  const point = [this.direccion.lng, this.direccion.lat];
                  console.log(polygon, point, classifyPoint(polygon, point));

                  if (classifyPoint(polygon, point) === 1) {
                      // this.direccionId = null;
                      this.clearSelection();
                      // this.cdr.detectChanges();
                      await this.uiService.presentAlert('Lo sentimos, el local ' + tienda.nombre + ' no realiza despachos a la dirección seleccionada.', 'Atención');
                      return;
                  }

                  // console.log(
                  //     classifyPoint(polygon, [1.5, 1.5]),
                  //     classifyPoint(polygon, [1, 2]),
                  //     classifyPoint(polygon, [100000, 10000]));
              }
          }
      }
  }

  clearSelection() {
      this.direccionId = null;
      console.log(this.direccionId);
  }

  async tipoEntregaChange() {
      this.clearSelection();
      if (this.pedido.tipoEntrega === 'despacho') {
          const loading = await this.uiService.presentLoading('Cargando datos...');
          await this.apiService.get('/jwt/tienda/multiple/' + this.tiendaIds.join('-'));
          await loading.dismiss();
      }
  }

  async mesaChange() {
      const mesaId = this.mesaId;

      const mesas = await this.dataService.getStorageData('mesas');
      const mesasArray = Object.keys(mesas).map((idx) => {
          return mesas[idx];
      });

      this.mesa = mesasArray.find((mesa: Mesa, index) => {
          return mesa.id === mesaId;
      });

      this.pedido.mesaId = this.mesa.id;
      this.orden.mesaId = this.mesa.id;
      this.orden.mesaNombre = this.mesa.nombre;
  }

  async addComentario() {
    const alert = await this.alertController.create({
    
        header: 'Comentarios',
        inputs: [
          {
            name: 'obs',
            type: 'textarea',
            value: this.pedido.observaciones,
            placeholder: 'Comentarios'
          }
        ],
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              console.log('Confirm Cancel');
            }
          }, {
            text: 'Guardar',
            handler: (d) => {
              this.pedido.observaciones = d.obs;
              console.log('Confirm Ok',d);
            }
          }
        ]
      });
  
      await alert.present();
  }
  async compartir(){

    const pedidoData = this.dataService.getPedidoData(this.pedido);

    const modal = await this.modalController.create({
        component: CompartirPage,
        cssClass: 'my-custom-class',
        mode:'ios',
        componentProps: {
          'pedido': pedidoData 
        }
      });
      return await modal.present();
}
async verProducto(item) {
    const modal = await this.modalController.create({
      component: ProductoDetallePage,
      mode: 'ios',
      cssClass: 'my-custom-class',
      swipeToClose: true,
      presentingElement: await this.modal.getTop(), 
      componentProps: {
        producto: item
      }
    });
    await modal.present();
  
}
cambioProducto(ev) {
    this.tieneRecomendaciones=ev;
}

}
