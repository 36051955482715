import {Injectable} from '@angular/core';
import {Plugins} from '@capacitor/core';
import * as moment from 'moment';
import {UiService} from './ui.service';
import {
    Banner,
    Cupon,
    Item,
    Mesa,
    Notificacion,
    Orden,
    PageView,
    Pedido,
    Producto,
    Tienda,
    TiendaHorario
} from '../interfaces/interfaces';
import 'moment/locale/es';
import {EventsService} from './events.service';
import {Router} from '@angular/router';
import {AlertController, NavController} from '@ionic/angular';
import {environment} from '../../environments/environment';

const {Storage} = Plugins;


const S3 = 'https://d4i7e0edqqgwm.cloudfront.net/documents/';

declare var MercadoPago: any;

@Injectable({
    providedIn: 'root'
})
export class DataService {
    private data = []; ;
    constructor( 
                private alertController: AlertController,
                private eventsService: EventsService,
                private uiService: UiService,
                private router: Router,
                private navCtrl: NavController) {
                    this.init();
    }
     async init() {
     
    
      }
    setData(id, data) {
        this.data[id] = data;
    }

    getData(id) {
        return this.data[id];
    }

    async getStorageData(key) {
        const ret = await Storage.get({key});
        // console.log(key, ret, this.isJsonString(ret));

        if (typeof ret.value !== 'undefined') {
            if (!this.isJsonString(ret.value)) {
                await this.removeStorageData(key);
                return null;
            } else {
                const sData = JSON.parse(ret.value);
                // console.log(id, sData);
                return sData;
            }
        }
    }

    isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    async setStorageData(key, data) {
        
        console.log('tiendadata', key, data, new Date())
    
    await Storage.set({
        key,
        value: JSON.stringify(data)
    });
    return null;
    }

    async removeStorageData(key) {
        // console.log(key);
        // console.log(await Storage.keys());
        await Storage.remove({key});
    }


    async clearStorageData() {
        // const tipo = await this.getStorageData('tipo');
        // const pushToken = await this.getStorageData('pushToken');
        // const pushSubscribed = await this.getStorageData('pushSubscribed');
        // const sessionId = await this.getStorageData('sessionId');
        // const tokenInvitado = await this.getStorageData('tokenInvitado');
        // const currentLang = await this.getStorageData('currentLang');
        // const socialSubscribe = await this.getStorageData('socialSubscribe');
        // const slugQR = await this.getStorageData('slugQR');
        // const tienda = await this.getStorageData('tienda');
        // const landingMesa = await this.getStorageData('landingMesa');
        //
        // await Storage.clear();

        // await this.setStorageData('tipo', tipo);
        // await this.setStorageData('pushToken', pushToken);
        // await this.setStorageData('pushSubscribed', pushSubscribed);
        // await this.setStorageData('sessionId', sessionId);
        // await this.setStorageData('tokenInvitado', tokenInvitado);
        // await this.setStorageData('invitado', true);
        // await this.setStorageData('currentLang', currentLang);
        // await this.setStorageData('socialSubscribe', socialSubscribe);
        // await this.setStorageData('slugQR', slugQR);
        // await this.setStorageData('tienda', tienda);
        // await this.setStorageData('cupones', []);
        // await this.storeOrdenes([]);
        // await this.setStorageData('landingMesa', landingMesa);

        await this.setStorageData('invitado', true);
        await this.removeStorageData('usuarioTienda');
        await this.removeStorageData('usuario');
        await this.removeStorageData('direcciones');
        await this.removeStorageData('oneclicks');
        await this.removeStorageData('mercadoPagoCards');
        await this.storeCupones([]);
        await this.storeNotificaciones([]);
        await this.storeOrdenes([]);
        await this.storePedidos([]);
    }

    // async getTiendas(id) {
    //   let keyMap = await this.storage.get('actividadesKeymap');
    //   let key = keyMap[id];
    //   let actividades = await this.getActividades();
    //   return actividades[key];
    // }

    async storeTiendas(tiendas) {
        const idTiendas = await this.getStorageData('idTiendas') || {};
        const slugTiendas = {};

        tiendas.forEach((value: any, key: string) => {
            const tienda: Tienda = value;
            if (tienda.slugManual && tienda.slugManual !== '') {
                tienda.slug = tienda.slugManual;
            }

            const slug = tienda.slug;

            if (tienda.imagenName) {
                tienda.imagenName = `${S3}` + 'tienda/' + tienda.imagenName;
            }

            if (tienda.logoName) {
                tienda.logoName = `${S3}` + 'tienda/' + tienda.logoName;
            }

            idTiendas[tienda.id] = tienda;
            slugTiendas[slug] = tienda;
        });
        // console.log(tiendas);

        await this.setStorageData('tiendas', tiendas);
        await this.setStorageData('idTiendas', idTiendas);
        await this.setStorageData('slugTiendas', slugTiendas);

        return tiendas;
    }

    async storeOrdenes(ordenes: Orden[], resena: boolean = false, url = null) {
        console.log('Store Ordenes', ordenes, resena);
        // resena = true;
        const minutesResena = 30;
        const idOrdenes = {};
        let itemsCount = 0;
        const usuarioTienda = await this.getStorageData('usuarioTienda');
        let firstResena = true;

        /*let actualOrdenes = await this.getStorageData('ordenes');
        actualOrdenes = actualOrdenes ? actualOrdenes : [];
        ordenes.forEach((orden: Orden) => {

            let existe =  actualOrdenes.find(x => x.id == orden.id);
            if (existe == null || existe == undefined){
                actualOrdenes.push(orden);
            }else{
                let index = actualOrdenes.indexOf(existe);
                actualOrdenes[index] = orden;
            }
        })*/

        // await this.setStorageData('ordenResena', null);
        for (const orden of ordenes) {
            if (typeof orden.ordenes !== 'undefined' && orden.ordenes.length > 0) {
                itemsCount = 0;
                const tiendaNombres = [];
                for (const ordenChild of orden.ordenes) {
                    if (ordenChild.resenaModal) {
                        orden.resenaModal = true;
                    }
                    await this.processOrden(ordenChild, itemsCount, idOrdenes);
                    itemsCount += ordenChild.itemsCount;
                    if (tiendaNombres.indexOf(ordenChild.tiendaNombre) === -1) {
                        tiendaNombres.push(ordenChild.tiendaNombre);
                    }
                }
                orden.key = orden.mesaNombre ? orden.mesaNombre : '#'+orden.id.toString();
                orden.itemsCount = itemsCount;
                orden.tiendaNombre = tiendaNombres.join(' | ');
                idOrdenes[orden.id] = orden;
            } else {
                await this.processOrden(orden, itemsCount, idOrdenes);
            }

            // console.log(!usuarioTienda, resena, !orden.resenaModal, !orden.rechazado, orden.tipoEntrega, firstResena);
            if (!usuarioTienda && resena && !orden.resenaModal && !orden.rechazado) {
                const cerradoMoment = moment(orden.cerrado);
                const now = moment();
                const duration = moment.duration(now.diff(cerradoMoment));
                const minutes = duration.asMinutes();
                // console.log(minutes);
                if (orden.tipoEntrega === 'servir' || minutes > minutesResena) {
                    if (firstResena) {
                        firstResena = false;
                        // console.log(orden);
                        await this.setStorageData('ordenResena', orden);
                        if (orden.tipoEntrega !== 'servir') {
                            this.eventsService.publish('resena:modal');
                        }
                    }
                }
            }
        }

        // console.log(ordenes, idOrdenes);

        await this.setStorageData('ordenes', ordenes);
        await this.setStorageData('idOrdenes', idOrdenes);

        this.eventsService.publish('ordenes:update', null);
    }

    async processOrden(orden, itemsCount, idOrdenes) {
        if (orden.pedidos.length > 0) {
            itemsCount = 0;
            idOrdenes[orden.id] = orden;
            // console.log(orden.imagenName);
            if (orden.imagenName) {
                orden.imagenName = `${S3}` + 'tienda/' + orden.imagenName;
            }
            // console.log(orden.imagenName);
            orden.key = orden.mesaNombre ? orden.mesaNombre : '#'+orden.id.toString();
            orden.pedidos.forEach((pedido: Pedido, pKey: number) => {
                if (!pedido.rechazado) {
                    itemsCount += pedido.items.length;
                }

                pedido.items.forEach((item: Item, iKey: number) => {
                    if (item.imagenName) {
                        item.imagenName = `${S3}` + 'producto/' + item.imagenName;
                    }
                });
            });
            orden.itemsCount = itemsCount;
        }
    }

    async storePedidos(pedidos) {
        const ordenesObject = {};
        const usuarioTienda = await this.getStorageData('usuarioTienda');
        const mesasVirtual = [];

        let actualPedido = await this.getStorageData('pedidos');
        actualPedido = actualPedido ? actualPedido : [];
        
        /*pedidos.forEach((pedido: Pedido, pKey: string) => {

            let existe =  actualPedido.find(x => x.id == pedido.id);
            if (existe == null || existe == undefined){
                actualPedido.push(pedido);
            }else{
                let index = actualPedido.indexOf(existe);
                actualPedido[index] = pedido;
            }
        })
        console.log('actualpedido', actualPedido, pedidos)*/

        pedidos.forEach((pedido: Pedido, pKey: string) => {
            const oKey = pedido.mesaId + (usuarioTienda ? ('-' + pedido.estado) : '-mesa');
            if (!ordenesObject.hasOwnProperty(oKey)) {
                const orden = this.createOrden();
                orden.mesaId = pedido.mesaId;
                orden.key = pedido.mesaNombre;
                orden.mesaNombre = pedido.mesaNombre;
                orden.usuario = pedido.usuario;
                orden.usuarioId = pedido.usuarioId;
                orden.usuarioNombre = pedido.usuarioNombre;
                //orden.tienda = pedido.tienda;
                orden.simboloMoneda = pedido.simboloMoneda;
                console.log(orden.simboloMoneda);
                orden.decimalesMoneda = pedido.decimalesMoneda;
                orden.tiendaId = pedido.tiendaId;
                orden.tiendaNombre = pedido.tiendaNombre;
                orden.tiendaSlug = pedido.tiendaSlug;
                orden.tipoEntrega = pedido.tipoEntrega;
                orden.tiempoEntrega = pedido.tiempoEntrega;
                orden.created = pedido.created;
                orden.updated = pedido.updated;
                orden.aceptado = pedido.aceptado;
                // if (!orden.aceptado) {
                orden.rechazado = pedido.rechazado;
                // }
                orden.listo = pedido.listo;
                orden.entregado = pedido.entregado;
                orden.pagado = pedido.pagado;
                orden.cerrado = pedido.cerrado;
                orden.pedidoEstado = pedido.estado;
                if (pedido.solicitaPago && !pedido.habilitaPago) {
                    orden.solicitaPago = pedido.solicitaPago;
                }
                ordenesObject[oKey] = orden;
            }
            if (pedido.aceptado && !pedido.rechazado && ordenesObject[oKey].pedidoEstado === 'Rechazado') {
                ordenesObject[oKey].pedidoEstado = pedido.estado;
            }

            if (!pedido.rechazado) {
                ordenesObject[oKey].monto += Number(pedido.monto);
                ordenesObject[oKey].descuento += Number(pedido.descuento);
                ordenesObject[oKey].total += Number(pedido.total);
                ordenesObject[oKey].itemsCount += pedido.items.length;
            }

            ordenesObject[oKey].pedidos.push(pedido);

            if (mesasVirtual.indexOf(pedido.mesaId) === -1) {
                mesasVirtual.push(pedido.mesaId);
            }
        });

        const ordenes = [];
        for (const orden of Object.values(ordenesObject)) {
            ordenes.push(orden);
        }
        console.log(ordenes, ordenesObject);

        await this.setStorageData('pedidos', pedidos);
        await this.setStorageData('ordenesVirtual', ordenes);
        await this.setStorageData('ordenesVirtualObject', ordenesObject);
        await this.setStorageData('mesasVirtual', mesasVirtual);
        this.eventsService.publish('pedidos:update', null);
    }

    async storeNotificaciones(notificaciones) {
        const ordenesObject = await this.getStorageData('ordenesVirtualObject');
        const mesasVirtual = await this.getStorageData('mesasVirtual');
        const usuarioTienda = await this.getStorageData('usuarioTienda');

         
        notificaciones.forEach((notificacion: Notificacion, pKey: string) => {
            if (usuarioTienda && !notificacion.ordenId && notificacion.mesa && mesasVirtual.indexOf(notificacion.mesa.id) === -1) {
                const oKey = notificacion.mesa.id + '-notificacion';
                if (!ordenesObject.hasOwnProperty(oKey)) {
                    const orden = this.createOrden();
                    orden.mesaId = notificacion.mesa.id;
                    orden.mesaNombre = notificacion.mesa.nombre;
                    orden.tiendaId = notificacion.tiendaId;
                    orden.tiendaNombre = notificacion.tiendaNombre;
                    orden.tiendaSlug = notificacion.slugManual || notificacion.tiendaSlug
                    orden.tipoEntrega = 'servir';
                    // orden.usuario = pedido.usuario;
                    orden.usuarioId = notificacion.usuarioId;
                    orden.usuarioNombre = notificacion.usuarioNombre;
                    orden.created = notificacion.created;
                    orden.pedidoEstado = 'Notificación';
                    ordenesObject[oKey] = orden;
                }
            }
        });
         
        const ordenes = [];
        for (const orden of Object.values(ordenesObject)) {
            ordenes.push(orden);
        }
        await this.setStorageData('ordenesVirtual', ordenes);
        await this.setStorageData('ordenesVirtualObject', ordenesObject);
        await this.setStorageData('notificaciones', notificaciones);
        this.eventsService.publish('ordenes:update', null);
    }

    async storeTienda(resp) {
        // TODO: iterar de alternativas a categorias e ir anidando los que sean necesarios
        const categorias = [];
        const productosArray = [];
        const productos = {};
        const productosId = {};
        const selecciones = {};
        const seleccionesId = {};
        const alternativas = {};
        const alternativasId = {};
        const mesas = {};

        resp.alternativas.forEach((value: any, key: string) => {
            const alternativa = value;
            const psId = alternativa.psId;
            const id = alternativa.id;
            alternativasId[id] = alternativa;
            if (!alternativas.hasOwnProperty(psId)) {
                alternativas[psId] = [];
            }
            alternativas[psId].push(alternativa);
        });
        // console.log(alternativas);

        resp.selecciones.forEach((value: any, key: string) => {
            const seleccion = value;
            if (typeof alternativas[seleccion.id] !== 'undefined') {
                seleccion.alternativas = alternativas[seleccion.id];
                const pId = seleccion.pId;
                const id = seleccion.id;
                seleccionesId[id] = seleccion;
                if (!selecciones.hasOwnProperty(pId)) {
                    selecciones[pId] = [];
                }
                selecciones[pId].push(seleccion);
            }
        });
        // console.log(selecciones);

        resp.productos.forEach((value: any, key: string) => {
            const producto = value;
            const id = producto.id;
            productosId[id] = producto;
            producto.selecciones = typeof selecciones[producto.id] !== 'undefined' ? selecciones[producto.id] : [];

            if (producto.imagenName) {
                producto.imagenName = `${S3}` + 'producto/' + producto.imagenName;
            }

            const categoriaId = producto.categoriaId;
            if (!productos.hasOwnProperty(categoriaId)) {
                productos[categoriaId] = [];
            }
            productos[categoriaId].push(producto);
        });
        // console.log(productos);

        resp.categorias.forEach((value: any, key: string) => {
            const categoria = value;
            if (typeof productos[categoria.id] !== 'undefined') {
                categoria.productos = productos[categoria.id];
            } else {
                categoria.productos = [];
            }

            categorias.push(categoria);
        });
        console.log(categorias);

        resp.mesas.forEach((mesa: Mesa, key: string) => {
            const codigo = mesa.codigo;
            mesas[codigo] = mesa;
        });

        categorias.forEach((categoria: any, key: number) => {
            // console.log(categoria);
            categoria.productos.forEach((producto: Producto, pKey: number) => {
                // console.log(producto);
                productosArray.push(producto);
            });
        });
        // console.log(mesas);

        await this.setStorageData('categorias', categorias);
        await this.setStorageData('productos', productosId);
        await this.setStorageData('productosArray', productosArray);
        await this.setStorageData('selecciones', seleccionesId);
        await this.setStorageData('alternativas', alternativasId);
        await this.setStorageData('mesas', mesas);
        await this.setStorageData('superiores', resp.superiores);

        this.eventsService.publish('tienda:data-update');
    }

    async storeCupones(cupones) {
        const aCupones = [];

        // cupones.forEach((cupon: Cupon, key: string) => {
        //
        //   cupon.fechaVencimientoMoment = moment(cupon.fechaVencimiento);
        //   aCupones.push(cupon);
        // });
        // console.log(cupones);

        await this.setStorageData('cupones', cupones);

        return cupones;
    }

    async getCupones() {
        const aCupones = [];
        const cupones = await this.getStorageData('cupones');

        if (cupones) {
            cupones.forEach((cupon: Cupon, key: string) => {
                cupon.fechaVencimientoMoment = moment(cupon.fechaVencimiento);
                aCupones.push(cupon);
            });
        }
        // console.log(cupones);

        return aCupones;
    }

    async getOrdenes() {
        console.log('getOrdenes');
        const aOrdenes = [];
        const ordenes = await this.getStorageData('ordenes') || [];
        const notificaciones = await this.getStorageData('notificaciones') || [];
        console.log(notificaciones);

        for (const orden of ordenes) {
            orden.fechaMoment = moment(orden.created);
            const filterNotificaciones = await this.filterNotificaciones(notificaciones, orden);
            orden.notificaciones = filterNotificaciones.notificaciones;
            orden.notificacionesSinLeer = filterNotificaciones.sinLeer;

            if (typeof orden.ordenes === 'undefined') {
                orden.ordenes = [];
            }
            orden.tipoDato = 'orden';
            if (orden.ordenes.length > 0 || orden.pedidos.length > 0 || orden.notificaciones.length > 0) {
                aOrdenes.push(orden);
            }
        }

        const ordenesVirtual = await this.getStorageData('ordenesVirtual') || [];
      
        const mesaIds = [];
        for (const orden of ordenesVirtual) {
            let notificacionMesa = false;
            if (mesaIds.indexOf(orden.mesaId) === -1) {
                mesaIds.push(orden.mesaId);
                notificacionMesa = true;
            }
            orden.fechaMoment = moment(orden.created);
            orden.tipoDato = 'ordenVirtual';
            const filterNotificaciones = await this.filterNotificaciones(notificaciones, orden, notificacionMesa);
            orden.notificaciones = filterNotificaciones.notificaciones;
            orden.notificacionesSinLeer = filterNotificaciones.sinLeer;

            if (orden.pedidos.length > 0 || orden.notificaciones.length > 0) {
                aOrdenes.push(orden);
            }
        }
         
        aOrdenes.sort((a, b) => (a.fechaMoment.isBefore(b.fechaMoment)) ? 1 : -1);

        return aOrdenes;
    }

    async filterNotificaciones(notificaciones: Notificacion[], orden: Orden, notificacionMesa = true) {
        if (orden) {
            if (orden.id) {
                notificaciones = notificaciones.filter(
                    notificacion => notificacion.ordenId === orden.id && !notificacion.leida);
            } else if (orden.mesaId) {
                notificaciones = notificaciones.filter(
                    notificacion => notificacion.mesaId === orden.mesaId &&
                        ((notificacion.body.indexOf('realizó un pedido') !== -1 && orden.pedidoEstado === 'Recibido') || notificacionMesa)
                        && !notificacion.leida);
            }
        }

        const sinLeer = notificaciones.filter(
            notificacion => !notificacion.leida).length;

        return {
            notificaciones,
            sinLeer
        };
    }

    async getPedidos() {
        const aPedidos = [];
        const pedidos = await this.getStorageData('pedidos') || [];

        pedidos.forEach((pedido: Pedido, key: string) => {
            pedido.fechaMoment = moment(pedido.created);
            aPedidos.push(pedido);
        });
        // console.log(pedidos);

        return aPedidos;
    }

    async getTiendas() {
        const tiendas = await this.getStorageData('tiendas');
       

        return tiendas;
    }

    async getItems() {
        let items = await this.getStorageData('items');
        if (!items) {
            items = [];
        }

        return items;
    }

    async getItemsByTienda() {
        const itemsTienda = {};
        const itemsTiendaArray = [];
        let items = await this.getStorageData('items');
        if (!items) {
            items = [];
        }
        items.forEach((aItem: Item, iKey: number) => {
            if (typeof (itemsTienda[aItem.tiendaNombre]) === 'undefined') {
                itemsTienda[aItem.tiendaNombre] = [];
            }
            itemsTienda[aItem.tiendaNombre].push(aItem);
        });
   
        for (const key in itemsTienda) {
            if (itemsTienda.hasOwnProperty(key)) {
                const itemTienda = itemsTienda[key];
                itemsTiendaArray.push(itemTienda);
            }
        }

        return itemsTiendaArray;
    }

    async storeItem(item = null) {
        let items = await this.getItems();
        if (item) {
            if (item.id!=undefined && item.id!=null)
            items[item.id] = item;
        }
        let itemTemp = [];
        for (let index = 0; index < items.length; index++) {
           if (items[index]){
             itemTemp.push(items[index])
           }
            
        }
        items = itemTemp;
        items.forEach((aItem: Item, iKey: number) => {
            aItem.id = iKey;
        });
        console.log('storeitem',items);
        // items.push(item);
        await this.setStorageData('items', items);
    }
    async storeItemTemporal(itemsR = null) {
        const items = []; //await this.getItemsTemporal();
        let indexMain = 0;
        let DataMain = [];
        for (let index = 0; index < items.length; index++) {
            items[index].id = indexMain;
            DataMain.push(items[index]);
            indexMain++;
            
        }
        for (let index = 0; index < itemsR.length; index++) {
            if (itemsR[index].productoId!=null){
                itemsR[index].id = indexMain;
                DataMain.push(itemsR[index]);
                indexMain++;
            }
           
            
        }
        

       
        console.log('agre storeitemTemporal',DataMain);
        // items.push(item);
        await this.setStorageData('itemsTemporal', DataMain);
    }
    async getItemsTemporal() {
        let items = await this.getStorageData('itemsTemporal');
        if (!items) {
            items = [];
        }

        return items;
    }
    async getSlugTiendas() {
        const tiendas = await this.getTiendas();
        console.log(tiendas);
        const slugTiendas = await this.getStorageData('slugTiendas');

        return slugTiendas;
    }

    async getTienda(id) {
        const tiendas = await this.getStorageData('idTiendas');
        const tienda = tiendas && tiendas.hasOwnProperty(id) ? tiendas[id] : null;
        // console.log(tienda);
        return tienda;
    }

    async getTiendaBySlug(slug, events = true) {
        const slugTiendas = await this.getSlugTiendas();
        const tienda = slugTiendas ? slugTiendas[slug] : null;
        const oldTienda = await this.getStorageData('tienda') || null;
        console.log('limpia tienda', tienda);
        // if (tienda) {
        await this.setStorageData('tienda', tienda);
        console.log(slug, events, oldTienda, tienda);
        if (events && tienda && (!oldTienda || oldTienda.id !== tienda.id)) {
            // console.log(oldTienda, tienda);
            // return;
            this.eventsService.publish('tienda:update', tienda);
        }
        console.log(slug, tienda);
        return tienda;
    }

    async getProducto(id) {
        const productos = await this.getStorageData('productos');
        const producto = productos && productos.hasOwnProperty(id) ? productos[id] : null;
        return producto;
    }

    async getMesaByCodigo(codigo) {
        const mesas = await this.getStorageData('mesas');
        console.log('mesas', mesas, codigo);
        const mesa = mesas && mesas.hasOwnProperty(codigo) ? mesas[codigo] : null;
        return mesa;
    }

    async getOrden(id = null) {
        let orden = null;
        if (id) {
            let ordenes = await this.getStorageData('idOrdenes');
            orden = ordenes && ordenes.hasOwnProperty(id) ? ordenes[id] : null;

            if (!ordenes || !ordenes.hasOwnProperty(id)) {
                ordenes = await this.getStorageData('ordenesVirtualObject');
                console.log(ordenes);
            }

            orden = ordenes && ordenes.hasOwnProperty(id) ? ordenes[id] : null;
        } else {
            orden = await this.getStorageData('orden') || this.createOrden();
        }

        return orden;
    }

    createOrden(): Orden {
        return {
            id: null,
            codigo: null,
            cuponId: null,
            oneClickId: null,
            direccionId: null,
            imagenName: null,
            resenaModal: false,
            calificacion: null,
            itemsCount: 0,
            comentarios: null,
            pedidoEstado: 'Recibido',
            mesaId: null,
            mesaNombre: null,
            usuarioId: null,
            usuarioNombre: null,
            tienda: null,
            tiendaId: null,
            tiendaSlug: null,
            tiendaSlugs: [],
            tiendaNombre: null,
            tiendaCorreoContactoApp: null,
            tipoEntrega: null,
            tiempoEntrega: 0,
            session: null,
            monto: 0,
            descuento: 0,
            propina: 0,
            despacho: 0,
            total: 0,
            observaciones: '',
            estado: 'En curso',
            propios: true,
            medioPago: null,
            formaPago: null,
            created: null,
            aceptado: null,
            rechazado: null,
            listo: null,
            entregado: null,
            pagado: null,
            cerrado: null,
            fechaMoment: null,
            usuario: null,
            habitacion: null,
            erut: null,
            solicitarFactura: false,
            solicitaPago: null,
            notificacionesSinLeer: 0,
            estados: [],
            despachoData: [],
            ordenes: [],
            pedidos: [],
            notificaciones: [],
            decimalesMoneda: false,
            direccionStringCompleto: null,
            simboloMoneda: '$',
            decimales: '1.0-0',
            locale: 'es-CL'
        };
    }

    async goHome() {
        const slugQR = await this.getStorageData('slugQR');
        console.log(slugQR);
        // const usuarioTienda = await this.getStorageData('usuarioTienda');
        // if (usuarioTienda) {
        //     this.navCtrl.navigateBack('/pedido-lista', {animated: true});
        // } else {
        //     this.navCtrl.navigateBack(slugQR ? ('/tienda-ver/' + slugQR) : '/restaurantes', {animated: true});
        // }
        this.navCtrl.navigateBack(slugQR ? '/' + slugQR + '/home' : '/', {animated: true});
        return;
    }

    moment(fecha: string) {
        return moment(fecha);
    }

    async checkUsuarioTienda() {
        let logout = false;
        const usuario = await this.getStorageData('usuario');
        if (usuario && typeof usuario.tienda !== 'undefined' && usuario.tienda) {
            const slugQR = await this.getStorageData('slugQR');
            // const tienda = await this.getStorageData('tienda');

            if (usuario.tienda.slug !== slugQR && usuario.tienda.slugManual !== slugQR) {
                console.log('logout tienda usuario', usuario, slugQR);
                this.eventsService.publish('user:logoutApp', true);
                logout = true;
            }
        }

        return logout;
    }

    getPedidoData(pedido: Pedido) {
        const itemsData = [];
        const pedidoData: any = {
            observaciones: pedido.observaciones,
            tipoEntrega: pedido.tipoEntrega,
            estado: 'Recibido',
            tienda: pedido.tiendaId,
            mesa: pedido.mesaId,
            celular: pedido.celular,
            // personas: pedido.personas,
            items: []
        };

        if (pedido.usuarioId) {
            pedidoData.usuario = pedido.usuarioId;
        } else {
            pedidoData.session = pedido.session;
        }
        console.log('get item', pedido)
        pedido.items.forEach((item: Item, aIdx: 0) => {
            const itemData = this.getItemData(item);
            itemsData.push(itemData);
        });

        pedidoData.items = itemsData;
        console.log(pedidoData);

        return pedidoData;
    }

    getItemData(item: Item) {
        const itemData = {
            nombre: item.nombre,
            producto: item.productoId,
            cantidad: item.cantidad,
            observaciones: item.observaciones,
            tiendaId: item.tiendaId,
            itemAlternativas: []
        };

        const alternativasData = [];

        // for (const key in item.selecciones) {
        //     if (item.selecciones.hasOwnProperty(key)) {
        //         const seleccion = item.selecciones[key];
        //         if (seleccion.value) {
        //             alternativasData.push(seleccion.value);
        //         }
        //     }
        // }
        console.log(item);
        for (const key in item.alternativas) {
            if (item.alternativas.hasOwnProperty(key)) {
                const alternativa = item.alternativas[key];
                if (alternativa.cantidad > 0) {
                    alternativasData.push({
                        nombre: alternativa.nombre,
                        alternativa: alternativa.alternativaId || alternativa.id,
                        cantidad: alternativa.cantidad,
                    });
                }
            }
        }

        itemData.itemAlternativas = alternativasData;
        return itemData;
    }

    // isTienda(object: any): object is Tienda {
    //     return true;
    // }

    isAbierto(entity: any, option = 'tienda', tienda?: Tienda) {
        // console.log(this.isTienda(entity) );
        let horarios = [];
        if (typeof entity !== 'undefined') {
            horarios = option === 'tienda' ? entity.horariosTienda : entity.horarios;
            if (typeof horarios === 'undefined') {
                horarios = [];
            }
        }

        if (tienda || (option == 'tienda' && entity)) {
            if ((tienda && tienda.siempreAbierto) || (entity && entity.siempreAbierto)) {
                return true;
            }
            if ((tienda && tienda.siempreCerrado) || (entity && entity.siempreCerrado)) {
                return false;
            }
        }

        let abierto = horarios.length === 0 ? true : false;
        horarios.forEach((horario: TiendaHorario, aIdx: number) => {
            const now = moment();
            const horaInicio = moment(horario.horaInicio);
            const horaTermino = moment(horario.horaTermino);
            if (horario.dias.constructor === Array && horario.dias.indexOf(Number(now.format('E'))) !== -1 &&
                Number(horaInicio.format('Hmm')) <= Number(now.format('Hmm')) &&
                Number(now.format('Hmm')) <= Number(horaTermino.format('Hmm'))) {
                abierto = true;
            }
            // console.log(now.format('E'), now.format('Hmm'), horaInicio.format('Hmm'), horaTermino.format('Hmm'));
            // console.log(horario, horaInicio, horaTermino, now, abierto);
        });

        // console.log(abierto);
        // return false;
        return abierto;
    }

    bannerLink(banner: Banner) {
        const link = banner.link;
        if (!link) {
            return;
        }

        const url = new URL(link);
        console.log(url);
        if (url.origin === environment.baseUrlApp) {
            this.router.navigate([url.pathname]);
        } else {
            window.open(link, '_blank');
        }
        // this.router.navigate([url.pathname]);
    }

    isCambioColor(orden: Orden, tienda) {
        const now = moment();
        let isCambioColor = false;
        if (orden.aceptado && !orden.pagado && !orden.rechazado && tienda.tiempoCambioColor > 0) {
            // console.log(orden);
            const aceptado = moment(orden.aceptado).add(tienda.tiempoCambioColor, 'minutes');
            // console.log(aceptado, now);
            if (now.isAfter(aceptado)) {
                isCambioColor = true;
            }
        }

        return isCambioColor;
    }

    async alertInput() {
        const alert = await this.alertController.create({
            cssClass: 'my-custom-class',
            header: 'Ingresar código tienda',
            // message: 'Enviando desde ' + this.mesa.nombre,
            inputs: [
                {
                    name: 'slug',
                    type: 'text',
                    placeholder: 'Slug tienda',
                }
            ],
            buttons: [
                {
                    text: 'Cancelar',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        console.log('Confirm Cancel');
                    }
                }, {
                    text: 'Confirmar',
                    handler: async (alertData) => {
                        console.log('Confirm Ok');
                        console.log(alertData.slug.toLowerCase());
                        // this.eventsService.publish('tienda:llamarGarzon', alertData.mensaje);
                        await this.router.navigate([alertData.slug.replace(/ /g, '').toLowerCase()]);
                    }
                }
            ]
        });

        await alert.present();
    }

    ordenIdInterno(orden: Orden) {
        let idInterno = '';
        if (typeof orden.ordenes === 'undefined') {
            orden.ordenes = [];
        }
        if (orden.ordenes.length > 1) {
            idInterno = 'Múltiple';
        } else if (orden.ordenes.length > 0) {
            idInterno = 'Orden #' + orden.ordenes[0].id;
        } else {
            idInterno = 'Orden #' + orden.id;
        }

        return idInterno;
    }

    initMercadoPago(tienda: Tienda = null) {
        // const publicKey = tienda && tienda.mercadoPagoPublicKey ? tienda.mercadoPagoPublicKey : environment.mercadoPagoPublicKey;
        const publicKey = environment.mercadoPagoPublicKey;
        return new MercadoPago(publicKey);
    }

    formatNumber(val, digits = 0) {
        // if (digits === 0) {
        //     digits = 1;
        // }
        // val = Number((val + '').replace(',', '.').replace(/[^0-9.]/g, ''));
        val = Number((val + '').replace(/[^\d]/g, ''));
        console.log(val, val.toFixed(digits));
        return val;
    }
}
