import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IonRouterOutlet, ModalController } from '@ionic/angular';
import { ProductoDetallePage } from 'src/app/pages/producto-detalle/producto-detalle.page';
import { DataService } from 'src/app/services/data.service';
import { ProductoRecomendado, RecomendationService } from 'src/app/services/recomendation-service.service';

@Component({
  selector: 'app-recomendaciones-home',
  templateUrl: './recomendaciones-home.component.html',
  styleUrls: ['./recomendaciones-home.component.scss'],
})
export class RecomendacionesHomeComponent implements OnInit {
  @Output() getProducts: EventEmitter<any> = new EventEmitter();
  @Input() store: number;
  @Input() tienda:any; 

  @Input() disabled: boolean;
  products: ProductoRecomendado[] = [];
  slideOpts = { 
    slidesPerView: 1.3,
    initialSlide: 0,
    speed: 200
  };
  decimales = '1.0-0';
  locale = 'es-CL';
  simboloMoneda = '$';
  constructor(
    private routerOutlet: IonRouterOutlet,
    private dataService: DataService,
    private recomendationService: RecomendationService,
    private modalController: ModalController
    ) {
  }

  async ngOnInit() {
  console.log('recomendados', this.products);
  if (this.store) {
  this.recomendationService.hotsRecomendation(this.store).then((response) => {
      let prods = [];
      for (let index = 0; index < response.length; index++) {
         if (response[index].itemActive===true){
          prods.push(response[index])
         }
        
      }
      this.products = prods;
      console.log('recomendados response', this.products);
      // return;
      this.getProducts.emit(this.products);
  });
  }
  }
onImgError(event) { 
    event.target.src = this.tienda.fotoProductoNameS3 ? this.tienda.fotoProductoNameS3 : this.tienda.logoNameS3;
  }
  async verProducto(item) {
    let producto = await this.dataService.getProducto(item.itemId);
    console.log(producto)
    const modal = await this.modalController.create({
      component: ProductoDetallePage,
      mode: 'ios',
      cssClass: 'my-custom-class',
      swipeToClose: true,
      presentingElement: this.routerOutlet.nativeEl, 
      componentProps: {
        producto: producto
      }
    });
    return await modal.present();
}

}