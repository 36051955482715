import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {UtilService} from "../../services/util.service";

@Component({
    selector: 'app-tour-producto',
    templateUrl: './tour-producto.component.html',
    styleUrls: ['./tour-producto.component.scss'],
})
export class TourProductoComponent implements OnInit {

    @Output() onclick = new EventEmitter<any>();

    text1: string;
    text2: string;
    showing = false;
    tour = 'tour_producto'
    top: string;
    left: string

    constructor(private translate: TranslateService, private util: UtilService) {}

    async ngOnInit() {
        await this.util.checkTour(this.tour, this.callback.bind(this))
    }

    action() {
        this.showing = false;
        this.onclick.emit(true);
    }

    callback() {
        this.showing = true;
        this.translate.get('tour_producto_1').subscribe((translated: string) => {
            this.text1 = translated;
        });
        this.translate.get('tour_producto_2').subscribe((translated: string) => {
            this.text2 = translated
        });

        setTimeout(() => {
            let quants =document.getElementById('qrmenu-quantity-products');
            let posc = this.util.getOffset( quants );
            const isDesktop = this.util.hasSomeParentTheClass(quants, 'desktop-app')
            if(!isDesktop){
                this.top = (posc.top-204) + "px";
                this.left = (posc.left-100) + "px";
            }
        }, 2500);


    }





}
