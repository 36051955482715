import {Injectable} from '@angular/core';
import {TranslateLoader} from '@ngx-translate/core';
import {Observable} from 'rxjs';

import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {DataService} from './data.service';

const URL = environment.apiUrl;

@Injectable({
    providedIn: 'root'
})
export class TranslateLoaderService implements TranslateLoader {
    contentHeader = new HttpHeaders({'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'});
    slugQR;

    constructor(private http: HttpClient,
                private dataService: DataService) {
        // this.loadData();
    }

    // async loadData() {
    //     // console.log('dataserv', this.dataService, this.storage, );
    //     this.slugQR = localStorage ? localStorage.getItem('slugQR') : null;
    //     // if (this.dataService) {
    //     //     this.slugQR = await this.dataService.getStorageData('slugQR') || '';
    //     // }
    // }

    getTranslation(lang: string): Observable<any> {
        let language = 'espanol';
        switch (lang) {
            case 'en':
                language = 'english';
                break;
            case 'pt':
                language = 'portugues';
                break;
        }
        console.log('angular translate loader');
        return Observable.create(async (observer) => {
            // this.slugQR = await this.dataService.getStorageData('slugQR') || '';
            this.slugQR = ((localStorage ? localStorage.getItem('_cap_slugQR') : '') || '').replace('"', '');
            const apiAddress = URL + '/diccionario/' + language + '.json?slug=' + this.slugQR;
            console.log('fetching ' + language);
            this.http.get(apiAddress, {headers: this.contentHeader}).subscribe((res: Response) => {
                    console.log(apiAddress, res);
                    observer.next(res);
                    observer.complete();
                },
                error => {
                    //  failed to retrieve from api, switch to local
                    this.http.get('/assets/i18n/' + lang + '.json').subscribe((res: Response) => {
                        console.log(res);
                        observer.next(res);
                        observer.complete();
                    });
                }
            );
        });

        // const apiAddress = '/assets/i18n/' + lang + '.json';
        // return Observable.create( async (observer) => {
        //     //  failed to retrieve from api, switch to local
        //     await this.http.get(apiAddress).subscribe((res: Response) => {
        //         console.log(apiAddress, res);
        //         observer.next(res);
        //         observer.complete();
        //     });
        //
        //     const apiAddressUrl = URL + '/diccionario/' + language + '.json';
        //     await this.http.get(apiAddressUrl, {headers: this.contentHeader}).subscribe((res: Response) => {
        //             console.log(apiAddressUrl, res);
        //             observer.next(res);
        //             observer.complete();
        //         },
        //         error => {
        //             // apiAddress = '/assets/i18n/' + lang + '.json';
        //             // //  failed to retrieve from api, switch to local
        //             // this.http.get(apiAddress).subscribe((res: Response) => {
        //             //     console.log(apiAddress, res);
        //             //     observer.next(res);
        //             //     observer.complete();
        //             // });
        //         }
        //     );
        // });
    }
}
