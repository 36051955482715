import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { Reserva, Tienda } from 'src/app/interfaces/interfaces';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { ReservaService } from 'src/app/services/reserva.service';

@Component({
  selector: 'app-lista-reservas-cliente',
  templateUrl: './lista-reservas-cliente.component.html',
  styleUrls: ['./lista-reservas-cliente.component.scss'],
})
export class ListaReservasClienteComponent implements OnInit {
  @Input() reserva: Reserva;
  @Input() tienda: Tienda;
  reservas: any;
  telefono: string;
  constructor(private activatedRoute: ActivatedRoute,
    private modal: ModalController,
    private dataService: DataService,
    private reservaService: ReservaService,
    private alertController: AlertController,
    private apiService: ApiService,
    private router: Router,) { }

  async ngOnInit() {
    let tel = await this.dataService.getStorageData('telReserva');
    console.log('tel',tel)
    if (!tel){
      this.telefono = await this.requestTelefono();
    }else{
      this.telefono = tel;
    }
    
    let fecha = moment().format('DD-MM-YYYY HH:mm');

    this.reservas = await this.reservaService.getReservas(this.telefono, this.tienda.id, fecha);
    console.log(this.reservas);
    this.reservas = this.reservas.reservas;
    for (let reserva of this.reservas) {
      reserva.fecha = moment(reserva.fecha.date).toDate();  
    }
    
  }

  cerrar(){
    console.log('cerrar');
    
    this.modal.dismiss();
  }
  async confirmar(id){
    await this.reservaService.editarEstado(id,'confirmado');
    const alert = await this.alertController.create({
       
      header: 'Aviso',
      message: 'Reserva confirmada', 
      buttons: ['OK']
    });

    await alert.present();
    this.modal.dismiss();
  }
  async cancelar(id){
    const alert2 = await this.alertController.create({
     
      header: 'Confirmar!',
      message: '¿Esta seguro de cancelar la reserva?',
      buttons: [
        {
          text: 'Cerrar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Si, cancelar',
          handler: async () => {
            await this.reservaService.editarEstado(id,'cancelado');
            const alert = await this.alertController.create({
              
              header: 'Aviso',
              message: 'Reserva cancelada', 
              buttons: ['OK']
            });

            await alert.present();
            this.modal.dismiss();
          }
        }
      ]
    });

    await alert2.present();
   
  }
  async requestTelefono(){
    return new Promise<string>(async (resolve) => {
      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        header: 'Telefóno',
        subHeader: 'Ingresa tu numero de telefóno',
        backdropDismiss: false,
        inputs: [
          {
            name: 'telefono',
            type: 'text',
            placeholder: '9 9999 9999',
            id: 'code'
          }
        ],
        buttons: [
          {
            text: 'Ok',
            handler: (data) => {
              return resolve( '56'+data.telefono.trim() );
            },
            cssClass: 'confirm'
          },
          {
            text: 'Cancelar',
            handler: (data) => {
              this.modal.dismiss();
            },
            cssClass: 'confirm'
          }
        ]
      });
  
      await alert.present();

      const code$ = new Subject<string>();

      const confirmBtn = document.querySelector('.confirm') as HTMLButtonElement;
      confirmBtn.disabled = true;

      const codeInput = document.getElementById('code') as HTMLInputElement;

      codeInput.addEventListener('keyup', () => code$.next(codeInput.value));

      code$.asObservable().subscribe(code => {
        confirmBtn.disabled = code.length != 9;
      });
    });
  }

}
