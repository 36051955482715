import { Reserva, Tienda } from './../interfaces/interfaces';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { resolve } from '@angular/compiler-cli/src/ngtsc/file_system';

// const URL = "http://localhost:8000/api";
const URL = environment.apiUrl;
@Injectable({
  providedIn: 'root'
})
export class ReservaService {

  constructor(
    private http: HttpClient,
  ) { }

  async reserva(reserva: Reserva) {
    return new Promise(resolve => {
      this.http.post(`${URL}/reserva/new`, reserva)
        .subscribe(
          async resp => {
            const respObject: any = resp;
            console.log(respObject);
            resolve(respObject);
          },
          async (err) => {
            resolve(err);
          }
        );
    })
  }

  async deleteReserva(id) {
    return new Promise(resolve => {
      this.http.delete(`${URL}/reserva/${id}`)
        .subscribe(
          async resp => {
            const respObject: any = resp;
            console.log(respObject);
            resolve(respObject);
          },
          async (err) => {
            resolve(err);
          }
        );
    })
  }

  async getLugar(tienda: number, nombre): Promise<number> {
    return new Promise(resolve => {
      this.http.get(`${URL}/lugar/tienda/${tienda}?nombre=${nombre}`)
        .subscribe(
          async resp => {
            const respObject: any = resp;
            resolve(respObject.lugares[0].id);
          },
          async (err) => {
            resolve(err);
          }
        );
    });
  }

  async getTipoReserva(tienda:number, nombre): Promise<number>{
    return new Promise(resolve => {
      this.http.get(`${URL}/tipo-reserva/tienda/${tienda}?nombre=${nombre}`)
        .subscribe(
          async resp => {
            const respObject: any = resp;
            console.log(respObject);
            resolve(respObject.tipoReservas[0].id);
          },
          async (err) => {
            resolve(err);
          }
        );
    });
  }
  async getTipoReservaDetalle(tienda:number, nombre): Promise<any>{
    return new Promise(resolve => {
      this.http.get(`${URL}/tipo-reserva/tienda/${tienda}?nombre=${nombre}`)
        .subscribe(
          async resp => {
            const respObject: any = resp;
            console.log(respObject);
            resolve(respObject.tipoReservas[0]);
          },
          async (err) => {
            resolve(err);
          }
        );
    });
  }

  async getLugares(tienda: number): Promise<[]> {
    return new Promise(resolve => {
      this.http.get(`${URL}/lugar/tienda/${tienda}`)
        .subscribe(
          async resp => {
            const respObject: any = resp;
            resolve(respObject.lugares);
          },
          async (err) => {
            resolve(err);
          }
        );
    });
  }

  async getTipoReservas(tienda:number): Promise<[]>{
    return new Promise(resolve => {
      this.http.get(`${URL}/tipo-reserva/tienda/${tienda}`)
        .subscribe(
          async resp => {
            const respObject: any = resp;
            resolve(respObject.tipoReservas);
          },
          async (err) => {
            resolve(err);
          }
        );
    });
  }

  async getMesas(tienda:number): Promise<[]>{
    return new Promise(resolve => {
      this.http.get(`${URL}/mesa/tienda/${tienda}`)
        .subscribe(
          async resp => {
            const respObject: any = resp;
            resolve(respObject.mesas);
          },
          async (err) => {
            resolve(err);
          }
        );
    });
  }

  async getHorasDisponibles(tienda:number, inicio, termino): Promise<any> {
    return new Promise(resolve => {
      this.http.get(`${URL}/reservas/disponibles/tienda/${tienda}/inicio/${inicio}/termino/${termino}`)
        .subscribe(
          async resp => {
            const respObject: any = resp;
            console.log(respObject);
            resolve(respObject);
          },
          async (err) => {
            resolve(err);
          }
        );
    });
  }

  async getHorasTipoDisponibles(tienda:number, dia, type): Promise<any> {
    return new Promise(resolve => {
      this.http.get(`${URL}/reservas/${type}/tienda/${tienda}/dia/${dia}`)
        .subscribe(
          async resp => {
            const respObject: any = resp;
            console.log(respObject);
            resolve(respObject);
          },
          async (err) => {
            resolve(err);
          }
        );
    });
  }

  async getReservas(telefono:string, tienda:number, inicio:string): Promise<any> {
    return new Promise(resolve => {
      this.http.get(`${URL}/reservas/telefono/${telefono}/tienda/${tienda}/inicio/${inicio}`)
        .subscribe(
          async resp => {
            const respObject: any = resp;
            console.log(respObject);
            resolve(respObject);
          },
          async (err) => {
            resolve(err);
          }
        );
    });
  }
  async editarEstado(id, estado) {
    return new Promise(resolve => {
      this.http.post(`${URL}/reserva/edit/${id}`, {estado:estado})
        .subscribe(
          async resp => {
            const respObject: any = resp;
            console.log(respObject);
            resolve(respObject);
          },
          async (err) => {
            resolve(err);
          }
        );
    })
  }


}
