import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonRouterOutlet, IonSlides, ModalController } from '@ionic/angular';
import { ProductoDetallePage } from 'src/app/pages/producto-detalle/producto-detalle.page';
import { DataService } from 'src/app/services/data.service';
import { ProductoRecomendado, RecomendationService } from 'src/app/services/recomendation-service.service';


@Component({
  selector: 'app-recomendados-mall',
  templateUrl: './recomendados-mall.component.html',
  styleUrls: ['./recomendados-mall.component.scss'],
})
export class RecomendadosMallComponent implements OnInit {
  @Output() getProducts: EventEmitter<any> = new EventEmitter();
  @Input() tiendas:any;
  @Input() tienda:any;
  @Input() disabled: boolean;
  products: any= [];
  slideOpts = {
    autoHeight: true,
    slidesPerView: 1.2,
    initialSlide: 0,
    speed: 400
  };
  decimales = '1.0-0';
  locale = 'es-CL';
  simboloMoneda = '$';
  inload: boolean =true;
  @ViewChild('slider', { static: true }) slider: IonSlides;
  constructor(
    private route: Router,
    private routerOutlet: IonRouterOutlet,
    private dataService: DataService,
    private recomendationService: RecomendationService,
    private modalController: ModalController
    ) {
  }

  async ngOnInit() {
  console.log('recomendados', this.products);
 
  let prod:any = []
  if (this.tiendas) {

    let hotprod = await this.recomendationService.hotSales(this.tienda.slug);
    for (let index = 0; index < hotprod.length; index++) {
     
      prod.push(hotprod[index])
      
    }
    /*for (let index = 0; index < this.tiendas.length; index++) {
      let store = this.tiendas[index].id;

     let recom = await this.recomendationService.guestUserRecomendation(store);
     
     for (let index2 = 0; index2 < recom.length; index2++) {
      recom[index2].logoName =  this.tiendas[index].logoName
      this.products.push(recom[index2])
       
     }
    
    }*/
    this.products = this.shuffle(prod)
    this.inload = false;
    setTimeout(() => {
      this.slider.update();
    }, 300);
 
  }
  }
  shuffle(sourceArray) {
    for (var i = 0; i < sourceArray.length - 1; i++) {
        var j = i + Math.floor(Math.random() * (sourceArray.length - i));

        var temp = sourceArray[j];
        sourceArray[j] = sourceArray[i];
        sourceArray[i] = temp;
    }
    return sourceArray;
}
onImgError(event,item) { 
  event.target.src = item.fotoProductoNameS3 ? item.fotoProductoNameS3 : item.logoName;
}
  async verProducto(item) {
 
    console.log(item)
    this.route.navigateByUrl('/'+item.itemTiendaSlug+'/home?prod='+item.itemId)
}

}
