import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Tienda} from "../../interfaces/interfaces";
import {TranslateService} from "@ngx-translate/core";
import * as moment from 'moment';
import {UtilService} from "../../services/util.service";

@Component({
    selector: 'app-tour-tienda',
    templateUrl: './tour-tienda.component.html',
    styleUrls: ['./tour-tienda.component.scss'],
})
export class TourTiendaComponent implements OnInit {

    @Output() onclick = new EventEmitter<any>();
    @Input() tienda: Tienda;
    text: string;
    showing= false;
    tour = 'tour_tienda'

    constructor(private translate: TranslateService, private util: UtilService) {}

    async ngOnInit() {
        await this.util.checkTour(this.tour,this.callback.bind(this))
    }


    callback(){
        this.showing = true;
        this.translate.get(this.tour, {store: this.tienda.nombre}).subscribe((translated: string) => {
            this.text = translated;
        });
    }

    action() {
        this.showing = false;
        this.onclick.emit(false);
    }

}
