import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonSlides } from '@ionic/angular';

@Component({
  selector: 'app-banner-mall',
  templateUrl: './banner-mall.component.html',
  styleUrls: ['./banner-mall.component.scss'],
})
export class BannerMallComponent implements OnInit {
  @Input() banners;
  slideOpts = {
    slidesPerView: 1,
    initialSlide: 0,
    speed: 400,
    autoplay: {
      delay: 2000,
    },
  };
  @ViewChild('slider', { static: true }) slider: IonSlides;
  constructor(private route : Router) {

    console.log('banner', this.banners)
   }

  ngOnInit() {}
  ionViewDidEnter() {
    this.slider.update() 
  }

  async verProducto(item) {
 
    console.log(item)
    if (item.productoId!=null &&   item.productoId!=undefined  &&   item.productoId!='' )
     this.route.navigateByUrl('/'+item.itemTiendaSlug+'/home?prod='+item.productoId)
}
}
