import { DetalleReservaComponent } from './detalle-reserva/detalle-reserva.component';
import { FormularioReservaComponent } from './formulario-reserva/formulario-reserva.component';
import { TablaReservasComponent } from './tabla-reservas/tabla-reservas.component';
import { ListaReservasClienteComponent } from './lista-reservas-cliente/lista-reservas-cliente.component';
import { ChatNavidadComponent } from './chat-navidad/chat-navidad.component';
import { BuscarItemComponent } from './buscar-item/buscar-item.component';
import {LOCALE_ID, NgModule} from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import {LangPickerComponent} from './lang-picker/lang-picker.component';
import {IonicModule} from '@ionic/angular';
import {RouterModule} from '@angular/router';
import {PipesModule} from '../pipes/pipes.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateLoaderService} from '../services/translate-loader.service';
import {HttpClient} from '@angular/common/http';
import {RatingComponent} from './rating/rating.component';
import {SocialLoginComponent} from './social-login/social-login.component';
import {GoogleMapsComponent} from './google-maps/google-maps.component';
import {RecomendacionesTiendaComponent} from "./recomendaciones-tienda/recomendaciones-tienda.component";
import {ProductoRecomendadoComponent} from "./producto-recomendado/producto-recomendado.component";
import {RecomendacionesProductoComponent} from "./recomendaciones-producto/recomendaciones-producto.component";
import {RecomendacionesPedidoComponent} from "./recomendaciones-pedido/recomendaciones-pedido.component";
import {TiendaModalComponent} from "./tienda-modal/tienda-modal.component";
import {RecomendacionesComponent} from "./recomendaciones/recomendaciones.component";
import {TooltipMsgComponent} from "./tooltip-msg/tooltip-msg.component";
import {RecomendacionesHotComponent} from "./recomendaciones-hot/recomendaciones-hot.component";
import {PublicTabsComponent} from "./public-tabs/public-tabs.component";
import {ModalAgregadosComponent} from "./modal-agregados/modal-agregados.component";
import {TourTiendaComponent} from "./tour-tienda/tour-tienda.component";
import {TourProductoComponent} from "./tour-producto/tour-producto.component";
import {TourPedidoComponent} from "./tour-pedido/tour-pedido.component";
import {LoadingComponent} from "./loading/loading.component";
import {QrmodalComponent} from "./qrmodal/qrmodal.component";
import {BannersTiendaComponent} from "../banners-tienda/banners-tienda.component";
import {MenuHelperComponent} from "./menu-helper/menu-helper.component";
import {AccordionComponent} from "./accordion/accordion.component";
import {WelcomeComponent} from "./welcome/welcome.component";
import {FiltroCartaComponent} from "./filtro-carta/filtro-carta.component";
import { RecomendacionesHomeComponent } from './recomendaciones-home/recomendaciones-home.component';
import { MeGustaComponent } from './me-gusta/me-gusta.component';
import { MeGustaProductoComponent } from './me-gusta-producto/me-gusta-producto.component';
import { ProductosHomeComponent } from './productos-home/productos-home.component';


import { CardPedidoComponent } from './card-pedido/card-pedido.component';
import { CardNotificationComponent } from './card-notification/card-notification.component';
import { GamificacionPuzzleComponent } from './gamificacion-puzzle/gamificacion-puzzle.component';
import { DetalleComidaComponent } from './detalle-comida/detalle-comida.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CarritoComponent } from './carrito/carrito.component';
import { GamificacionRuletaComponent } from './gamificacion-ruleta/gamificacion-ruleta.component';
import { GamificacionAlertaComponent } from './gamificacion-alerta/gamificacion-alerta.component';

import { NgPipesModule } from 'ngx-pipes';
import { DatePickerModule } from 'ionic4-date-picker';

import localeEsCl from '@angular/common/locales/es-CL';
import {RecomendacionesPedidoNewComponent} from "./recomendaciones-pedido-new/recomendaciones-pedido-new.component";
import { ParaTiComponent } from './para-ti/para-ti.component';
import { TiendaCardComponent } from './tienda-card/tienda-card.component';
import { RecomendadosMallComponent } from './recomendados-mall/recomendados-mall.component';
import { BannerMallComponent } from './banner-mall/banner-mall.component';
import { ParaTiM1Component } from './para-ti-m1/para-ti-m1.component';
import { ParaTiM2Component } from './para-ti-m2/para-ti-m2.component';
import { ParaTiM3Component } from './para-ti-m3/para-ti-m3.component';

registerLocaleData(localeEsCl, 'es-Cl');
@NgModule({
    declarations: [
        ParaTiComponent,
        MeGustaComponent,
        RecomendacionesHomeComponent,
        LangPickerComponent,
        RatingComponent,
        SocialLoginComponent,
        RecomendacionesTiendaComponent,
        ProductoRecomendadoComponent,
        RecomendacionesProductoComponent,
        RecomendacionesPedidoComponent,
        TiendaModalComponent,
        RecomendacionesComponent,
        TooltipMsgComponent,
        RecomendacionesHotComponent,
        PublicTabsComponent,
        ModalAgregadosComponent,
        TourTiendaComponent,
        TourProductoComponent,
        TourPedidoComponent,
        LoadingComponent,
        QrmodalComponent,
        BannersTiendaComponent,
        SocialLoginComponent,
        GoogleMapsComponent,
        MenuHelperComponent,
        AccordionComponent,
        WelcomeComponent,
        FiltroCartaComponent,
        MeGustaProductoComponent,
        ProductosHomeComponent,
        CardPedidoComponent,
        CardNotificationComponent,
        GamificacionPuzzleComponent,
        DetalleComidaComponent,
        CarritoComponent,
        GamificacionRuletaComponent,
        GamificacionAlertaComponent,
        BuscarItemComponent,
        ChatNavidadComponent,
        ListaReservasClienteComponent,
        TablaReservasComponent,
        RecomendacionesPedidoNewComponent,
        TiendaCardComponent,
        RecomendadosMallComponent,
        BannerMallComponent,
        ParaTiM1Component,
        ParaTiM2Component,
        ParaTiM3Component,
        FormularioReservaComponent,
        DetalleReservaComponent
    ],
    exports: [
        ParaTiComponent,
        MeGustaComponent,
        RecomendacionesHomeComponent,
        LangPickerComponent,
        RatingComponent,
        SocialLoginComponent,
        GoogleMapsComponent,
        SocialLoginComponent,
        RecomendacionesTiendaComponent,
        ProductoRecomendadoComponent,
        RecomendacionesProductoComponent,
        RecomendacionesPedidoComponent,
        TiendaModalComponent,
        TooltipMsgComponent,
        RecomendacionesHotComponent,
        PublicTabsComponent,
        ModalAgregadosComponent,
        TourTiendaComponent,
        TourProductoComponent,
        TourPedidoComponent,
        LoadingComponent,
        QrmodalComponent,
        BannersTiendaComponent,
        MenuHelperComponent,
        WelcomeComponent,
        FiltroCartaComponent,
        MeGustaProductoComponent,
        ProductosHomeComponent,
        CardPedidoComponent,
        CardNotificationComponent,
        GamificacionPuzzleComponent,
        DetalleComidaComponent,
        CarritoComponent,
        GamificacionRuletaComponent,
        BuscarItemComponent,
        ChatNavidadComponent,
        ListaReservasClienteComponent,
        TablaReservasComponent,
        RecomendacionesPedidoNewComponent,
        TiendaCardComponent,
        RecomendadosMallComponent,
        BannerMallComponent,
        ParaTiM1Component,
        ParaTiM2Component,
        ParaTiM3Component,
        FormularioReservaComponent,
        DetalleReservaComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        RouterModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        DatePickerModule,
        NgPipesModule,
        NgxDatatableModule
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'es-Cl' },
    ]
})
export class ComponentsModule {}
