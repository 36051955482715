import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {IonSlides} from "@ionic/angular";
import {ProductoRecomendado} from "../../services/recomendation-service.service";
import {DataService} from "../../services/data.service";
import {UtilService} from "../../services/util.service";

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit {

  @ViewChild('slides') slides: IonSlides;
  @Input() height = "0%";
  bg: string
  logo: string
  welcome = false;
  items: any[];
  current = 1;

  slideChanged() {
    this.slides.getActiveIndex().then(index => {
      this.current = (index + 1);
    });

  }

  slideOpts = {
    autoplay: false,
  };

  constructor(private utils: UtilService,private dataService: DataService) {
    this.items = [
      {id: 1, titulo: 'Escanea', mensaje: 'Con tu celular podrás',mensaje2:'ver nuestro menú', imagen: '/assets/nl/welcome/slide1.png'},
      {id: 2, titulo: 'Pide', mensaje: 'Cuantas veces quieras',mensaje2:'', imagen: '/assets/nl/welcome/slide2.png'},
      {id: 3, titulo: 'Paga', mensaje: 'Podrás pagar ',mensaje2:'cuando quieras, sin esperas', imagen: '/assets/nl/welcome/slide3.png'}
    ]
  }

  async ngOnInit() {

    /*
    if (window.location.href.indexOf('araucofoodie.cl') == -1) {
      this.bg = 'url(/assets/nl/welcome/bg-qrmenu.jpg)';
      this.logo = '/assets/nl/welcome/logo-qrmenu.png';
      this.utils.showWelcome(this.showWelcome.bind(this));
    } else {
       this.bg = 'url(/assets/nl/welcome/bg-arauco.jpg)';
       this.logo = '/assets/nl/welcome/logo-arauco.png';
    }

setTimeout(async () => {
      const tema = await this.dataService.getStorageData('tema');

      if(tema == 'parque-arauco'){
        this.bg = 'url(/assets/nl/welcome/bg-arauco.jpg)';
        this.logo = '/assets/nl/welcome/logo-arauco.png';
      }
    },500)*/

  }

  showWelcome() {
    this.welcome = true;
  }

  continuar() {
    this.welcome = false;
  }

}
