import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-me-gusta',
  templateUrl: './me-gusta.component.html',
  styleUrls: ['./me-gusta.component.scss'],
})
export class MeGustaComponent implements OnInit {
  @Input() megusta: boolean;
  constructor() { 
    if (this.megusta==undefined || this.megusta == null){
      this.megusta = false;
    }
  }

  ngOnInit() {}

  like(){
    this.megusta = !this.megusta;
  }
}
