import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {Banner, Tienda} from '../interfaces/interfaces';
import {DataService} from '../services/data.service';
import {EventsService} from '../services/events.service';

@Component({
    selector: 'app-banners-tienda',
    templateUrl: './banners-tienda.component.html',
    styleUrls: ['./banners-tienda.component.scss'],
})
export class BannersTiendaComponent implements OnInit {

    @Input() tienda: Tienda;
    banners: Banner[];
    slideOpts = {
        initialSlide: 0,
        autoHeight: true,
        slidesPerView: 1,
        autoplay: false,
        pager: true,
        zoom: false
    };

    constructor(private dataService: DataService,
                private eventsService: EventsService) {

    }

    async ngOnInit() {
        await this.loadInfo();
    }

    async ngOnChanges(changes: SimpleChanges) {
        await this.loadInfo();
    }

    async loadInfo() {
        // console.log(this.tienda);
        if (!this.tienda) {
            return;
        } else {
            this.banners = await this.tienda.bannersTienda.filter(
                banner => banner.seccion === 'Carrusel' && this.dataService.isAbierto(banner, 'banner'));
            // console.log(this.tienda.bannersTienda, this.banners);

            this.eventsService.publish('banners:list', this.banners);

        }
    }

    bannerLink(banner: Banner) {
        this.dataService.bannerLink(banner);
    }

}
