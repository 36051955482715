import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ProductoRecomendado} from '../../services/recomendation-service.service';
import {IonSlides} from '@ionic/angular';
import {Subscription} from 'rxjs';
import {EventsService} from '../../services/events.service';

@Component({
    selector: 'app-recomendaciones',
    templateUrl: './recomendaciones.component.html',
    styleUrls: ['./recomendaciones.component.scss'],
})
export class RecomendacionesComponent implements OnInit {

    subscriptions: (Subscription | any)[] = [];
    @ViewChild('recomendaciones') recomendaciones;
    @ViewChild('slides') slides: IonSlides;
    @Input() products: ProductoRecomendado[] = [];
    @Input() limit = 100;
    @Input() disabled: boolean;
    showButton = true;

    slideOpts = {
        initialSlide: 0,
        autoplay: false,
        slidesPerView: 3,
        pagination: false
    };

    constructor(private eventsService: EventsService) {
    }

    ngOnInit() {
        if (typeof this.products === 'undefined') {
            this.products = [];
        }

        this.subscriptions.push(this.eventsService.subscribe('tienda:loaded', async () => {
            if (this.slides) {
                await this.slides.update();
            }
            // console.log('recomendados products', this.products);
            this.showButton = this.products.length > 3;
        }));
    }

    async prev() {
        const slide = await this.slides.getActiveIndex();
        if (slide === 0) {
            await this.slides.slideTo(this.products.length - 1);
        } else {
            await this.slides.slidePrev();
            await this.slides.slidePrev();
            await this.slides.slidePrev();

        }
    }

    async next() {
        const slide = await this.slides.getActiveIndex();
        if (slide === (this.products.length - 3)) {
            await this.slides.slideTo(0);
        } else {
            await this.slides.slideNext();
            await this.slides.slideNext();
            await this.slides.slideNext();
        }
    }

    showAll() {
        if (!this.disabled) {
            this.limit = this.products.length;
        }
    }

    slideChanged() {
        this.slides.getActiveIndex().then(index => {
            if (index > this.products.length / 2 || this.products.length < 4) {
                // this.showButton = false;
            }
        });
    }
}
