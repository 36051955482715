import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import chroma from "chroma-js";
import { UiService } from 'src/app/services/ui.service';
import { formatNumber } from '@angular/common';

@Component({
  selector: 'app-informe-cliente',
  templateUrl: './informe-cliente.page.html',
  styleUrls: ['./informe-cliente.page.scss'],
})
export class InformeClientePage implements OnInit {
  tienda: any;
  danger: boolean = false;
  mis_productos_favoritos_data:any = [];
  ultimas_compras:any = [];
  @Input() usuario: any;
  decimales = '1.0-0';
  locale = 'es-CL';
  simboloMoneda = '$';
  constructor(private apiService: ApiService, private modalCtrl: ModalController, private dataService: DataService, private uiService: UiService) { }

  async ngOnInit() {
    this.decimales = await this.dataService.getStorageData('decimales');
    this.locale = await this.dataService.getStorageData('locale');
    this.simboloMoneda = await this.dataService.getStorageData('simboloMoneda');
    this.getCliente();
    console.log(this.usuario)
  }
  async dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    await this.modalCtrl.dismiss({
      dismissed: true
    });
  }
  ionViewDidEnter() {


    this.renderChart()
  }

  timeSince(date) {

    var seconds = Math.floor((new Date().getTime() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      this.danger = true
      return Math.floor(interval) + " años";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      this.danger = true
      return Math.floor(interval) + " meses";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      if (interval > 7) {
        this.danger = true
      } else {
        this.danger = false
      }
      return Math.floor(interval) + " días";
    }
    interval = seconds / 3600;
    this.danger = false
    if (interval > 1) {
      return Math.floor(interval) + " horas";
    }
    interval = seconds / 60;
    this.danger = false
    if (interval > 1) {
      return Math.floor(interval) + " minutos";
    }
    else{
      seconds = 0; 
    }
    return Math.floor(seconds) + " segundos";
  }

  async getCliente() {

   
    this.tienda = await this.dataService.getStorageData('tienda');
    await this.apiService.get('/jwt/tienda/cliente/'+this.usuario.usuarioId+'/' + this.tienda.id);//
    let resena = await this.dataService.getStorageData('mis_resena');
 


    if (resena == null) {
      resena = []
    }
    if (resena.length > 0)
      if (resena[0].resena == null) {
        resena[0].resena = 0;
      }

    resena[0].resena = resena[0].resena * 1;

    if (resena[0].resena > 0)
      for (let index = 0; index < Math.round(resena[0].resena); index++) {
        document.getElementById("cliente-Star" + (index + 1)).classList.add('Rating--Star__active');

      }



    let mis_resena_tienda = await this.dataService.getStorageData('mis_resena_tienda');
    if (mis_resena_tienda == null) {
      mis_resena_tienda = []
    }
    if (mis_resena_tienda.length > 0)
      if (mis_resena_tienda[0].resena == null) {
        mis_resena_tienda[0].resena = 0;
      }

    mis_resena_tienda[0].resena = mis_resena_tienda[0].resena * 1;

    if (mis_resena_tienda[0].resena > 0)
      for (let index = 0; index < Math.round(mis_resena_tienda[0].resena); index++) {
        document.getElementById("cliente-StarTienda" + (index + 1)).classList.add('Rating--Star__active');

      }

    let ultimas_compras = await this.dataService.getStorageData('ultimas_compras');
    this.ultimas_compras = ultimas_compras;

    if (ultimas_compras.length > 0) {

      console.log(new Date(ultimas_compras[0].fecha));
      document.getElementById('clienteUltimaVisita').innerHTML = ('Última visita: ' + ultimas_compras[0].dia);
      document.getElementById('clienteDesdeHace').innerHTML = ('Hace más ' + this.timeSince(new Date(ultimas_compras[0].fecha)))
    }


 
  
    let mis_cliente_analisis = await this.dataService.getStorageData('mis_cliente_analisis');


    if (mis_cliente_analisis['promedioCompras'].length > 0) {
      if (mis_cliente_analisis['promedioCompras'][0].total == null) {
        mis_cliente_analisis['promedioCompras'][0].total = 0;
      }
      document.getElementById('clienteAnalisis2').innerHTML = ('Compra en promedio:  $ ' + formatNumber((mis_cliente_analisis['promedioCompras'][0].total * 1),this.locale,this.decimales)  + '.-');
    }
    let dia = '';
    if (mis_cliente_analisis['diasQueMasCompra'].length > 0) {
       dia = 'Suele comprar dias  ' + mis_cliente_analisis['diasQueMasCompra'][0].dias;
      if (+mis_cliente_analisis['diasQueMasCompra'].length > 1) {
        dia = dia + ' y ' + mis_cliente_analisis['diasQueMasCompra'][1].dias;
      }
  
    }
    let hora ='';
    if (mis_cliente_analisis['horasQueMasCompra'].length > 0) {


      let horas = [];
       hora = '<br> a las  ' + mis_cliente_analisis['horasQueMasCompra'][0].horas;
      horas.push(mis_cliente_analisis['horasQueMasCompra'][0].horas);

      if (mis_cliente_analisis['horasQueMasCompra'].length > 1) {
        horas.push(mis_cliente_analisis['horasQueMasCompra'][1].horas);
        horas = horas.sort(function (a, b) { return a - b })
        if (mis_cliente_analisis['horasQueMasCompra'][0].horas - mis_cliente_analisis['horasQueMasCompra'][1].horas <= 4) {
          hora = '<p style="text-align: center"> entre las ' + horas[0] + ' y ' + horas[1];
        } else {
          hora = '<p style="text-align: center"> entre las ' + horas[0] + ' y ' + horas[0] + 1;
        }
      } else {

      }

      console.log(horas, horas.sort())

      if (+mis_cliente_analisis['horasQueMasCompra'].length > 1) {

      }
     
    }
    document.getElementById('clienteAnalisis3').innerHTML = (dia+hora+' horas </p>');
    this.mis_productos_favoritos_data = await this.dataService.getStorageData('mis_productos_favoritos_data');
    let mis_compras_mensuales = await this.dataService.getStorageData('mis_compras_mensuales');

    /*
     var colors = ['#FF5100', '#F8C03C']
    var options = {
      series: [],
      chart: {
        type: 'bar',
        height: 200
      },
      legend: {
        position: 'top'
      },
      colors: colors,
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        title: {
          text: 'Ventas'
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {

      },

      stroke: {
        width: 2,
        colors: ['#FF5100', '#F8C03C']
      },
      title: {
        text: 'Compras mensuales',
        align: 'center',
        style: {
          color: '#444'
        }
      },
    };


    var chartClienteComprasMensuales = new ApexCharts(document.querySelector("#chartClienteComprasMensuales"), options);
    chartClienteComprasMensuales.render();

    chartClienteComprasMensuales.updateOptions(mis_compras_mensuales);


    let mis_compras_por_tipo =  await this.dataService.getStorageData('mis_compras_por_tipo');


    var options2 = {
      series: [44, 55, 13],
      chart: {
        type: 'pie',
        height: 200
      },
      legend: {
        position: 'top'
      },
      colors: colors,
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        title: {
          text: 'Ventas'
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {

        }
      },
      title: {
        text: 'Compras mensuales',
        align: 'center',
        style: {
          color: '#444'
        }
      },
    };


    var chartClientePorTipo = new ApexCharts(document.querySelector("#chartClientePorTipo"), options2);
    chartClientePorTipo.render();

    mis_compras_por_tipo.colors = chroma.scale([colors[0], colors[1]]).colors(mis_compras_por_tipo.series.length);
         

    chartClientePorTipo.updateOptions(mis_compras_por_tipo);
    
    */
  }
  renderChart() {
   

     



  }
}