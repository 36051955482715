import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {PopoverController} from "@ionic/angular";

@Component({
  selector: 'app-tooltip-msg',
  templateUrl: './tooltip-msg.component.html',
  styleUrls: ['./tooltip-msg.component.scss'],
})
export class TooltipMsgComponent implements OnInit {

  @Input() tooltip: TooltipMsg;

  constructor() {}

  async ngOnInit() {
    this.tooltip.html = this.tooltip.content + ' <ion-icon class="icon-down" name="caret-down"></ion-icon>'
  }

  ngOnChanges(changes: SimpleChanges) {
    this.tooltip.html = this.tooltip.content + ' <ion-icon class="icon-down" name="caret-down"></ion-icon>'
  }

}

export class TooltipMsg {
  classes: string
  content: string
  html?: string
}
