import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IonContent, IonSlides, ModalController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { TooltipMsg } from 'src/app/components/tooltip-msg/tooltip-msg.component';
import { Alternativa, Categoria, Item, Pedido, Producto, Seleccion, Tienda } from 'src/app/interfaces/interfaces';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { EventsService } from 'src/app/services/events.service';
import { UiService } from 'src/app/services/ui.service';
import { CountUp } from 'countup.js';
import { CarritoCompraPage } from '../carrito-compra/carrito-compra.page';
@Component({
  selector: 'app-producto-detalle',
  templateUrl: './producto-detalle.page.html',
  styleUrls: ['./producto-detalle.page.scss'],
})
export class ProductoDetallePage implements OnInit, OnDestroy {
    @ViewChild(IonContent, { static: false }) content: IonContent;
    @ViewChild('recom', { static: false }) recom: any;
    @ViewChild('total') total:ElementRef;
    @ViewChild('slides', {static: false}) slides: IonSlides;
    subscriptions: (Subscription | any)[] = [];
    categorias: Categoria[] = [];
    textoBuscar = '';
    platform;
    subscription;
    @Input() add;
    @Input() entity;
    @Input() id;
    @Input() isModal;
    tienda: Tienda;
    tiendaCarta: Tienda;
    @Input() producto: Producto;
    @Input() itemCarrito: any = null;
    items: Item[] = [];
    pedidos: Pedido[] = [];
    abierto: boolean;
    usuarioTienda;
    modificandoTienda;
    recomendar:boolean = false;
    tieneRecomendaciones:any = [];
    /* slidesss */
    productos: Producto[];
    productoSlides: Producto[];
    productoIndex = 0;
    slidesAmount = 5;
    fpIndex;
    lpIndex;
    firstLoad = true;
    productoRow;
    totalRows;
    slidesActive = false;
    guardadoTemp:boolean = false;
    slideOpts = {
        initialSlide: 0,
        speed: 400,
        pagination: false
    };
    slidesOk = false;
    disponible = true;


    decimales = '1.0-0';
    locale = 'es-CL';
    simboloMoneda = '$';
    stateModalAgregados = 'close';
    agregoitem = false;
    // @ViewChild('formItem', {static: false}) formItem: NgForm;
    @ViewChild('form', {static: false}) formItem: NgForm;
    showCart = false;
    showProducto = false;
    recomendations: TooltipMsg;
    hideMoreContent = false;

    @ViewChild('imgprod') imgView: ElementRef;
    @ViewChild('qrmenubanner') imgBanner: ElementRef;
    imgTop: string;
    cantidadItem:any = 0;
    itemsTemp: Item[] = [];
    itemsTempActive:any = 0;
    item: Item = {
        id: null,
        productoId: null,
        categoriaId: null,
        categoriaNombre: null,
        imagenName: null,
        tiendaId: null,
        tiendaSlug: null,
        tiendaNombre: null,
        tiendaMultiPago: false,
        nombre: null, 
        precio: 0,
        descuento: 0,
        precioFinal: 0,
        cantidad: 1,
        observaciones: null,
        calificacion: null,
        modificadoTienda: null,
        // supero: false,
        selecciones: {},
        alternativas: {}
    };
    productosIds: string;
    optionsCount = {
        duration: 0,
        separator: '.',
        decimal: ',',
        prefix: ' $ ',
      };
    precioFinal:any = 0;
    inIncrement:boolean = false;
    constructor(
        public modalCtrl: ModalController,
        private activatedRoute: ActivatedRoute,
        private apiService: ApiService,
        private translate: TranslateService,
        private dataService: DataService,
        private uiService: UiService,
        private navCtrl: NavController,
        private router: Router,
        private eventsService: EventsService) {
    }

    async ngOnInit() {
 
        if (this.entity==undefined){
            this.entity = 'producto';//this.activatedRoute.snapshot.paramMap.get('entity');
            this.id = 0;//this.activatedRoute.snapshot.paramMap.get('id');
        }
        await this.dataSubscribe();
        this.startTooltips();
    }

    startTooltips() {

        const translated = this.translate.instant('tooltip_producto');
        if (translated === 'tooltip_producto') {
            setTimeout(() => {
                this.startTooltips();
            }, 1000);

        } else {

            this.translate.get('tooltip_producto').subscribe((translated2: string) => {
                this.recomendations = {
                    classes: 'showing ion-float-right cbl jumps',
                    content: `<span>${translated2}</span>`
                };

            });
        }

    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription: Subscription, index: number) => {
            subscription.unsubscribe();
        });
    }

    async dataSubscribe() {
        this.subscriptions.push(this.eventsService.subscribe('item:eliminar', () => {
            this.eliminarItem();
        }));

        this.subscriptions.push(this.eventsService.subscribe('tienda:data-update', () => {
            this.loadData(false);
        }));
    }

    async ionViewDidEnter() {
        this.item = {
            id: null,
            productoId: null,
            categoriaId: null,
            categoriaNombre: null,
            imagenName: null,
            tiendaId: null,
            tiendaSlug: null,
            tiendaNombre: null,
            tiendaMultiPago: false,
            nombre: null,
            precio: 0,
            descuento: 0,
            precioFinal: 0,
            cantidad: 1,
            observaciones: null,
            calificacion: null,
            modificadoTienda: null,
            // supero: false,
            selecciones: {},
            alternativas: {}
        };

        this.modificandoTienda = false;
        await this.loadData();
        this.showCart = (await this.dataService.getItems()).length > 0;
        this.productosIds = this.producto.id.toString();

        const dataLayer = window['dataLayer'] || [];
        // console.log(dataLayer);
        dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        dataLayer.push({
          event: "view_item",
          ecommerce: {
            items: [
             {
               item_name: this.producto.nombre,       // Name or ID is required.
               item_id:this.producto.id,
               price: this.producto.precioFinal, 
               item_category:  this.producto.categoriaNombre
             } ]
          }
        }); 
        console.log('dataLayer',dataLayer)


    }
    cambioProducto(ev) {
         this.tieneRecomendaciones=ev;
    }
    async loadData(redirect = true) {
        this.tiendaCarta = await this.dataService.getStorageData('tienda');
        this.usuarioTienda = await this.dataService.getStorageData('usuarioTienda');
        this.items = await this.dataService.getItems() || [];
        this.pedidos = await this.dataService.getStorageData('pedidos') || [];
        if (this.entity === 'producto') {
            this.item.productoId = this.id;
            this.item.id = this.items.length;
        } else {
            if (this.usuarioTienda && this.id > 100) {
                this.modificandoTienda = true;
            }
            this.item = (this.modificandoTienda ? await this.dataService.getStorageData('item' + this.id) : this.items[(Number(this.id))])
                || null;

            if (!this.item) {
                const route = this.modificandoTienda ? 'pedido' : 'item';
                await this.navCtrl.navigateBack(route + '-lista', {animated: true});
                return;
            }
        }

        //this.producto = await this.dataService.getProducto(this.item.productoId);
        console.log(this.item, this.producto);
        // return;

        if (redirect && !this.producto && this.item.tiendaSlug) {
            console.log('tienda-reload');
            const loading = await this.uiService.presentLoading('Cargando datos...');
            await this.apiService.get('/jwt/tienda/' + this.item.tiendaSlug);
            await loading.dismiss();
            //this.producto = await this.dataService.getProducto(this.item.productoId);
        }

        if (!this.producto) {
            if (redirect) {
                await this.uiService.presentToast('Producto no existe');
                // this.navCtrl.navigateBack('/restaurantes', {animated: true});
                await this.dataService.goHome(); 
            }
            return;
        }

        if (this.entity === 'producto') {
            // console.log(this.producto);
            this.tienda = await this.dataService.getTienda(this.producto.tiendaId);
            this.item.precio = this.producto.precio;
            this.item.productoId = this.producto.id;
            this.item.descuento = this.producto.descuento;
            this.item.precioFinal = this.producto.precioFinal;
            this.item.nombre = this.producto.nombre;
            this.item.imagenName = this.producto.imagenName;
            this.item.tiendaId = this.tienda.id;
            this.item.tiendaSlug = this.tienda.slug;
            this.item.tiendaNombre = this.tienda.nombre;
            this.item.tiendaMultiPago = this.tienda.multiPago;


            const selecciones = {};
            const alternativas = {};
            this.producto.selecciones.forEach((seleccion: Seleccion, index: number) => {
                selecciones[seleccion.id] = {
                    id: seleccion.id,
                    seleccionNombre: seleccion.nombre,
                    checkeds: 0,
                    value: null
                };
                seleccion.alternativas.forEach((alternativa: Alternativa, aIdx: number) => {
                    alternativas[alternativa.id] = {
                        id: alternativa.id,
                        cantidad: 0,
                        seleccionNombre: seleccion.nombre,
                        nombre: alternativa.nombre,
                        precio: alternativa.precio
                    };
                });
            });
            this.item.selecciones = selecciones;
            this.item.alternativas = alternativas;
        } else {
            this.tienda = await this.dataService.getTienda(this.item.tiendaId);
        }

         

       
        if (this.slidesActive && this.entity === 'producto') {
            // console.log(this.producto);
           // await this.loadDataSlides();
        }

     

        this.disponible = !(!this.tienda || ((!this.isAbierto(this.tienda) || !this.isAbierto(this.producto, 'producto')
            || !this.producto.disponible) && !this.tienda.soloCarta));

        

        let temporales =  await this.dataService.getItemsTemporal();
        this.itemsTemp  = [];//await this.dataService.getItemsTemporal();

        console.log('this.storeitemTemporal agregando',this.itemsTemp,redirect , this.itemCarrito,temporales)
        if (redirect===true){

            if (this.itemCarrito==null || this.itemCarrito==undefined){

                    if (temporales.length==0){
                        this.incrementItem(null, true);
                    } 
                     else{
                         let lastProd = null;
                        if (this.add===true){
                            for (let index = 0; index < temporales.length; index++) {
                            
                                console.log('agregando push',temporales[index])
                                this.itemsTemp.push(temporales[index]);
                                lastProd = temporales[index].productoId;
                                 
                            }
                            this.itemsTempActive = this.itemsTemp.length;
                            if (lastProd!=this.producto.id){
                                this.incrementItem(null, false);
                            }
                        }else{
                            this.incrementItem(null, false);
                        }
                        
                        console.log('this.storeitemTemporal agregando',this.itemsTemp)
                        
                       
                     }
                     
                 
             

            }else{
               
                 for (let index = 0; index < this.producto.selecciones.length; index++) {
                     for (let index2 = 0; index2 < this.producto.selecciones[index].alternativas.length; index2++) {
                            for (let k in this.itemCarrito.selecciones){
                               
                                if (this.itemCarrito.selecciones[k].value==this.producto.selecciones[index].alternativas[index2].id){
                                    this.producto.selecciones[index].alternativas[index2].checked = true;
                                }
                            }
                         
                     }
                     
                 }
                
                for (let index = 0; index < this.itemCarrito.cantidad; index++) {
                    let itemTemporal = {...this.itemCarrito};
                    itemTemporal.cantidad = 1;
                    console.log('agregando push',itemTemporal)
                    this.itemsTemp.push({...itemTemporal});
                    this.item = {...itemTemporal};
                }
                
                this.itemsTempActive = 0;
            }
          
            
        }

        this.updatePrecio();

    }

    isAbierto(entity, option = 'tienda') {
        return this.dataService.isAbierto(entity, option, this.tienda);
    }

    check(alternativa, seleccion) {
        // const iAlternativa = this.item.alternativas[alternativa.id];
        // const iSeleccion = this.item.selecciones[seleccion.id];
        // if (iAlternativa.checked) {
        //     iSeleccion.checkeds++;
        // } else {
        //     iSeleccion.checkeds--;
        // }
        // console.log(iAlternativa, iSeleccion);
        this.updatePrecio();
    }

    async incrementItem(input, guardar=false) {
        this.inIncrement = true;
        const errorAlert = this.checkMultiPago();

        if (errorAlert) {
            await this.uiService.presentAlert(errorAlert, 'Atención');

            //this.modalCtrl.dismiss();
            //this.verCarrito();

            return;
        }
        this.item.cantidad =  1 ;
        
        
        if (this.item.productoId)
        this.producto = await this.dataService.getProducto(this.item.productoId)
        
        console.log('aca ',this.producto, this.item)
        
            this.item.cantidad = 1 ;

            if ((this.item.cantidad === 0 )) {
                await this.eliminarItem();
                this.inIncrement = false;
                return;
            }
    
            const form = this.formItem;
            const error = null;
     
            if (guardar==false){
                if (!this.isAbierto(this.tienda)) {
                    await this.uiService.presentToast('Este restaurant se encuentra cerrado','middle');
                    this.inIncrement = false;
                     
                    return;
                }
        
                if (!this.isAbierto(this.producto, 'producto') || !this.producto.disponible) {
                    await this.uiService.presentToast('Este producto no se encuentra disponible','middle');
                    this.inIncrement = false;
                     
                    return;
                }
        
                if ((form.invalid || error)) {
                    await this.uiService.presentToast(error ? error : 'Debes seleccionar los campos requeridos','middle');
                    this.inIncrement = false;
                     
                    return;
                }
            }
            
    
               


            if (guardar==false)  {
               
                this.item = {
                    id: null,
                    productoId: null,
                    categoriaId: null,
                    categoriaNombre: null,
                    imagenName: null,
                    tiendaId: null,
                    tiendaSlug: null,
                    tiendaNombre: null,
                    tiendaMultiPago: false,
                    nombre: null,
                    precio: 0,
                    descuento: 0,
                    precioFinal: 0,
                    cantidad: 1,
                    observaciones: null,
                    calificacion: null,
                    modificadoTienda: null,
                    // supero: false,
                    selecciones: {},
                    alternativas: {}
                };
    
                this.items = await this.dataService.getItems() || [];
    
                this.item.productoId = this.producto.id;
                this.item.id = this.items.length + this.itemsTemp.length;
                this.item.precio = this.producto.precio;
                this.item.descuento = this.producto.descuento;
                this.item.precioFinal = this.producto.precioFinal;
                this.item.nombre = this.producto.nombre;
                this.item.imagenName = this.producto.imagenName;
                this.item.tiendaId = this.tienda.id;
                this.item.tiendaSlug = this.tienda.slug;
                this.item.tiendaNombre = this.tienda.nombre;
                this.item.tiendaMultiPago = this.tienda.multiPago;
                // console.log(this.producto);
                this.tienda = await this.dataService.getTienda(this.producto.tiendaId);
                this.item.precio = this.producto.precio;
                this.item.descuento = this.producto.descuento;
                this.item.precioFinal = this.producto.precioFinal;
                this.item.nombre = this.producto.nombre;
                this.item.imagenName = this.producto.imagenName;
                this.item.tiendaId = this.tienda.id;
                this.item.tiendaSlug = this.tienda.slug;
                this.item.tiendaNombre = this.tienda.nombre;
                this.item.tiendaMultiPago = this.tienda.multiPago;


                const selecciones = {};
                const alternativas = {};
                this.producto.selecciones.forEach((seleccion: Seleccion, index: number) => {
                    selecciones[seleccion.id] = {
                        id: seleccion.id,
                        seleccionNombre: seleccion.nombre,
                        checkeds: 0,
                        value: null
                    };
                    seleccion.alternativas.forEach((alternativa: Alternativa, aIdx: number) => {
                        alternativas[alternativa.id] = {
                            id: alternativa.id,
                            cantidad: 0,
                            seleccionNombre: seleccion.nombre,
                            nombre: alternativa.nombre,
                            precio: alternativa.precio
                        };
                    });
                });
                this.item.selecciones = selecciones;
                this.item.alternativas = alternativas;

             

                for (let index = 0; index < this.producto.selecciones.length; index++) {
                    for (let index2 = 0; index2 < this.producto.selecciones[index].alternativas.length; index2++) {
                        this.producto.selecciones[index].alternativas[index2].checked = false;
                         
                         
                     }
                    
                }

                let item = {...this.item}
                console.log('agregando push', item)
                this.itemsTemp.push(item);
                this.itemsTempActive = this.itemsTemp.length-1;
                console.log('agregando add',this.itemsTemp,this.add)
                
                 this.dataService.storeItemTemporal(this.itemsTemp);
                 this.inIncrement = false;
            }else{
                console.log('agregando',this.itemsTemp)
                this.dataService.storeItemTemporal(this.itemsTemp);
            }
            
               
          
            this.updatePrecio();
            
           
                 
        
        
        
    }
    async addTemp(){
        console.log('asdasdasd ', new Date(),this.itemsTemp)
        this.guardadoTemp = true;
        this.item = {
            id: null,
            productoId: null,
            categoriaId: null,
            categoriaNombre: null,
            imagenName: null,
            tiendaId: null,
            tiendaSlug: null,
            tiendaNombre: null,
            tiendaMultiPago: false,
            nombre: null,
            precio: 0,
            descuento: 0,
            precioFinal: 0,
            cantidad: 1,
            observaciones: null,
            calificacion: null,
            modificadoTienda: null,
            // supero: false,
            selecciones: {},
            alternativas: {}
        };

        this.items = await this.dataService.getItems() || [];

        this.item.productoId = this.producto.id;
        this.item.id = this.items.length + this.itemsTemp.length;
        this.item.precio = this.producto.precio;
        this.item.descuento = this.producto.descuento;
        this.item.precioFinal = this.producto.precioFinal;
        this.item.nombre = this.producto.nombre;
        this.item.imagenName = this.producto.imagenName;
        this.item.tiendaId = this.tienda.id;
        this.item.tiendaSlug = this.tienda.slug;
        this.item.tiendaNombre = this.tienda.nombre;
        this.item.tiendaMultiPago = this.tienda.multiPago;
        // console.log(this.producto);
        this.tienda = await this.dataService.getTienda(this.producto.tiendaId);
        this.item.precio = this.producto.precio;
        this.item.descuento = this.producto.descuento;
        this.item.precioFinal = this.producto.precioFinal;
        this.item.nombre = this.producto.nombre;
        this.item.imagenName = this.producto.imagenName;
        this.item.tiendaId = this.tienda.id;
        this.item.tiendaSlug = this.tienda.slug;
        this.item.tiendaNombre = this.tienda.nombre;
        this.item.tiendaMultiPago = this.tienda.multiPago;


        const selecciones = {};
        const alternativas = {};
        this.producto.selecciones.forEach((seleccion: Seleccion, index: number) => {
            selecciones[seleccion.id] = {
                id: seleccion.id,
                seleccionNombre: seleccion.nombre,
                checkeds: 0,
                value: null
            };
            seleccion.alternativas.forEach((alternativa: Alternativa, aIdx: number) => {
                alternativas[alternativa.id] = {
                    id: alternativa.id,
                    cantidad: 0,
                    seleccionNombre: seleccion.nombre,
                    nombre: alternativa.nombre,
                    precio: alternativa.precio
                };
            });
        });
        this.item.selecciones = selecciones;
        this.item.alternativas = alternativas;

     

        for (let index = 0; index < this.producto.selecciones.length; index++) {
            for (let index2 = 0; index2 < this.producto.selecciones[index].alternativas.length; index2++) {
                this.producto.selecciones[index].alternativas[index2].checked = false;
                 
                 
             }
            
        }
        
        let item = {...this.item}
        console.log('agregando push', item)
        this.itemsTemp[this.itemsTempActive] = (item);
        this.itemsTempActive = this.itemsTemp.length-1;
        console.log('agregando add',this.itemsTemp,this.add)
        
         this.dataService.storeItemTemporal(this.itemsTemp);
    }
   setSeleccion(valor,id, ii,alt,ialter){
       if (alt==false){
        this.item.selecciones[id].value = null;
       }else{
        this.item.selecciones[id].value = valor;
       }
     console.log(id,this.item.selecciones[id],this.item.selecciones,this.item.alternativas)
     console.log(valor,id,alt, this.producto)
      let index = ii;

       let seleccionadas =  alt ? 1 : 0;
      for (let index2 = 0; index2 < this.producto.selecciones[index].alternativas.length; index2++) {
     
           
        if (this.producto.selecciones[index].alternativas[index2].checked === true){
            
            seleccionadas++;
        }

    }
    console.log('selec',this.item.selecciones,seleccionadas,this.producto.selecciones[index].maximo,this.item.selecciones[id].value,this.producto.selecciones[index].alternativas[ialter])
    
    this.producto.selecciones[index].alternativas[ialter].checked = alt;

    if ( this.producto.selecciones[index].maximo>1){
        
        
        return false;
    }else{

         let seleccionado=false;
        for (let index2 = 0; index2 < this.producto.selecciones[index].alternativas.length; index2++) {
             this.producto.selecciones[index].alternativas[index2].checked = false;
            
            if (this.producto.selecciones[index].alternativas[index2].id==valor){ 
               this.producto.selecciones[index].alternativas[index2].checked = true;
            }
            
        }

        for (let index2 = 0; index2 < this.producto.selecciones[index].alternativas.length; index2++) {
    
          
           if (this.producto.selecciones[index].alternativas[index2].checked === true){
               
              seleccionado=true;
           }

       } 
    }



      

        setTimeout(() => {
            this.updatePrecio();
        }, 300);
      
    
   }
    async increment(input, max) {

        if (max==false){

            const errorAlert = this.checkMultiPago();

            if (errorAlert) {
                await this.uiService.presentAlert(errorAlert, 'Atención');
                return;
            }
            input.cantidad = input.cantidad + 1;
            
            await this.showAgregados(input.cantidad);
           
            this.updatePrecio();

        }
        
        
         
        
    }
 async setItemTemp(item, index){
       
        console.log(index,this.itemsTempActive, this.item, item)
        this.itemsTemp[this.itemsTempActive] = {...this.item};
        this.itemsTempActive = index;
        
        this.item = {...item};
        let producto = await this.dataService.getProducto(this.item.productoId);
        this.producto = {...producto}
        
        for (let index = 0; index < this.producto.selecciones.length; index++) {
            for (let index2 = 0; index2 < this.producto.selecciones[index].alternativas.length; index2++) {
                this.producto.selecciones[index].alternativas[index2].checked = false;
                   for (let k in item.selecciones){
                        
                       if (item.selecciones[k].value==this.producto.selecciones[index].alternativas[index2].id){
                           this.producto.selecciones[index].alternativas[index2].checked = true;
                       }
                   }
                
            }
            
        }
        console.log('agregando',this.itemsTemp)
        this.dataService.storeItemTemporal(this.itemsTemp);
        
    }
    async decrement(input) {
        input.cantidad--;
        this.updatePrecio();
        
    }
    async decrementItem(input) {
        input.cantidad--;
        if (input.cantidad==0){
            
            this.itemsTemp.splice(this.itemsTempActive, 1);
             
            
            this.itemsTempActive--;
            if ( this.itemsTempActive<0){
                this.itemsTempActive = 0;
            }
            console.log('agregando',this.itemsTemp)
            this.dataService.storeItemTemporal(this.itemsTemp);
            let item = this.itemsTemp[this.itemsTempActive];
            this.producto = await this.dataService.getProducto(item.productoId)
            this.item = {...item};
            for (let index = 0; index < this.producto.selecciones.length; index++) {
                for (let index2 = 0; index2 < this.producto.selecciones[index].alternativas.length; index2++) {
                    this.producto.selecciones[index].alternativas[index2].checked = false;
                       for (let k in item.selecciones){
                            
                           if (item.selecciones[k].value==this.producto.selecciones[index].alternativas[index2].id){
                               this.producto.selecciones[index].alternativas[index2].checked = true;
                           }
                       }
                    
                }
                
            }

        }else{
          
            this.updatePrecio();
        }
        this.updatePrecio();
       
        
    }
    async softDelete(input) {
        input.cantidad = 0;
        this.updatePrecio();
        await this.guardar();
        this.showCart = (await this.dataService.getItems()).length > 0;
    }

    checkMultiPago() {
        let errorAlert = null;
        let multiTienda = false;

        this.items.forEach((item: Item, iKey: number) => {
            if (item.tiendaId !== this.tienda.id) {
                multiTienda = true;
                if (!item.tiendaMultiPago) {
                    errorAlert = 'La tienda ' + item.tiendaNombre + ' no acepta pedidos múltiples, para continuar finaliza el pedido anterior.';
                }
            }
            // if (item.tiendaId !== this.tienda.id) {
            //     // this.uiService.presentAlert('Tienes items en tu carro que no corresponden a esta tienda.', 'Error');
            //     // await this.dataService.setStorageData('volverCarro', true);
            //     // this.navCtrl.navigateBack('/tienda-ver/' + item.tiendaSlug, {animated: true});
            //     // error = true;
            //     // break;
            //     // this.eliminarItem($item);
            //     this.items.splice((Number(iKey)), 1);
            // }
        });

        if (multiTienda && !this.tienda.multiPago) {
            errorAlert = 'La tienda ' + this.tienda.nombre + ' no acepta pedidos múltiples, para continuar finaliza el pedido anterior.';
        }
    
        return errorAlert;
    }

    async updatePrecio() {

        let precio = Number(this.producto.precio);
        const descuento = Number(this.producto.descuento);
        let precioFinal = Number(this.producto.precioFinal);
        let producto = await this.dataService.getProducto(this.item.productoId)
        this.producto.selecciones.forEach((seleccion: Seleccion, index: number) => {
            const iSeleccion = this.item.selecciones[seleccion.id];
            if (iSeleccion){
                let checkeds = 0;
                // console.log(iSeleccion);
                seleccion.alternativas.forEach((alternativa: Alternativa, aIdx: number) => {
                    const iAlternativa = this.item.alternativas[alternativa.id];
                    if (iAlternativa){
                        if (!seleccion.multiple) {
                            // tslint:disable-next-line:radix
                            if (alternativa.checked==true) {
                                iAlternativa.cantidad = 1;
                            } else {
                                iAlternativa.cantidad = 0;
                            }
                        }
                        // console.log(iAlternativa);
                        // tslint:disable-next-line:radix
                        // if (iAlternativa.checked) {
                        //     precio += alternativa.precio;
                        //     checkeds++;
                        // }
                        precio += Number(alternativa.precio) * iAlternativa.cantidad;
                        precioFinal += Number(alternativa.precio) * iAlternativa.cantidad;
                        checkeds += iAlternativa.cantidad;
                    }
                    
                });
                console.log('checkeds', checkeds)
                iSeleccion.checkeds = checkeds;
                this.item.selecciones[seleccion.id] = iSeleccion
            }
            
        });
        this.item.precio = precio;
        this.item.descuento = descuento;
        this.item.precioFinal = precioFinal;
        if (this.item.productoId){
            this.itemsTemp[this.itemsTempActive] = {...this.item};
        }
        
       
        this.updatePrecioGeneral();
 
    }

    updatePrecioGeneral(){
        if (this.itemsTemp.length==0){
            
            this.precioFinal = this.item.precioFinal*1;
        }else{
            this.precioFinal = 0;
           
            for (let index = 0; index < this.itemsTemp.length; index++) {
                console.log(this.precioFinal, this.itemsTemp[index].precioFinal)
                this.precioFinal = this.precioFinal + this.itemsTemp[index].precioFinal*1;
                
            }
        }
    }


    async guardar() {

        const form = this.formItem;
        let error = null;

        const errorAlert = this.checkMultiPago();

        if (errorAlert) {
            await this.uiService.presentAlert(errorAlert, 'Atención');

            //this.modalCtrl.dismiss();
            //this.verCarrito();

            return;
        }
        
        if (!this.isAbierto(this.tienda)) {
            await this.uiService.presentToast('Este restaurant se encuentra cerrado','middle');
            this.inIncrement = false;
       
            return;
        }

        if (!this.isAbierto(this.producto, 'producto') || !this.producto.disponible) {
            await this.uiService.presentToast('Este producto no se encuentra disponible','middle');
            this.inIncrement = false;
            
            return;
        }

        if ((form.invalid || error)) {
            await this.uiService.presentToast(error ? error : 'Debes seleccionar los campos requeridos','middle');
            this.inIncrement = false;
            
            return;
        }
        

        if ((this.item.cantidad === 0 ) && this.itemsTemp.length==0) {
            await this.eliminarItem();
            return;
        }

      

        // console.log(this.item);
        // return;

        // await this.pedidos.forEach((pedido: Pedido, key: number) => {
        //     pedido.items.forEach((item: Item, iKey: number) => {
        //         // if (!pedido.rechazado && item.tiendaId !== this.tienda.id) {
        //         //     this.uiService.presentAlert('Tienes pedidos en curso que no corresponden a esta tienda. ' + pedido.tiendaNombre +
        //         ' debe cerrar el ciclo de tu pedido #' + pedido.id + ' mesa ' + pedido.mesaNombre +
        //         ' para que puedas continuar.', 'Error');
        //         //     this.navCtrl.navigateBack('/orden-ver', {animated: true});
        //         //     // this.navCtrl.navigateBack('/tienda-ver/' + item.tiendaSlug, {animated: true});
        //         //     error = true;
        //         // }
        //     });
        // });

        /* Muevo esto a la parte de cerrar el MODAL.
        this.producto.selecciones.forEach((seleccion: Seleccion, index: number) => {
            const iSeleccion = this.item.selecciones[seleccion.id];
            if (seleccion.minimo > 0 && iSeleccion.checkeds < seleccion.minimo) {
                error = 'Debes seleccionar al menos ' + seleccion.minimo + ' alternativas de ' + seleccion.nombre;
            }
        });
         */
        this.producto.selecciones.forEach((seleccion: Seleccion, index: number) => {
            const iSeleccion = this.item.selecciones[seleccion.id];
            if (seleccion.minimo > 0 && iSeleccion.checkeds < seleccion.minimo) {
                error = 'Debes seleccionar al menos ' + seleccion.minimo + ' alternativas de ' + seleccion.nombre;
            }
        });
        if (error) {
            await this.uiService.presentAlert(error, 'Atención');

            //this.modalCtrl.dismiss();
            //this.verCarrito();

            return;
        }
        if (!this.isAbierto(this.tienda)) {
            await this.uiService.presentToast('Este restaurant se encuentra cerrado');
            return;
        }

        if (!this.isAbierto(this.producto, 'producto') || !this.producto.disponible) {
            await this.uiService.presentToast('Este producto no se encuentra disponible');
            return;
        }

        if ((form.invalid || error) && this.itemsTemp.length==0) {
            await this.uiService.presentToast(error ? error : 'Debes seleccionar los campos requeridos','middle');
            return;
        }
        if (this.itemsTempActive>-1){
            this.itemsTemp[this.itemsTempActive] = {...this.item};
        }
        console.log('item guardar', this.item,  this.itemsTemp)
        if (this.modificandoTienda) {
            /*const itemData = this.dataService.getItemData(this.item);
            console.log(itemData);
            // return;
            const loading = await this.uiService.presentLoading('Enviando datos...');
            const result = await this.apiService.put('/jwt/item/edit/' + this.id, itemData);
            console.log(result);
            await loading.dismiss();
            if (result) {
                // console.log(this.tienda.sistemaPOS, this.tienda);
                if (this.tienda.sistemaPOS) {
                    await this.uiService.presentAlert('Modificación realizada exitosamente. Recuerde solicitar al administrador del local, que replique estos cambios en el POS.', null, null, 'alert-over');
                }
                await this.navCtrl.navigateBack('pedido-lista?item=' + this.id, {animated: true});
            }*/
        } else {
            if (this.itemsTemp.length==0){
                console.log('agregando',this.itemsTemp)
                await this.dataService.storeItem(this.item);
                this.eventsService.publish('items:update', null);
    
                if (this.entity === 'producto') {
                    // this.navCtrl.navigateBack('/' + this.tienda.slug + '/carta', {animated: true});
                } else {
                    //await this.navCtrl.navigateBack('item-lista', {animated: true});
                }
                // }
            }else{
                let todosSinSeleccion=false;
                let agrupados = this.groupBy(this.itemsTemp, "productoId");
                console.log('obj',agrupados)
                for (let index = 0; index < agrupados.length; index++) {
                    let cantidadItems = 0;
                    for (let index2 = 0; index2 < agrupados[index].length; index2++) {
                        console.log('obj '+index+' '+index2,agrupados[index][index2])
                        
                        for (let k in  agrupados[index][index2].selecciones){
                            cantidadItems++;
                        }
                         
                         
                    }
                    if (cantidadItems==0){
                        let cantidades = 0;
                        for (let index2 = 0; index2 < agrupados[index].length; index2++) {
                            cantidades = cantidades + agrupados[index][index2].cantidad;
                         }
                         agrupados[index][0].cantidad = cantidades;
                         agrupados[index][0].id = this.items.length + index  ;

                         if (this.itemCarrito!=null && index==0){
                            agrupados[index][0].id = this.itemCarrito.id;
                        }else{
                            if (this.itemCarrito!=null)
                            agrupados[index][0].id = this.items.length + index - 1  ;
                        }

                         await this.dataService.storeItem(agrupados[index][0]);
                         this.items = await this.dataService.getItems() || [];
                    }else{


                        for (let index2 = 0; index2 < agrupados[index].length; index2++) {

            

                       
                            agrupados[index][index2].id = this.items.length + index2  ;
                            if (this.itemCarrito!=null && index2==0){
                                agrupados[index][index2].id = this.itemCarrito.id;
                            }else{
                                if (this.itemCarrito!=null)
                                agrupados[index][index2].id = this.items.length + index - 1  ;
                            }
                            if ( agrupados[index][index2].id!=undefined )
                            await this.dataService.storeItem(agrupados[index][index2]);
                            this.items = await this.dataService.getItems() || [];
        
                             
                        }


                    }
                    
                }
                 
                /*if (this.producto.selecciones.length==0){
                    let cantidades = 0;
                    for (let index = 0; index < this.itemsTemp.length; index++) {
                        cantidades = cantidades + this.itemsTemp[index].cantidad;
                        
                    }
                    this.itemsTemp[0].cantidad = cantidades;
                    await this.dataService.storeItem(this.itemsTemp[0]);
                }
                else{
                    for (let index = 0; index < this.itemsTemp.length; index++) {

            

                        console.log('item guardar '+index, this.items.length, this.itemsTemp[index])
                        this.itemsTemp[index].id = this.items.length + index  ;
                        if (this.itemCarrito!=null && index==0){
                            this.itemsTemp[index].id = this.itemCarrito.id;
                        }else{
                            if (this.itemCarrito!=null)
                            this.itemsTemp[index].id = this.items.length + index - 1  ;
                        }
                        if ( this.itemsTemp[index].id!=undefined )
                        await this.dataService.storeItem(this.itemsTemp[index]);
    
                         
                    }
                }*/
                
                this.eventsService.publish('items:update', null);
            }
            console.log('agregando',this.itemsTemp)
            this.dataService.storeItemTemporal([]);

        }
        this.showCart = (await this.dataService.getItems()).length > 0;

        const dataLayer = window['dataLayer'] || [];
        dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        dataLayer.push({
            'event': 'addToCart',
            'ecommerce': {
                'currencyCode': 'CLP',
                'add': {
                    'products': [{
                        'name': this.producto.nombre,
                        'id': this.producto.id,
                        'price': this.producto.precioFinal,
                        'brand': this.tienda.nombre,
                        'category': this.producto.categoriaNombre,
                        'variant': '',
                        'quantity': this.item.cantidad
                    }]
                }
            }
        });
        console.log('dataLayer',dataLayer)
        /*if (this.tieneRecomendaciones.length>0){
            this.content.scrollToPoint(0,this.recom.el.offsetTop-50, 500);
            this.recomendar = true;
        }else{
            this.modalCtrl.dismiss();
        }*/
       
        this.modalCtrl.dismiss();
    }
    groupBy(collection, property) {
        var i = 0, val, index,
            values = [], result = [];
        for (; i < collection.length; i++) {
            val = collection[i][property];
            index = values.indexOf(val);
            if (index > -1)
                result[index].push(collection[i]);
            else {
                values.push(val);
                result.push([collection[i]]);
            }
        }
        return result;
    }
    async eliminarTapped() {
        await this.uiService.presentAlertConfirm('Está seguro que desea eliminar este producto?', 'Atención', 'item:eliminar');
    }

    async eliminarItem() {
        if (this.modificandoTienda) {
            this.item.cantidad = 0;
            await this.guardar();
            return;
        }

        this.items.splice((Number(this.id)), 1);
        this.items.forEach((item: Item, iKey: number) => {
            item.id = iKey;
        });
        await this.dataService.setStorageData('items', this.items);
        this.eventsService.publish('items:update', null);

        if (this.entity !== 'producto') {
            if (this.items.length === 0) {
                await this.navCtrl.navigateBack('/' + this.tienda.slug + '', {animated: true});
            } else {
                await this.navCtrl.navigateBack('item-lista', {animated: true});
            }
        }
    }

    checkFormValid() {
        return this.formItem && this.formItem.valid;
    }

    async showAgregados(cant) {
        this.hideMoreContent = false;
        setTimeout(() => {
            if (cant === 0 && this.producto.selecciones && this.producto.selecciones.length) {
                this.stateModalAgregados = 'open';
                setTimeout(() => {
                    if (this.stateModalAgregados === 'open') {
                        this.hideMoreContent = true;
                    }
                }, 4000);
            } else {
                this.agregoitem = true;
                //this.guardar();
            }
        }, 100);
    }

    async addAgregados(ev) {

        const form = this.formItem;
        let error = null;
        let close = true;

        this.producto.selecciones.forEach((seleccion: Seleccion, index: number) => {
            const iSeleccion = this.item.selecciones[seleccion.id];
            if ((seleccion.minimo > 0 && iSeleccion && iSeleccion.checkeds < seleccion.minimo) ||
                (iSeleccion.checkeds === 0 && seleccion.requerido)) {
                const sub = document.getElementById('scrollable');
                sub.scrollTop = 0;
                this.hideMoreContent = false;
                setTimeout(() => {
                        this.hideMoreContent = true;
                }, 4000);
                // sub.scrollIntoView({behavior: 'smooth', block: 'start'});
                seleccion.requeridoError = true;
                close = false;
                error = 'Debes seleccionar al menos ' + (seleccion.minimo || 1) + ' alternativas de ' + seleccion.nombre;
            }else{
                seleccion.requeridoError = false;
            }
        });

        if(!close && error && ev !== 'close'){
            await this.uiService.presentToast(error ? error : 'Debes seleccionar los campos requeridos');
            return;
        }
            if (form.invalid || error || ev === 'close') {
                //this.item.cantidad = 0;
                await this.eliminarItem();
                await this.uiService.presentToast(error ? error : 'Debes seleccionar los campos requeridos');
            }

            //await this.guardar();
            this.stateModalAgregados = 'close';
            this.agregoitem = true;
            this.showCart = (await this.dataService.getItems()).length > 0;

            if (this.producto && this.producto.selecciones && this.producto.selecciones.length && this.item.cantidad === 1) {
                setTimeout(() => {
                    this.navCtrl.navigateForward('/' + (this.tienda.slugManual ? this.tienda.slugManual : this.tienda.slug) + '/carta');
                }, 500);
            }



    }

    async cancelAgregados() {
        await this.softDelete(this.item);
        this.stateModalAgregados = 'close';
    }

    async closeModal(ev) {
        await this.addAgregados(ev);
    }

    changeStateModal(ev) {
        // do nothing x ahora
    }

    showRecomendacionesProducto(ev) {
        if (ev.id === this.producto.id) {
            this.showProducto = ev && ev.productos && ev.productos.length;
        }
    }


    async loadDataSlides() {
        this.slidesOk = false;
        this.tienda = await this.dataService.getTienda(this.producto.tiendaId);
        this.productos = await this.dataService.getStorageData('productosArray');
        if (!this.productos || !this.tienda) {
            // this.uiService.presentToast(!this.productos ? 'Sin productos' : 'Sin tienda');
            // await this.dataService.goHome(); 
            // this.router.navigate(['item-form/producto/' + producto.id]);
            // this.router.navigate(['/carta/' + this.slug ]);
            return;
        } else {
            console.log(this.productos);
            this.productos = this.productos.filter(
                producto => this.dataService.isAbierto(producto, 'producto', this.tienda));
            console.log(this.productos);
        }


        // let vIndex = 0;
        // let virtualRow = 0;
        let prodRow = 0;
        let totRow = 0;
        let varIndex = 0;
        let startIndex = 0;
        let i = 0;
        this.productos.forEach((producto: Producto, index: number) => {
            // console.log(producto.id, this.id);
            if (this.id) {
                if (producto.id === Number(this.id)) {
                    console.log(producto);
                    // this.slideOpts.initialSlide = index;
                    this.producto = producto;
                    this.productoIndex = index;

                    while (i <= this.productoIndex) {
                        if (varIndex % this.slidesAmount === 0) {
                            if (varIndex !== 0) {
                                i -= 3;
                                prodRow++;
                            }
                            startIndex = 0;
                        } else {
                            startIndex++;
                        }

                        i++;
                        varIndex++;
                    }

                    varIndex = 0;
                    i = 0;
                    while (i <= this.productos.length) {
                        if (varIndex !== 0 && varIndex % this.slidesAmount === 0) {
                            i -= 3;
                            totRow++;
                        }
                        // console.log(i, varIndex, totRow);
                        i++;
                        varIndex++;
                    }
                    return;
                }
                // virtualIndex++;
            } else {
                this.producto = producto;
                return;
            }
        });
        // console.log(varIndex, prodRow, totRow, this.productos.length);

        // this.productoRow = Math.floor(this.productoIndex / this.slidesAmount);
        // this.totalRows = Math.floor(this.productos.length / this.slidesAmount);
        this.productoRow = prodRow;
        this.totalRows = totRow;

        // const virtualIndex = this.productoIndex < this.slidesAmount ? this.productoIndex : this.productoIndex + this.productoRow * 3;
        // const divisor = this.slidesAmount;
        // const resto = virtualIndex % divisor;
        // this.productoRow = Math.floor(virtualIndex / this.slidesAmount);
        // console.log(this.productoIndex, divisor, resto, this.productoRow, virtualIndex);
        console.log(this.productoIndex, startIndex, this.productoRow, this.totalRows);

        if (this.id) {
            this.slideOpts.initialSlide = startIndex;
        }

        this.fpIndex = this.productoRow * (this.slidesAmount - 3);
        this.lpIndex = this.fpIndex + this.slidesAmount;
        this.productoSlides = this.productos.slice(this.fpIndex, this.lpIndex);
        console.log(this.fpIndex, this.lpIndex);

        // this.producto = await this.dataService.getProducto(this.item.productoId);

        this.decimales = await this.dataService.getStorageData('decimales');
        this.locale = await this.dataService.getStorageData('locale');
        this.simboloMoneda = await this.dataService.getStorageData('simboloMoneda');

        this.slidesOk = true;
    }

    async slideChange($event: any) {

        if (this.firstLoad) {
            this.firstLoad = false;
            // return;
        }

        this.showProducto = false;
        // console.log($event);
        this.slides.getActiveIndex().then(async index => {
            console.log(index, this.fpIndex, this.lpIndex);
            if (index === 0 || index === this.slidesAmount - 1) {

            }
            if (index === 0 && this.productoRow !== 0) {
                this.fpIndex = this.fpIndex - (this.slidesAmount - 3);
                this.lpIndex = this.fpIndex + this.slidesAmount;
                this.productoSlides = this.productos.slice(this.fpIndex, this.lpIndex);
                index = this.slidesAmount - 3;
                this.productoRow--;
                this.slides.slideTo(index, 0);
            }

            if (index === this.slidesAmount - 1 && this.productoRow < this.totalRows) {
                this.fpIndex = this.lpIndex - 3;
                this.lpIndex = this.fpIndex + this.slidesAmount;
                this.productoSlides = this.productos.slice(this.fpIndex, this.lpIndex);
                index = 2;
                this.productoRow++;
                this.slides.slideTo(index, 0);
            }
            // this.productoSlides = this.productos.slice(index, index+5);
            this.producto = this.productoSlides[index];
            console.log(index, this.fpIndex, this.lpIndex);

            this.item = {
                id: null,
                productoId: null,
                categoriaId: null,
                categoriaNombre: null, 
                imagenName: null,
                tiendaId: null,
                tiendaSlug: null,
                tiendaNombre: null,
                tiendaMultiPago: false,
                nombre: null,
                precio: 0,
                descuento: 0,
                precioFinal: 0,
                cantidad: 0,
                observaciones: null,
                calificacion: null,
                modificadoTienda: null,
                // supero: false,
                selecciones: {},
                alternativas: {}
            };

            this.items = await this.dataService.getItems() || [];

            this.item.productoId = this.producto.id;
            this.item.id = this.items.length;
            this.item.precio = this.producto.precio;
            this.item.descuento = this.producto.descuento;
            this.item.precioFinal = this.producto.precioFinal;
            this.item.nombre = this.producto.nombre;
            this.item.imagenName = this.producto.imagenName;
            this.item.tiendaId = this.tienda.id;
            this.item.tiendaSlug = this.tienda.slug;
            this.item.tiendaNombre = this.tienda.nombre;
            this.item.tiendaMultiPago = this.tienda.multiPago;
            console.log('item actualizado', this.item);

        });
    }

    onImgError(event) {
        event.target.src = this.tienda?.fotoProductoNameS3 ? this.tienda?.fotoProductoNameS3 : this.tienda?.logoName;
    }
    volver(){
        this.modalCtrl.dismiss();
        console.log('volver')
    }


    async verCarrito(){
        const modal = await this.modalCtrl.create({
            component: CarritoCompraPage,
            mode: 'ios',
            cssClass: 'my-custom-class',
            swipeToClose: true,
            presentingElement: await this.modalCtrl.getTop(), 
          });
          await modal.present();
          const { data } = await modal.onWillDismiss();
          console.log(data);
    }
    async verProducto(item,add) {
        this.modalCtrl.dismiss();
        const modal = await this.modalCtrl.create({
          component: ProductoDetallePage,
          mode: 'ios',
          cssClass: 'my-custom-class',
          swipeToClose: true, 
          componentProps: {
            add:add,
            producto: item
          }
        });
        await modal.present();
        const { data } = await modal.onWillDismiss();
        console.log(data);
         
    }
    async addCarrito(producto){
        
           console.log('addCarrito', producto)
           if (producto.selecciones.length>0){ 
               this.incrementItem(null, true);
               setTimeout(() => {
                this.verProducto(producto,true)
               }, 100);
               return;
           }
           let item:Item = {
                id: null,
                productoId: null,
                categoriaId: null,
                categoriaNombre: null,
                imagenName: null,
                tiendaId: null,
                tiendaSlug: null,
                tiendaNombre: null,
                tiendaMultiPago: false,
                nombre: null,
                precio: 0,
                descuento: 0,
                precioFinal: 0,
                cantidad: 0,
                observaciones: null,
                calificacion: null,
                modificadoTienda: null,
                // supero: false,
                selecciones: {},
                alternativas: {}
           };
   
           let Items = await this.dataService.getItems() || [];
           let indexProd = 0;
           for (let index = 0; index < Items.length; index++) {
               if (Items[index].productoId==producto.id && producto.selecciones.length==0){
                   indexProd = index;
               }
               
           }
           if (indexProd==0){
               item.productoId = producto.id;
               item.id = Items.length;
               item.precio = producto.precio;
               item.descuento = producto.descuento;
               item.precioFinal = producto.precioFinal;
               item.nombre = producto.nombre;
               item.imagenName = producto.imagenName;
               item.tiendaId = this.tienda.id;
               item.tiendaSlug = this.tienda.slug;
               item.tiendaNombre = this.tienda.nombre;
               item.tiendaMultiPago = this.tienda.multiPago;
               item.cantidad = 1;;
           }else{
               item = Items[indexProd];
               item.cantidad++;
           }
          
   
   
           const selecciones = {};
           const alternativas = {};
           producto.selecciones.forEach((seleccion: Seleccion, index: number) => {
               selecciones[seleccion.id] = {
                   id: seleccion.id,
                   seleccionNombre: seleccion.nombre,
                   checkeds: 0,
                   value: null
               };
               seleccion.alternativas.forEach((alternativa: Alternativa, aIdx: number) => {
                   alternativas[alternativa.id] = {
                       id: alternativa.id,
                       cantidad: 0,
                       seleccionNombre: seleccion.nombre,
                       nombre: alternativa.nombre,
                       precio: alternativa.precio
                   };
               });
           });
           item.selecciones = selecciones;
           item.alternativas = alternativas;
           
           console.log('item actualizado',producto,  item);
   
           if (!this.isAbierto(this.tienda)) {
               await this.uiService.presentToast('Este restaurant se encuentra cerrado');
               return;
           }
   
           if (!this.isAbierto(item, 'producto') || !producto.disponible) {
               await this.uiService.presentToast('Este producto no se encuentra disponible');
               return;
           }
           console.log('agregando push',item)
           this.itemsTemp.push({...item});
           this.item = {...item};
           if (this.item.productoId){
            let producto2 = await this.dataService.getProducto(this.item.productoId);
            this.producto = {...producto2}
           }
          
           this.itemsTempActive = this.itemsTemp.length-1;
           console.log('agregando',this.itemsTemp)
           await this.dataService.storeItemTemporal(this.itemsTemp);
           this.updatePrecio();
           
   
   
          
       }
       getCantidad(){
           let cantidad = 0;
           for (let index = 0; index < this.itemsTemp.length; index++) {
               if (this.itemsTemp[index].productoId==(this.itemsTemp[this.itemsTempActive].productoId)){
                cantidad  = cantidad + this.itemsTemp[index].cantidad*1;
               }
               
           }
           return cantidad;
       }

}
