import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Producto, Usuario} from "../../interfaces/interfaces";
import {ProductoRecomendado, RecomendationService} from "../../services/recomendation-service.service";
import {DataService} from "../../services/data.service";

@Component({
  selector: 'app-recomendaciones-producto',
  templateUrl: './recomendaciones-producto.component.html',
})
export class RecomendacionesProductoComponent implements OnInit {


  @Output() getProducts: EventEmitter<any> = new EventEmitter();
  @Input() store: number;
  @Input() producto: Producto;
  products: ProductoRecomendado[]

  constructor(private dataService: DataService,
              private recomendationService: RecomendationService) {}

  async ngOnInit() {
    if (this.producto) {
      this.recomendationService.upsellRecomendation(this.producto.tiendaId,this.producto.id).then((response) => {
        this.products = response;
        this.getProducts.emit({id: this.producto.id, productos:this.products});
      });
    }

  }


}
