import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FiltroPipe } from './filtro.pipe';
import { SafehtmlPipe } from './safehtml.pipe';

@NgModule({
  declarations: [FiltroPipe, SafehtmlPipe],
  exports: [FiltroPipe, SafehtmlPipe],
  imports: [
    CommonModule
  ]
})
export class PipesModule { }
