import { Route } from '@angular/compiler/src/core';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Tienda } from 'src/app/interfaces/interfaces';

@Component({
  selector: 'app-tienda-card',
  templateUrl: './tienda-card.component.html',
  styleUrls: ['./tienda-card.component.scss'],
})
export class TiendaCardComponent implements OnInit {
  @Input() tienda;
  @Input() index;
  @Input() banners;
  img: any = '';
  constructor(private route: Router) { }

  ngOnInit() {

    console.log('this.tienda', this.tienda)
    if (this.tienda.logoMall!=null){
      this.img  = 'https://d4i7e0edqqgwm.cloudfront.net/documents/tienda/'+this.tienda.logoMall 
    }else{
      this.img  = this.tienda.imagenName
    }
  }
  goTo( ){
    console.log('this.tienda', this.tienda)
    if (this.tienda.mostrarPadre && this.tienda.tiendasCount>0){
      this.route.navigateByUrl("/"+this.tienda.slug+'/restaurantes')
    }else{
      this.route.navigateByUrl("/"+this.tienda.slug+'/home')
    }
  }
}
