import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Categoria, Tienda} from "../../interfaces/interfaces";
import {Observable, Subscription} from "rxjs";
import {DataService} from '../../services/data.service';


@Component({
  selector: 'app-menu-helper',
  templateUrl: './menu-helper.component.html',
  styleUrls: ['./menu-helper.component.scss'],
})
export class MenuHelperComponent implements OnInit {

  @Input() state: string = 'close';
  @Output() modalState = new EventEmitter<any>();
  @Output() change = new EventEmitter<any>();
  @Input() categorias: Categoria[];
  @Input() tienda: Tienda;
  @Input() usuarioTienda: any;
  visible: number;

  hasItems: boolean;
  tiendas: any[];
  mesa: any;
  constructor(private dataService: DataService) { }

  ngOnInit() {
    this.loadInfo();
  }

  toggleModal(ev) {
      this.state == 'open' ? this.closeModal() : this.openModal();
  }

  openModal() {
    this.state = 'open';
    this.modalState.emit({state: 'open'});
  }

  closeModal() {
    this.state = 'close';
    this.modalState.emit({state: 'close'});
  }

  async loadInfo() {
    this.tiendas = await this.dataService.getStorageData('superiores');
    console.log("tiendas",this.tiendas);
    this.hasItems = this.tiendas && this.tiendas.length && this.tiendas[0].hijos && this.tiendas[0].hijos.length

    this.mesa = await this.dataService.getStorageData('mesa');
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loadInfo();
  }

  goToCategory(categoria, subcategoria){
    this.change.emit({categoria: categoria, subcategoria:subcategoria});
    this.closeModal();
  }

  onVisibilityChanged(visible: number) {
    this.visible = visible;
  }

   @ViewChild('content') content: ElementRef

   @HostListener('document:click', ['$event'])
   andClickEvent(event) {
       if (this.content && !this.content.nativeElement.contains(event.target) ) {
           if (this.state == 'open') {
               this.closeModal()
           }
       }
   }

}
