import {Component, OnDestroy, OnInit} from '@angular/core';
import {EventsService} from '../../services/events.service';
import {TranslateService} from '@ngx-translate/core';
import {DataService} from '../../services/data.service';
import {AuthenticationService} from '../../services/authentication.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-lang-picker',
  templateUrl: './lang-picker.component.html',
  styleUrls: ['./lang-picker.component.scss'],
})
export class LangPickerComponent implements OnInit, OnDestroy {

  currentLang;
  subscriptions: (Subscription | any)[] = [];

  constructor(private dataService: DataService,
              private eventsService: EventsService) { }

  async ngOnInit() {
    this.currentLang = await this.dataService.getStorageData('currentLang') || 'es';

    this.subscriptions.push(this.eventsService.subscribe('language:change', (lang) => {
      console.log(lang);
      this.currentLang = lang;
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription, index: number) => {
      subscription.unsubscribe();
    });
  }

  async cambiarIdioma(lang: string) {
    this.eventsService.publish('language:change', lang);
    this.currentLang = lang;
    await this.dataService.setStorageData('currentLang', lang);
    // console.log(this.translateService.currentLang);
  }
}
