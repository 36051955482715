import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

import Amplify, {PubSub, Auth} from 'aws-amplify';
// import PubSub from '@aws-amplify/pubsub';
import API from '@aws-amplify/api';
import awsconfig from './aws-exports';
// import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';
import {AWSIoTProvider} from '@aws-amplify/pubsub/lib/Providers/AWSIotProvider';

import 'hammerjs';

API.configure(awsconfig);
// PubSub.configure(awsconfig);
Amplify.configure(awsconfig);

// if (environment.production) {
//   enableProdMode();
// }

// signUp();
// confirmSignUp();
// signIn();
async function signUp() {
    try {
        const user = await Auth.signUp({
            username: 'ikleimans@gmail.com',
            password: '12345678',
            attributes: {
                email: 'ikleimans@gmail.com',          // optional
                // phone_number,   // optional - E.164 number convention
                // other custom attributes
            }
        });
        console.log({user});
    } catch (error) {
        console.log('error signing up:', error);
    }
}

async function confirmSignUp() {
    try {
        await Auth.confirmSignUp('ikleimans@gmail.com', '021533');
    } catch (error) {
        console.log('error confirming sign up', error);
    }
}

async function signIn() {
    try {
        const user = await Auth.signIn('ikleimans@gmail.com', '12345678');
        console.log({user});

        // Auth.currentCredentials().then((info) => {
        //   console.log(info);
        //   // const cognitoIdentityId = info.data.IdentityId;
        //   pubsub();
        // });
    } catch (error) {
        console.log('error signing in', error);
    }
}


// Amplify.configure({
//   // Auth: {
//   //   // Auth config ...
//   // },
//   "aws_project_region": "us-east-1",
//   "aws_appsync_graphqlEndpoint": "https://7ctqutfq6vdzxmsqjmo44kuysu.appsync-api.us-east-1.amazonaws.com/graphql",
//   "aws_appsync_region": "us-east-1",
//   "aws_appsync_authenticationType": "API_KEY",
//   "aws_appsync_apiKey": "da2-ors2qj6zurfmrdfplnimdqrzum",
//   PubSub: {
//     aws_pubsub_region: 'us-east-1',
//     aws_pubsub_endpoint: 'wss://atpazpuxn5pxa-ats.iot.us-east-1.amazonaws.com/mqtt',
//   }
// });

// Note no options are passed in to the provider constructor -- no compiler error
// Amplify.addPluggable(new AWSIoTProvider());
//
Amplify.addPluggable(new AWSIoTProvider({
    aws_pubsub_region: 'us-east-1',
    aws_pubsub_endpoint: 'wss://atpazpuxn5pxa-ats.iot.us-east-1.amazonaws.com/mqtt',
}));


// async function pubsub(){
//   PubSub.subscribe(['myTopic1', 'myTopic2']).subscribe({
//     next: data => console.log('Message received', data),
//     error: error => console.error(error),
//     close: () => console.log('Done'),
//   });
//
//   const result = await PubSub.publish('myTopic1', { msg: 'Hello to all subscribers!' });
//   console.log(result);
// }

if (environment.production) {
    enableProdMode();
}

if (environment.name === 'prod') {
    if (window) {
        window.console.log = () => {
        };
    }
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err));

// platformBrowserDynamic().bootstrapModule(AppModule).then(() => {
//     if ('serviceWorker' in navigator && environment.production) {
//         navigator.serviceWorker.register('ngsw-worker.js');
//     }
// }).catch(err => console.log(err));
