import { Component, Input, OnInit } from '@angular/core';
import { createAnimation } from '@ionic/angular';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-gamificacion-ruleta',
  templateUrl: './gamificacion-ruleta.component.html',
  styleUrls: ['./gamificacion-ruleta.component.scss'],
})
export class GamificacionRuletaComponent implements OnInit {
  @Input() productoimagen: any;
  @Input() resultado = false;
  @Input() color: any;
  @Input() logo = false;
  @Input() tienda: any = {};
  @Input() cupon: any = {};
  showAlert = false;

  constructor(private modal: ModalController) { }

  ngOnInit() {
    console.log('ruleta ########');
  }

  click() {
    console.log('click ########');
    const animationWin = createAnimation()
      .addElement(document.getElementById('ruleta'))
      .duration(5000)
      .keyframes([
        { offset: 0, transform: 'rotate(900deg)' },
        { offset: 0.2, transform: 'rotate(600deg)' },
        { offset: 0.4, transform: 'rotate(300deg)' },
        { offset: 0.5, transform: 'rotate(250deg)' },
        { offset: 0.6, transform: 'rotate(220deg)' },
        { offset: 0.7, transform: 'rotate(180deg)' },
        { offset: 0.8, transform: 'rotate(140deg)' },
        { offset: 0.9, transform: 'rotate(100deg)' },
        { offset: 1, transform: 'rotate(92deg)' }
      ]);

    const animationLose = createAnimation()
      .addElement(document.getElementById('ruleta'))
      .duration(5000)
      .keyframes([
        { offset: 0, transform: 'rotate(900deg)' },
        { offset: 0.2, transform: 'rotate(600deg)' },
        { offset: 0.4, transform: 'rotate(350deg)' },
        { offset: 0.5, transform: 'rotate(300deg)' },
        { offset: 0.6, transform: 'rotate(250deg)' },
        { offset: 0.7, transform: 'rotate(220deg)' },
        { offset: 0.8, transform: 'rotate(200deg)' },
        { offset: 1, transform: 'rotate(179deg)' },
      ]);

      if (this.resultado) {
        animationWin.play();
      } else {
        animationLose.play();
      }
    setTimeout(() => {
      this.showAlert = true;
      setTimeout(() => {
        this.modal.dismiss();
      }, 5000);
    }, 5000);
  }

}
