import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../services/authentication.service';
import {DataService} from '../services/data.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private router: Router,
                private dataService: DataService,
                public authService: AuthenticationService) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        // const auth = this.authService.isAuthenticated();
        // if (!auth){
        //   this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        // }
        // return auth;
        const url: string = state.url;
        return this.checkLogin(url);
    }

    checkLogin(url: string): boolean {
        const auth = this.authService.isAuthenticated();
        if (!auth) {
            this.authService.redirectUrl = url;
            this.dataService.setData('redirectUrl', url);

            console.log(this.authService.redirectUrl);
            this.router.navigate(['/login']);
        }
        return auth;
    }

}
