import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProductoRecomendado, RecomendationService} from '../../services/recomendation-service.service';
import {DataService} from '../../services/data.service';
import {Usuario} from '../../interfaces/interfaces';

@Component({
    selector: 'app-recomendaciones-tienda',
    templateUrl: './recomendaciones-tienda.component.html'
})
export class RecomendacionesTiendaComponent implements OnInit {

    @Output() getProducts: EventEmitter<any> = new EventEmitter();
    @Input() store: number;
    @Input() disabled: boolean;
    private usuario: Usuario;
    products: ProductoRecomendado[];

    constructor(private dataService: DataService,
                private recomendationService: RecomendationService) {
    }

    async ngOnInit() {
        console.log('recomendaciones tienda');
        this.usuario = await this.dataService.getStorageData('usuario');
        if (this.store && this.usuario) {
            this.recomendationService.registeredUserRecomendation(this.store, this.usuario.id || 828).then((response) => {
                this.products = response;
                this.getProducts.emit(this.products);
            });
        } else if (this.store && !this.usuario) {
            this.recomendationService.guestUserRecomendation(this.store).then((response) => {
                this.products = response;
                this.getProducts.emit(this.products);
            });
        }


        /*
          this.recomendationService.shoppingCartRecomendation(44,"1306,6154,8517").then((response) => {
            console.log('shopping',response)
          });*/

    }

}
