import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { load } from '@fingerprintjs/fingerprintjs';
import { AlertController, ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-compartir',
  templateUrl: './compartir.page.html',
  styleUrls: ['./compartir.page.scss'],
})
export class CompartirPage implements OnInit {
  mostrar:boolean=false;
  @Input() pedido: any;
  compartirText:any = 'xx';
  nombre:any = '';
  telefono:any = '';
  email:any = '';

  registroForm: FormGroup;
  isSubmitted = false;
  softlogin: any;
  constructor(
    private alertController: AlertController,
    public modal:ModalController,
    private apiService: ApiService,
    private dataService: DataService,
    private uiService: UiService,
    public formBuilder: FormBuilder,
    ) {

      this.registroForm = this.formBuilder.group({
        email: ['', [  Validators.email]],
        nombre: ['', [ ]],
        telefono: ['', [ ]],
        //nacimiento: ['', []],
        //direccion: ['', []],
      });

      
     }
     get errorControl() {
      return this.registroForm.controls;
    }
  ngOnInit() {

    this.softlogin = {
      nombre: '',
      email: '',
      telefono: ''
    }

    console.log('en compatir');
  }

  celularFormat(input) {
    if (input) {
      this.softlogin.telefono = input.replace(/[^\d]/g, '');
    }
    // console.log($event);
  }
  back(){
    this.modal.dismiss();
  }
  openWSP(){
    var  element = document.createElement('a') as HTMLElement;
      element.setAttribute('href', 'https://wa.me/'+this.telefono+'?text=[QRMenu]%0a Hola, acá esta tu link de pago '+this.compartirText );
      element.setAttribute('style', 'display:none;');
      element.setAttribute('target', '_system');
      
      element.click();
  }
  async compartir(){
        this.pedido.compartir = true;
        this.pedido.compartirData = this.softlogin;
        const loading = await this.uiService.presentLoading('Enviando datos...');
        const result = await this.apiService.post('/jwt/pedido/new', this.pedido);
        let compartir = await this.dataService.getData('postResponse');
        if (compartir.compartir!=undefined){
          this.compartirText = compartir.compartir;
          this.mostrar = true;
           
        }
        loading.dismiss();
  }
}
