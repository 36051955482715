import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import 'moment/locale/es';
@Component({
  selector: 'app-card-pedido',
  templateUrl: './card-pedido.component.html',
  styleUrls: ['./card-pedido.component.scss'],
})
export class CardPedidoComponent implements OnInit {
  mesa: any;
  status: any;
  flag: any;
  time: any;
  timeLeft: any;
  @Input() pedido: any;
  icon:any = 'pending.svg'
  constructor() { }

  ngOnInit() {
    this.mesa = this.pedido.key;
    this.status = this.pedido.pedidoEstado;
    this.flag = this.pedido.pedidoEstado=='Recibido' ? 'Nuevo pedido' : '';
    if (this.pedido.estado=='Pago Pendiente'){
      this.flag = 'Solicitud de pago';
    }
    if (this.pedido.solicitaPago!=null){
      this.flag = 'Pago en linea';
    }
    this.time = this.pedido.created;
    this.timeLeft = this.pedido.created; 
    if (this.status=='Recibido'){
      this.icon = 'pending.svg';
    }
    if (this.status=='Aceptado'){
      this.icon = 'preparation.svg';
    }
    if (this.status=='Pago Pendiente'){
      this.icon = 'pay.svg';
    }
  }

  timeAgo(time){
    var now = moment(new Date()); //todays date
    var end = moment(time); // another date
    var duration = moment.duration(now.diff(end));
    var n = duration.asMinutes();
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return rhours.toString().padStart(2, '0')+":"+rminutes.toString().padStart(2, '0');
  }

}
