import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

@Pipe({
  name: 'safehtml'
})
export class SafehtmlPipe implements PipeTransform {

  constructor(private sanitizer:DomSanitizer){}

  transform(html) {
    return this.sanitizer.bypassSecurityTrustHtml(html+`<p></p><style>.product-description, .product-description *{
          margin: 0;
          padding: 0;
          padding-right: 4px;
          display: block;
          white-space: normal;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          color: black !important;
          min-height: 18px;
          max-height: 30px;
          font-size: 12px !important;
          ;}</style>`);
  }

}
