/// <reference types="@types/googlemaps"/>
import {Component, Input, Renderer2, ElementRef, Inject, OnInit, OnDestroy} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Plugins} from '@capacitor/core';
import {Comuna, Direccion, GoogleAddress, Orden, Region} from '../../interfaces/interfaces';
import {DataService} from '../../services/data.service';
import GeocoderAddressComponent = google.maps.GeocoderAddressComponent;
import {Subscription} from 'rxjs';
import PubSub from '@aws-amplify/pubsub';
import {Router} from '@angular/router';
import {EventsService} from '../../services/events.service';

const {Geolocation, Network} = Plugins;

@Component({
    selector: 'app-google-maps',
    templateUrl: './google-maps.component.html',
    styleUrls: ['./google-maps.component.scss'],
})
export class GoogleMapsComponent implements OnInit, OnDestroy {
    subscriptions: (Subscription | any)[] = [];
    @Input() direccion: Direccion = null;
    @Input() orden: Orden = null;
    @Input() apiKey: string;
    @Input() options: any;

    public map: any;
    public marker: any;
    public markers = {};
    private mapsLoaded = false;
    private networkHandler = null;
    regiones: Region[];
    comunas: Comuna[];
    comuna: Comuna;
    rutaPath;

    constructor(private renderer: Renderer2,
                private element: ElementRef,
                private dataService: DataService,
                private eventsService: EventsService,
                @Inject(DOCUMENT) private iDocument) {

    }

    async ngOnInit() {
        console.log(this.options);
        this.regiones = await this.dataService.getStorageData('regiones');
        this.comunas = await this.dataService.getStorageData('comunas');

        if (this.orden) {
            const topics = ['ordenes:ruta-update:orden-' + this.orden.id];
            console.log(topics);
            this.subscriptions.push(PubSub.subscribe(topics).subscribe({
                next: data => {
                    console.log('Message received', data, data.value);
                    const ruta = data.value.ruta;

                    console.log(this.markers);
                    if (this.markers.hasOwnProperty('rutaEnd')) {
                        const rutaEnd = ruta[ruta.length - 1];
                        const latLng = new google.maps.LatLng(rutaEnd.lat, rutaEnd.lng);
                        const marker = this.markers['rutaEnd'];
                        console.log(marker);
                        marker.setPosition(latLng);
                    } else {
                        if (ruta.length > 0) {
                            const rutaEnd = ruta[ruta.length - 1];
                            this.addMarker(rutaEnd.lat, rutaEnd.lng, 'rutaEnd');
                        }
                    }
                    this.rutaPath.setPath(ruta);
                },
                error: error => {
                    console.error(error);
                    this.eventsService.publish('pubsub:error', error);
                },
                complete: () => console.log('Done'),
            }));
        }

        this.init().then((res) => {
            console.log('Google Maps ready.');
        }, (err) => {
            console.log(err);
        });
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription: Subscription, index: number) => {
            subscription.unsubscribe();
        });
    }

    private init(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.loadSDK().then((res) => {
                this.initMap().then((res2) => {
                    resolve(true);
                }, (err) => {
                    reject(err);
                });
            }, (err) => {
                reject(err);
            });
        });
    }

    private loadSDK(): Promise<any> {
        console.log('Loading Google Maps SDK');
        return new Promise((resolve, reject) => {
            if (!this.mapsLoaded) {
                Network.getStatus().then((status) => {
                    if (status.connected) {
                        this.injectSDK().then((res) => {
                            resolve(true);
                        }, (err) => {
                            reject(err);
                        });

                    } else {
                        if (this.networkHandler == null) {
                            this.networkHandler = Network.addListener('networkStatusChange', (status2) => {
                                if (status2.connected) {
                                    this.networkHandler.remove();
                                    this.init().then((res) => {
                                        console.log('Google Maps ready.');
                                    }, (err) => {
                                        console.log(err);
                                    });
                                }
                            });
                        }
                        reject('Not online');
                    }
                }, (err) => {
                    // NOTE: navigator.onLine temporarily required until Network plugin has web implementation
                    if (navigator.onLine) {
                        this.injectSDK().then((res) => {
                            resolve(true);
                        }, (err2) => {
                            reject(err2);
                        });
                    } else {
                        reject('Not online');
                    }
                });
            } else {
                reject('SDK already loaded');
            }
        });
    }

    private injectSDK(): Promise<any> {
        return new Promise((resolve, reject) => {
            window['mapInit'] = () => {
                this.mapsLoaded = true;
                resolve(true);
            };

            const scriptMap = document.getElementById('googleMaps');
            // console.log('Exist map:', scriptMap);
            if (scriptMap) {
                this.mapsLoaded = true;
                resolve(true);
            } else {
                const script = this.renderer.createElement('script');
                script.id = 'googleMaps';

                if (this.apiKey) {
                    script.src = 'https://maps.googleapis.com/maps/api/js?key=' + this.apiKey + '&callback=mapInit&language=es';
                } else {
                    script.src = 'https://maps.googleapis.com/maps/api/js?callback=mapInit&language=es';
                }

                this.renderer.appendChild(this.iDocument.body, script);
            }
        });
    }

    private initMap(): Promise<any> {
        return new Promise((resolve, reject) => {
            let latLng = null;
            let destino = null;
            let ruta = [];
            if (this.orden) {
                const despachoData = this.orden.despachoData;
                console.log(despachoData);
                const origen = despachoData.origen;
                destino = despachoData.destino;
                if (despachoData.ruta) {
                    ruta = despachoData.ruta;
                }
                latLng = new google.maps.LatLng(origen.lat, origen.lng);
            } else if (this.direccion) {
                latLng = new google.maps.LatLng(this.direccion.lat, this.direccion.lng);
            }

            const mapOptions = {
                center: latLng,
                zoom: 12,
                streetViewControl: false,
                fullscreenControl: false,
                mapTypeControl: false,

            };

            this.map = new google.maps.Map(this.element.nativeElement, mapOptions);
            resolve(true);

            const center = this.map.getCenter();
            this.addMarker(center.lat(), center.lng(), this.orden ? 'origen' : 'center');
            this.marker = this.markers['center'];
            console.log(this.marker);

            console.log(this.orden);
            if (this.orden) {
                this.addMarker(destino.lat, destino.lng, 'destino');

                console.log(ruta);
                this.rutaPath = new google.maps.Polyline({
                    path: ruta,
                    geodesic: true,
                    strokeColor: '#0c0b0b',
                    strokeOpacity: 1.0,
                    strokeWeight: 2,
                });

                if (ruta.length > 0) {
                    const rutaEnd = ruta[ruta.length - 1];
                    this.addMarker(rutaEnd.lat, rutaEnd.lng, 'rutaEnd');
                }

                this.rutaPath.setMap(this.map);

                const bounds = new google.maps.LatLngBounds();
                for (const key in this.markers) {
                    if (this.markers.hasOwnProperty(key)) {
                        const marker = this.markers[key];
                        console.log(marker.getPosition().lat(), marker.getPosition().lng());
                        bounds.extend(marker.getPosition());
                    }
                }
                console.log(bounds);
                this.map.fitBounds(bounds);

            }

            if (this.direccion && !this.direccion.id) {
                Geolocation.getCurrentPosition().then((position) => {
                    console.log(position);
                    latLng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                    // this.direccion.lat = position.coords.latitude;
                    // this.direccion.lng = position.coords.longitude;
                    this.map.setZoom(15);
                    this.map.setCenter(latLng);
                    this.marker.setPosition(latLng);
                    this.updateLatLng(latLng);

                    const geocoder = new google.maps.Geocoder();
                    geocoder.geocode({location: latLng}, (results, status) => {
                        console.log('Geccoder result: ', results);
                        if (status === 'OK') {
                            const result = this.buildGoogleResult(results[0].address_components);
                            console.log(result);
                            const comunaGoogle = typeof result.stateOrProvince3 !== 'undefined' ? result.stateOrProvince3 : null;
                            const comuna = this.comunas.find(
                                aComuna => aComuna.nombre === comunaGoogle);
                            if (comuna) {
                                this.options.recalculatePinActivated = false;
                                console.log(this.options.recalculatePinActivated);
                                // this.direccion.regionId = comuna.regionId;
                                this.direccion.calle = result.streetName;
                                this.direccion.numero = result.streetNumber;
                                this.direccion.comunaId = comuna.id;
                                // this.recalculatePinActivated = true;
                                // console.log(this.recalculatePinActivated);
                            }
                        } else {
                            console.log('Geocode was not successful for the following reason: ' + status);
                        }
                    });
                }, (err) => {
                    // reject('Could not initialise map');

                });
            } else {
                if (this.orden) {
                    this.map.setZoom(12);
                } else {
                    this.map.setZoom(15);
                }
            }
        });

    }

    public addMarker(lat: number, lng: number, option): void {

        const latLng = new google.maps.LatLng(lat, lng);

        const markerOptions = {
            map: this.map,
            // animation: google.maps.Animation.DROP,
            position: latLng,
            draggable: false,
        };

        let icon = null;
        if (option === 'destino') {
            icon = 'home-2.png';
        } else if (option === 'origen') {
            icon = 'restaurant.png';
            option = 'center';
        } else if (option === 'rutaEnd') {
            icon = 'pickup.png';
        } else {
            markerOptions.draggable = true;
        }

        if (icon) {
            markerOptions['icon'] = '/assets/markers/' + icon;
        }

        const marker = new google.maps.Marker(markerOptions);

        this.markers[option] = marker;

        if (this.direccion) {
            google.maps.event.addListener(marker, 'dragend', (event) => {
                console.log(event);
                this.updateLatLng(event.latLng);
            });
        }
    }

    recalculatePin(skipCalle = false) {
        // skipCalle = true;
        // const modalGeneralContainer = $('#modal-direccion-container');
        const calle = this.direccion.calle;
        const numero = this.direccion.numero;
        let comuna = null;
        if (this.direccion.comunaId) {
            this.comuna = this.comunas.find(
                aComuna => aComuna.id === this.direccion.comunaId);
            comuna = this.comuna.nombre;
        }
        let region = null;
        if (this.direccion.regionId) {
            region = this.regiones.find(
                aRegion => aRegion.id === this.direccion.regionId);
            region = region.nombre;
        }

        const direccion = (!skipCalle ? (calle ? (calle + ' ') : '') + (numero ? (numero + ', ') : '') : '') + (comuna && comuna !== 'Seleccione Región' ? (comuna + ', ') : '') + (region ? region : '') + ', Chile';
        console.log(direccion);
        this.codeAddress(direccion, skipCalle);
    }

    codeAddress(address, stop = false) {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({address}, (results, status) => {
            console.log('Geccoder result: ', results);
            if (status === 'OK') {
                const result = this.buildGoogleResult(results[0].address_components);
                console.log(result);
                const comunaGoogle = typeof result.stateOrProvince3 !== 'undefined' ? result.stateOrProvince3 : null;
                const comuna = this.comuna ? this.comuna.nombre : null;
                console.log(comunaGoogle, comuna);
                if (comunaGoogle === comuna) {
                    const latLng = results[0].geometry.location;
                    this.map.setZoom(15);
                    this.map.setCenter(latLng);
                    this.marker.setPosition(latLng);
                    this.updateLatLng(latLng, result);
                } else if (!stop) {
                    this.recalculatePin(true);
                }
            } else {
                console.log('Geocode was not successful for the following reason: ' + status);
            }
        });
    }

    buildGoogleResult(addressComponents: GeocoderAddressComponent[]) {
        const result: GoogleAddress = {
            postalCode: null,
            streetNumber: null,
            streetName: null,
            city: null,
            district: null,
            stateOrProvince: null,
            stateOrProvince2: null,
            stateOrProvince3: null,
            countryCode: null,
            country: null,
            addressLine1: null,
            addressLine2: null,
        };

        // console.log(address_components);
        for (let i = addressComponents.length - 1; i >= 0; i--) {
            const component = addressComponents[i];
            // Postal code
            if (component.types.indexOf('postal_code') >= 0) {
                result.postalCode = component.short_name;
            }
            // Street number
            else if (component.types.indexOf('street_number') >= 0) {
                result.streetNumber = component.short_name;
            }
            // Street name
            else if (component.types.indexOf('route') >= 0) {
                result.streetName = component.short_name;
            }
            // City
            else if (component.types.indexOf('locality') >= 0) {
                result.city = component.short_name;
            }
            // District
            else if (component.types.indexOf('sublocality') >= 0) {
                result.district = component.short_name;
            }
            // State \ Province
            else if (component.types.indexOf('administrative_area_level_1') >= 0) {
                result.stateOrProvince = component.short_name;
            } else if (component.types.indexOf('administrative_area_level_2') >= 0) {
                result.stateOrProvince2 = component.short_name;
            } else if (component.types.indexOf('administrative_area_level_3') >= 0) {
                result.stateOrProvince3 = component.short_name;
            }
            // State \ Province
            else if (component.types.indexOf('country') >= 0) {
                result.countryCode = component.short_name;
                result.country = component.long_name;
            }
        }
        result.addressLine1 = [result.streetNumber, result.streetName].join(' ').trim();
        result.addressLine2 = '';

        return result;
    }

    updateLatLng(latLng, result = null) {
        this.direccion.lat = latLng.lat();
        this.direccion.lng = latLng.lng();
        if (result && result.postalCode) {
            this.direccion.postal = result.postalCode;
        }
        console.log(this.direccion);
    }

}
