import { Router } from '@angular/router';
import { ReservaService } from './../../services/reserva.service';
import { Reserva, Tienda } from './../../interfaces/interfaces';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AlertController, IonSlides, ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { DataService } from 'src/app/services/data.service';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { IonContent } from '@ionic/angular';
import { HostListener } from '@angular/core';

@Component({
  selector: 'app-chat-navidad',
  templateUrl: './chat-navidad.component.html',
  styleUrls: ['./chat-navidad.component.scss'],
})
export class ChatNavidadComponent implements OnInit {
  @Input() tienda: Tienda;
  @Input() tipoReserva: any;
  @Input() lugar: any;
  @Input() slug: string;
  @ViewChild('slides') slides: IonSlides;
  @ViewChild('pageTop') pageTop: IonContent;
  rutValido: boolean;
  tipoReservaAux: any;
  reservaResponse: any;
  handleWheelEvent(event) {
    event.preventDefault();
  }

  formReserva: FormGroup;
  reserva : Reserva =  {
    tienda: 0,
    usuario: 0,
    lugar: 0,
    tipoReserva: 0,
    fecha: null,
    duracion: 0,
    estado : "",
    precio: 0,
    observaciones: "",
    personas: 0,
    telefono: "",
    nombre: "",
    correo: "",
    rut: "",
    aceptaRecibirInfo: false
  }
  observaciones = [];
  chatMap = {
    "step-1" : {
      preguntas: [
        '¡Hola! ¡Esta Navidad por fin volvemos a reencontrarnos!',
        '¿Te gustaria venir a sacarte una foto conmigo?'
      ]
    },
    "step-2": {
      preguntas: [
        'Primero necesito tu nombre y apellido'
      ]
    },
    "step-3": {
      preguntas: [
        ' ¡Por fin sé tu nombre! Ahora debes enviarme tu rut, tu mail y número telefónico para gestionar tu reserva'
      ]
    },
    "step-4": {
      preguntas: [
        `Que fecha te gustaria que nos viéramos ?`
      ]
    },
    "step-5": {
      preguntas: [
        `Y por ultimo elige la hora de nuestro encuentro`
      ]
    },
    "step-6": {
      preguntas: [
        `Y por ultimo elige la hora de nuestro encuentro`
      ]
    },
  };
  pasos = Object.getOwnPropertyNames(this.chatMap);
  paso = 0;
  statusNext: boolean;
  rangoDia: any;
  horaSeleccionada = "";
  meses =  ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
  dias = ['L', 'M', 'M', 'J', 'V', 'S','D'];
  ayer =  moment().subtract(1, 'days').toDate();
  mesSeleccionado = "";
  fechaBusqueda: any;
  mostrarHeader; mostrarCalendario: boolean;
  aceptaPro; aceptaTerm; confirmarDatos; botonConfirmoSeleccionado; botonSiSeleccionado = false;
  horasDisponibles = [];
  horas: any;
  horasArr = [];
  mesesSeleccionables = [];
  fecha: any;
  isChecked = false;
  errorEmail: boolean;
  errorTelefono: boolean;
  checkVelues: boolean;
  loading:boolean = false;
  constructor(
    private reservaService: ReservaService,
    private modalController: ModalController,
    private alertController: AlertController,
    private dataService: DataService,
    private formBuilder: FormBuilder,
    private router: Router
  ) { }

  async ngOnInit() {  
    this.reserva.tienda = this.tienda.id;
    this.reserva.tipoReserva = this.tipoReserva;
    this.reserva.lugar = this.lugar;
   
    
    this.formReserva = this.formBuilder.group({
      nombre: ['', [Validators.required, Validators.minLength(3)]],
      correo: ['', Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(50),Validators.pattern(/^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i)])],
      telefono: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],
      rut: ['', [Validators.required]],
      fecha: ['', [Validators.required]],
      aceptaRecibirInfo: [false],
      tienda: [this.tienda.id],
      tipoReserva: this.tipoReserva,
      observaciones: "",
      personas: 0,
      estado: "",
      lugar: this.lugar,
      codTel: 56
    });

    
    this.mesesSeleccionables = [moment().month(11).format('MMMM')]; // Solo diciembre
    this.fechaBusqueda = moment().endOf('year');

    // this.fechaBusqueda  = this.fechaBusqueda.startOf('month').toDate();
    this.fechaBusqueda  = this.fechaBusqueda.toDate();
    
    // let inicio = moment().format('DD-MM-YYYY HH:mm');
    let inicio = moment().format('DD-MM-YYYY');
    console.log(inicio);
    
    let termino = moment(this.fechaBusqueda).set('date', 25).format('DD-MM-YYYY');
    this.fechaBusqueda  = null;
    const resp = await this.reservaService.getHorasDisponibles(this.tienda.id, inicio, termino);
    console.log(150, resp.horasDisponibles);
    
    if (resp.horasDisponibles.length === 0) {
      await this.alertReservas('Lo Sentimos', 'No quedan horas disponibles');
      this.cerrarReserva();
      return;
    }
    for (const key in resp.horasDisponibles) {
      let fecha = moment(key).toDate();
      this.horasDisponibles.push(fecha)
    }
    this.horas = resp.horasDisponibles;
    
    this.tipoReservaAux = await  this.reservaService.getTipoReservaDetalle(this.tienda.id, 'Navidad');

  }
  async getDiasDisponibles(){
    this.fechaBusqueda = moment().month(10);

    this.fechaBusqueda  = this.fechaBusqueda.startOf('month').toDate();
    
    let inicio = moment(this.fechaBusqueda).format('DD-MM-YYYY');
    let termino = moment(this.fechaBusqueda).endOf('month').add(1, 'day').format('DD-MM-YYYY');
    this.fechaBusqueda  = null;
    const resp = await this.reservaService.getHorasDisponibles(this.tienda.id, inicio, termino);
    for (const key in resp.horasDisponibles) {
      let fecha = moment(key).toDate();
      this.horasDisponibles.push(fecha)
    }
    this.horas = resp.horasDisponibles;
  }
  ionViewDidEnter() {
    this.slides.lockSwipes(true);
  }

  comenzarReserva(){
    this.botonSiSeleccionado = true;
    this.statusNext = true;
  }

  cerrarReserva(){
    this.modalController.dismiss();
  }

  nameInput(){
    this.statusNext = this.formReserva.value.nombre.length > 3;     
  }

  validateTel(telefono) {
    const legthTel = telefono.toString().length;
    this.errorTelefono = (legthTel == 9);
  }

  
  validaDatos(){    
    if (
      !this.formReserva.controls.correo.errors &&
      this.formReserva.value.correo.length > 5 &&
      this.rutValido &&
      this.formReserva.value.telefono.toString().length > 8
    ) {
      this.statusNext = true; 
    } else {
      this.statusNext = false;
    }
    this.checkVelues = true;
  }

  seleccionarPersonas(personas: number){
    this.reserva.personas = personas;
    this.statusNext = true;
  }

  agregaObservacionInput(){ 
    this.reserva.observaciones = this.observaciones.toString();
    if (this.reserva.personas === this.observaciones.length) {
      this.statusNext = true;
    }
  }

  async seleccionaMes(mes){
    this.mesSeleccionado = mes;
    mes = moment().month(mes).format("M");
    let mesActual = moment().format("M");
    console.log(mesActual);
    
    let day : any;
    
    if (mes == mesActual) {
      day = moment().format("D");
    } else {
      day = moment().day(1).format("D");
    }
    
    const anio = moment().year();
    this.fechaBusqueda = moment(`${anio}-${mes}`).toDate();
    console.log(this.fechaBusqueda);
    
    let inicio = moment(`${anio}-${mes}-${day}`).format('DD-MM-YYYY');
    let termino = moment(`${anio}-${mes}`).add(1, 'M').format('DD-MM-YYYY');
    
    const resp = await this.reservaService.getHorasDisponibles(this.tienda.id, inicio, termino);
    for (const key in resp.horasDisponibles) {
      let fecha = moment(key).toDate();
      this.horasDisponibles.push(fecha)
    }
    this.horas = resp.horasDisponibles;
    this.mostrarCalendario = true;
    this.statusNext = true;
  }

  seleccionDia(fecha){
    console.log(fecha);
    
    this.reserva.fecha = fecha;
    this.formReserva.get('fecha').setValue(fecha, {
      onlyself: true
    });
    fecha = moment(fecha).format('YYYY-MM-DD');
    this.horasArr = this.horas[fecha];
    console.log(this.horasArr);
    
    this.statusNext = true;
    console.log(this.formReserva.value);
  }


  seleccionRangoDia(rango){
    this.rangoDia = rango;
    this.statusNext = true;
  }

  aceptaRecibirInfo(e){
    console.log(e);
    this.formReserva.get('aceptaRecibirInfo').setValue(e.detail.checked, {
      onlyself: true
    });
    console.log(this.formReserva.value);
    
  }

  seleccionaHora(hora, lugar) {
    console.log(hora);
    console.log(lugar);

    this.formReserva.get('lugar').setValue(lugar, {
      onlyself: true
    });
    
    console.log(this.formReserva.value);
    
    this.horaSeleccionada = hora;
    const dateObj = new Date(this.reserva.fecha);
    this.fecha = moment(dateObj);
    const dateStr = dateObj.toISOString().split('T').shift();
    //this.reserva.fecha = moment(dateStr + ' ' + hora).format('DD-MM-YYYY HH:mm');
    this.formReserva.get('fecha').setValue(moment(dateStr + ' ' + hora).format('DD-MM-YYYY HH:mm'), {
      onlyself: true
    });
    this.fecha = moment(dateStr + ' ' + hora).toDate()
    this.statusNext = true;
  }

  async siguienteSlide(){
    const indexSlide = await this.slides.getActiveIndex();
    console.log(this.formReserva);
    
    if (this.paso === 5  && this.formReserva.valid) {
      if (this.formReserva.value.telefono.toString().length == 9) {
        console.log('actualiza telefono');
        
        let telefono = `${this.formReserva.value.codTel}${this.formReserva.value.telefono}`;
        this.formReserva.get('telefono').setValue(telefono, {
          onlyself: true
        });
      }
      console.log(this.formReserva.value);
      this.loading = true;
      const resp: any = await this.reservaService.reserva(this.formReserva.value);
      this.loading = false;
      console.log(resp);
      if (resp.success) {
        console.log('entra');
        await this.slides.lockSwipes(false);
        this.slides.slideNext();
        this.slides.lockSwipes(true);
        this.pageTop.scrollToTop(400);
        this.statusNext = false;
        this.mostrarHeader = true;
        this.paso += 1;
        return;
      }
      if (!resp.ok) {
        this.statusNext = false;
        this.mostrarHeader = true;
        this.alertReservas('No se pudo agendar tu reserva',  resp.error.failed);
        return;
      }
        
      
    }
     console.log('siguiente');
     
    await this.slides.lockSwipes(false);
    this.slides.slideNext();
    this.slides.lockSwipes(true);
    this.pageTop.scrollToTop(400);
    this.statusNext = false;
    this.mostrarHeader = true;
    this.paso += 1;
  }

  async anteriorSlide(){
    const indexSlide = await this.slides.getActiveIndex();
    if (indexSlide === 6) {
      console.log(this.slug);
      window.location.reload();
      return;
    }
    if (indexSlide === 1) this.mostrarHeader = false;
    this.paso -= 1;
    this.slides.lockSwipes(false);
    this.slides.slidePrev();
    this.slides.lockSwipes(true);
    this.pageTop.scrollToTop(400);
    this.statusNext = true;
  }

  redirectTo(uri:string){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate([uri]));
 }

  get errorCtr() {
    return this.formReserva.controls;
  }

  prevent(event){

    if (event.keyCode == 13 || event.keyCode == 9) {
      event.preventDefault();
      event.stopPropagation();
      event.srcEvent.stopPropagation();
    }
  }

  validaRut() {
    let rutCompleto = this.formReserva.value.rut;
		if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutCompleto ))
			return false;
		var tmp 	= rutCompleto.split('-');
		var digv	= tmp[1]; 
		var rut 	= tmp[0];
		if ( digv == 'K' ) digv = 'k' ;
    console.log((this.dv(rut) == digv ));
    this.rutValido = (this.dv(rut) == digv );
		return (this.dv(rut) == digv );
	}

  dv(T){
		var M=0,S=1;
		for(;T;T=Math.floor(T/10))
			S=(S+T%10*(9-M++%6))%11;
		return S?S-1:'k';
	}

  async goHome(){
    await window.location.reload();
  }

  async alertReservas(title, str) {
    const alert = await this.alertController.create({
      header: title,
      message: str,
      buttons: ['OK']
    });

    await alert.present();
  }
}
