/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://7ctqutfq6vdzxmsqjmo44kuysu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-ors2qj6zurfmrdfplnimdqrzum",
    "aws_cognito_identity_pool_id": "us-east-1:e8adb5d4-7754-4730-bcc0-aec346a94239",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_8mEZK0d8N",
    "aws_user_pools_web_client_id": "1d54k0o48luhmv6e52ir7rufqh",
    "oauth": {}
};


export default awsmobile;
