import * as moment from 'moment';
import {Moment} from 'moment';

// declare global {
//     interface Window {
//         visualViewport: any;
//     }
// }

export interface LoginResponse {
    token: string;
}


export interface Tienda {
    id: number;
    nombre: string;
    tipo: string;
    slug: string;
    slugManual: string;
    tpId: number;
    tpSlug: string;
    imagenName: string;
    logoName: string;
    logoNameS3: string;
    tema: string;
    claveSupervisor: string;
    soloCarta: boolean;
    llamarGarzon: boolean;
    mostrarTiempo: boolean;
    servir: boolean;
    servirPrepago: boolean;
    pagoPresencial: boolean;
    llevar: boolean;
    cargarHabitacion: boolean;
    verificarHabitacion: boolean;
    opcionFactura: boolean;
    despacho: boolean;
    zonaDespacho: string;
    tarifaDespacho: number;
    montoMinimoDespacho: number;
    saltarLanding: boolean;
    esconderIdiomas: boolean;
    decimalesMoneda: boolean;
    simboloMoneda: string;
    machActivo: string;
    niubizActivo: string;
    webpayActivo: string;
    oneclickActivo: string;
    amipassActivo: string;
    mercadoPago: boolean;
    // mercadoPagoServir: boolean;
    // mercadoPagoLlevar: boolean;
    // mercadoPagoDelivery: boolean;
    calificacionPromedio: number;
    calificacionCantidad: number;
    mesasCount: number;
    tiendasCount: number;
    tiempoCierreManual: number;
    tiempoCambioColor: number;
    tiempoListo: number;
    correoContactoApp: string;
    sistemaPOS: string;
    envioPOS: boolean;
    multiPago: boolean;
    cadena: boolean;
    direccionStringCompleto: string;
    mesas: Mesa[];
    horariosTienda: TiendaHorario[];
    bannersTienda: Banner[];
    recomendacionHots?: string;
    mall: boolean;
    imagenProductoDefecto: boolean;
    color: string;
    fotoProductoNameS3: string;
    backgroundImage: string;
    tipoCategoria?: string;
    consumirLocal: boolean;
    direccion: Direccion;
    propinaTakeAway?: boolean;
    siempreAbierto: boolean;
    siempreCerrado: boolean;
    listaEspera: boolean;
    tiempoEspera: number;
    cartaEspera: boolean;
    preguntaEspera: string;
    menoresEspera: boolean;
    opcionesEspera: string;
    notificacionesPendientes: string;
    controlarAforo: boolean;
    cantidadAforo: number;
    actualAforo: number;
    bloqueoPago: boolean;
    ocultarTiempoPosicionWL?: boolean;
    ocultarPosicionWL?: boolean;
    ocultarRut?: boolean;
    verPaseMovilidad?:boolean;
    pagoForzosoSinClave?: boolean;
    celular?: string;
    paraTiColor1?: string;
    paraTiColor2?: string;
    paraTiTipo?: string;
    textoRecomendacion?: string;
    logoMall?:string;
    tipoComida?:string;
 
}

export interface TiendaHorario {
    id: number;
    horaInicio: string;
    horaTermino: string;
    dias: any[];
}

export interface Banner {
    id: number;
    imagenNameS3: string;
    link: string;
    productoId: number;
    seccion: string;
    horarios: BannerHorario[];
}

export interface BannerHorario {
    id: number;
    horaInicio: string;
    horaTermino: string;
    dias: any[];
}

export interface SubCategoria {
    id: number;
    items: Producto[];
    nombre: string;
    expanded?: boolean;
}

export interface Categoria {
    id: number;
    nombre: string;
    slug: string;
    expanded: boolean;
    productos: Producto[];
    logoActivo: string
    logoInactivo: string
    subCategoriasFront: SubCategoria[];
}

export interface Producto {
    id: number;
    tiendaId: number;
    categoriaId: number;
    categoriaNombre: string;
    nombre: string;
    descripcion: string;
    precio: number;
    descuento: number;
    precioFinal: number;
    imagenName: string;
    calificacionPromedio: number;
    calificacionCantidad: number;
    destacado: boolean;
    activo: boolean;
    disponible: boolean;
    selecciones: Seleccion[];
    horarios: ProductoHorario[];
    subCategoriaFront: SubCategoria;
    side: boolean;
    observaciones?: string;
}

export interface ProductoHorario {
    id: number;
    horaInicio: string;
    horaTermino: string;
    dias: any[];
}

export interface Seleccion {
    id: number;
    nombre: string;
    requerido: boolean;
    multiple: boolean;
    minimo: number;
    maximo: number;
    checkeds: number;
    alternativas: Alternativa[];
    requeridoError? : boolean;
}

export interface Alternativa {
    id: number;
    nombre: string;
    precio: number;
    checked: any;
    cantidad: number;
    activo: boolean;
    disponible: boolean;
}

export interface Item {
    id: number;
    productoId: number;
    categoriaId: number;
    categoriaNombre: string;
    imagenName: string;
    imagenNameS3?: string;
    tiendaId: number;
    tiendaSlug: string;
    tiendaNombre: string;
    tiendaMultiPago: boolean;
    nombre: string;
    precio: number;
    descuento: number;
    precioFinal: number;
    cantidad: number;
    calificacion: number;
    observaciones: string;
    modificadoTienda: boolean;
    selecciones: {};
    alternativas: {};
}

export interface Pedido {
    id: number;
    mesaId: number;
    mesaNombre: string;
    usuarioId: number;
    usuarioNombre: string;
    usuarioCelular?: string;
    tiendaId: number;
    tiendaSlug: string;
    tiendaNombre: string;
    tiempoEntrega: number;
    tipoEntrega: string;
    session: string;
    monto: number;
    descuento: number;
    total: number;
    observaciones: string;
    estado: string;
    propio: boolean;
    medioPago: string;
    created: string;
    updated?: string;
    aceptado: string;
    rechazado: string;
    listo: string;
    entregado: string;
    pagado: string;
    cerrado: string;
    enviadoPOS: string;
    celular: string;
    modificadoTienda: boolean;
    solicitaPago: string;
    habilitaPago: string;
    fechaMoment: Moment;
    usuario: Usuario;
    tienda: Tienda;
    items: Item[];
    cupon?: any;
    simboloMoneda?: string;
    decimalesMoneda?: boolean;
    personas?: Number;
    medioPagoText?: string;
}

export interface Orden {
    id: number;
    codigo: string;
    cuponId: number;
    oneClickId: number;
    direccionId: any;
    direccionStringCompleto: string;
    imagenName: string;
    resenaModal: boolean;
    calificacion: number;
    itemsCount: number;
    comentarios: string;
    pedidoEstado: string;
    mesaId: number;
    mesaNombre: string;
    usuarioId: number;
    usuarioNombre: string;
    usuarioCelular?: string;
    tiendaId: number;
    tiendaSlug: string;
    tiendaSlugs: [];
    tiendaNombre: string;
    tiendaCorreoContactoApp: string;
    tiempoEntrega: number;
    tipoEntrega: string;
    session: string;
    monto: number;
    descuento: number;
    despacho: number;
    propina: number;
    total: number;
    observaciones: string;
    estado: string;
    propios: boolean;
    solicitarFactura: boolean;
    decimalesMoneda: boolean;
    simboloMoneda: string;
    decimales: string;
    locale: string;
    medioPago: string;
    formaPago: string;
    created: string;
    updated?: string;
    aceptado: string;
    rechazado: string;
    listo: string;
    entregado: string;
    pagado: string;
    cerrado: string;
    notificacionesSinLeer: number;
    estados: string[];
    despachoData: any;
    fechaMoment: Moment;
    usuario: Usuario;
    habitacion: Habitacion;
    erut: Erut;
    tienda: Tienda;
    pedidos: Pedido[];
    ordenes: Orden[];
    notificaciones: Notificacion[];
    color?: string;
    consumirLocal?: boolean,
    mostrarTiempo?: boolean,
    tipoDato?: string,
    hide?: boolean,
    solicitaPago: string;
    key?: string;
}

export interface Comuna {
    id: number;
    regionId: number;
    nombre: string;
}

export interface Direccion {
    id: number;
    regionId: number;
    comunaId: number;
    stringCorto: string;
    stringCompleto: string;
    calle: string;
    numero: string;
    comentarios: string;
    postal: string;
    lat: number;
    lng: number;
}


export interface GoogleAddress {
    postalCode: string;
    streetNumber: string;
    streetName: string;
    city: string;
    district: string;
    stateOrProvince: string;
    stateOrProvince2: string;
    stateOrProvince3: string;
    countryCode: string;
    country: string;
    addressLine1: string;
    addressLine2: string;
}

export interface Region {
    id: number;
    ordinal: string;
    nombre: string;
}

export interface Habitacion {
    id: number;
    nombre: string;
    rut: string;
    numero: string;
    imagenNameS3: string;
}

export interface Erut {
    id: number;
    nombre: string;
    razonSocial: string;
    rut: string;
    telefono: string;
    email: string;
    imagenNameS3: string;
}

export interface Usuario {
    id: number;
    nombre: string;
    apellido: string;
    email: string;
    celular: string;
    rut: string;
    fechaNacimiento: string;
    notificacionesSms: boolean;
    notificacionesPush: boolean;
    mesaIds: any[];
    tienda: Tienda;
    eruts: Erut[];
    avatar?: string;
}

export interface MercadoPagoCard {
    id: number;
    idMercadoPago: number;
    digitos: string;
    tipo: string;
}

export interface Oneclick {
    id: number;
    digitos: string;
    tipo: string;
}

export interface Cupon {
    id: number;
    monto: number;
    porcentaje: number;
    fechaVencimiento: string;
    fechaVencimientoMoment: Moment;
    despachoGratis: boolean;
    codigo?:string;
}

export interface Mesa {
    id: number;
    nombre: string;
    codigo: string;
}

export class Notificacion {
    id: number;
    mesa: Mesa;
    tienda: Tienda;
    mesaId: number;
    usuarioId: number;
    usuarioNombre: string;
    tiendaId: number;
    tiendaNombre?: string;
    tiendaSlug?: string;
    slugManual?: string;
    ordenId: number;
    session: string;
    body: string;
    leida: boolean;
    created: string;
}

export class Appearance {
    width: number;
    height: number;
    ratio: number;
    subs: any;
    subr: any;
    host: string;
    path: string;
    background: string;
    leftCol: string;
    rightCol: string;
    safari: boolean;
    lastUrl: string;
    enable: boolean;
}

export class PageView {
    datetime?: number;
    page?: any;
    store?: number;
    user?: any;
    product?: any;
    uid: string;
    device: string;
}

export class PageViewInfo {
    subscription: any;
    last: string;
    views: PageView[];
    uid: string;
    device: string;

    constructor(dv, ss) {
        this.uid = ss;
        this.device = dv;
    }
}

export interface Reserva {
    tienda: number;
    usuario: number;
    lugar: number;
    tipoReserva: number;
    fecha: string;
    duracion: number;
    estado: string;
    precio: number;
    observaciones: string;
    personas: number;
    telefono: string;
    nombre?: string;
    rut?: string;
    correo?: string;
    mesa?: number;
    aceptaRecibirInfo?: boolean;
} 
