import { Alternativa, Categoria, Item, Pedido, Producto, Seleccion, SubCategoria, Tienda, Usuario } from './../../interfaces/interfaces';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UiService } from 'src/app/services/ui.service';
import { ApiService } from 'src/app/services/api.service';
import { CarritoComponent } from '../carrito/carrito.component';
import { DetalleComidaComponent } from '../detalle-comida/detalle-comida.component';
import * as moment from 'moment';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-buscar-item',
  templateUrl: './buscar-item.component.html',
  styleUrls: ['./buscar-item.component.scss'],
})
export class BuscarItemComponent implements OnInit {
  @Input() productos : Producto[] = [];
  @Input() pedido : Pedido;
  search: string = "";
  itemsCarrito = 0;
  @Output() addProd = new EventEmitter<any[]>();
  orden = [];
  soundEffect;
  seeOrder : Boolean;
  @Input() tienda: Tienda;
  personas = 1;
  notificaciones: any = [];
  seeSubCategory = false; 
  @ViewChild('cantidad', { read: ElementRef, static:false }) cantidad: ElementRef;
  mesas: any;
  categoriaSeleccionada: Categoria;
  subCategoriaSeleccionada: SubCategoria;
  tab;
  fecha = moment().format("DD MMMM YYYY");
  usuario: Usuario;
  usuarioTienda;
  sessionId;
  tipoEntrega: string = null;
  tiposEntrega: any[]; 
  constructor(
    private dataService: DataService,
    private uiService: UiService,
    private apiService: ApiService,
    private modalController: ModalController,
  ) { }

  async ngOnInit() {
    //console.log(this.productos);

    this.soundEffect = await new Audio();
    this.soundEffect.src = 'assets/sounds/add.mp3';
    
  }
  ionViewDidEnter() {
    this.calcularItemsCarrito();
  }
  close(data){
    this.modalController.dismiss(data)
  }
  /*async add(producto: Producto) {
    console.log(producto);
    //this.addProd.emit([producto]);
    this.modalController.dismiss(producto);
  }*/
  
  async modificarProducto(producto: Producto) {
    console.log('cambio', producto);
    const loading = await this.uiService.presentLoading('Enviando datos...');
    const disponible = !producto.disponible;
    
    const result = await this.apiService.put('/jwt/producto/edit/' + producto.id, {disponible});
    console.log(result);
    await loading.dismiss();
    if (result) {
        await this.uiService.presentAlert('Modificación realizada exitosamente.');
        producto.disponible = disponible;
    }
  }

  async carrito(){
    this.modalController.dismiss(true);
    return;
    const modal = await this.modalController.create({
      component: CarritoComponent,
      swipeToClose: true,
      mode:'ios',
      presentingElement: await this.modalController.getTop(),
      componentProps: {
        pedido: this.pedido,
      }
    });
    await modal.present();

    const { data } = await modal.onDidDismiss();
    
    if (data) {
       
      this.close(data);
    }
  }
  


  async add(producto: Producto, modalprod) {
    this.seeOrder = true;
    if (producto.disponible==false){
      return;
    }
    console.log(producto);
    
    if (producto.selecciones.length > 0){
      this.openModalFood(producto, modalprod);
     
    } else {
       
 
      this.soundEffect.play();
      let existe = this.pedido.items.filter((item) => item.productoId == producto.id);
      
      if (existe.length > 0) {
        existe[0].cantidad += 1;
        this.calcularItemsCarrito();
      } else {
        this.agregaItem(producto)
      }

      
    }
  }

  async openModalFood(producto: Producto,modalprod) {
    const modal = await this.modalController.create({
      component: DetalleComidaComponent,
      swipeToClose: true,
      mode:'ios',
      presentingElement: await this.modalController.getTop(),
      componentProps: {
        producto
      }
    });
    await modal.present();

    const { data } = await modal.onDidDismiss();
    // Agregar a pedido
    if (data) {
      this.agregaItem(producto);
      
    }

    if (modalprod===true){
      
    }
  }

  calcularItemsCarrito(){
    let tempCantidad =  this.itemsCarrito;
    this.itemsCarrito = 0;
    for (const items of this.pedido.items) {
      
      this.itemsCarrito += items.cantidad;
    }
    if (this.cantidad) {
      this.animateValue(this.cantidad.nativeElement, tempCantidad, this.itemsCarrito,100);
    }
    console.log(this.itemsCarrito);
  }

  async agregaItem(producto: Producto){
    console.log(producto);
    
    let item : Item = {
      id: null,
      productoId: null,
      categoriaId: null,
      categoriaNombre: null,
      imagenName: null,
      tiendaId: null,
      tiendaSlug: null,
      tiendaNombre: null,
      tiendaMultiPago: false,
      nombre: null,
      precio: 0,
      descuento: 0,
      precioFinal: 0,
      cantidad: 1,
      observaciones: null,
      calificacion: null,
      modificadoTienda: null,
      selecciones: {},
      alternativas: {}
    };

    this.usuario = await this.dataService.getStorageData('usuario');
    this.sessionId = await this.dataService.getStorageData('sessionId');


    if (this.usuario) {
        this.pedido.usuarioId = this.usuario.id;
        this.pedido.usuario = this.usuario;
    } else {
      this.pedido.session = this.sessionId;
    }


    this.usuario = await this.dataService.getStorageData('usuario');
    // const mesaIds = this.usuario.mesaIds || [];

    // const mesas = await this.dataService.getStorageData('mesas');
    // const mesasArray = Object.keys(mesas).map((idx) => {
    //     return mesas[idx];
    // });

    // this.mesas = mesasArray.filter((mesa: Mesa, index) => {
    //     return (mesaIds.length === 0 || mesaIds.indexOf(mesa.id) !== -1);
    // });

    const tipoEntrega = this.tienda.servirPrepago ? 'servirPrepago' : 'servir';
    this.tipoEntrega = tipoEntrega;
    this.tiposEntrega = [tipoEntrega];

    this.tiposEntrega = [...new Set(this.tiposEntrega)];
    this.pedido.tipoEntrega = this.tipoEntrega;

    item.productoId = producto.id;
    item.precio = producto.precio;
    item.descuento = producto.descuento;
    item.precioFinal = producto.precioFinal;
    item.nombre = producto.nombre;
    item.imagenName = producto.imagenName;
    item.observaciones = producto.observaciones;
    item.tiendaId = this.tienda.id;
    item.tiendaSlug = this.tienda.slug;
    item.tiendaNombre = this.tienda.nombre;
    item.tiendaMultiPago = this.tienda.multiPago;

    const selecciones = {};
    const alternativas = {};
    producto.selecciones.forEach((seleccion: Seleccion, index: number) => {
        selecciones[seleccion.id] = {
            id: seleccion.id,
            checkeds: 0,
            value: null
        };
        seleccion.alternativas.forEach((alternativa: Alternativa, aIdx: number) => {
            alternativas[alternativa.id] = {
                id: alternativa.id,
                cantidad: alternativa.cantidad ? alternativa.cantidad : 0 ,
                seleccionNombre: seleccion.nombre,
                nombre: alternativa.nombre,
                precio: alternativa.precio
            };
        });
    });
    item.selecciones = selecciones;
    item.alternativas = alternativas;
    
    console.log(item);
    
    this.pedido.items.push(item);
    console.log(this.pedido);
    this.calcularItemsCarrito();
  }
  animateValue(obj, start, end, duration) {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      obj.innerHTML = Math.floor(progress * (end - start) + start);
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  }
}
