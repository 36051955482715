import {AfterViewInit, Component, EventEmitter, Output, ChangeDetectorRef} from '@angular/core';
import {Input, ElementRef} from '@angular/core';
import {Platform} from '@ionic/angular';
import {UtilService} from '../../services/util.service';
import {Subscription} from 'rxjs';
import {EventsService} from '../../services/events.service';

@Component({
    selector: 'app-qrmodal',
    templateUrl: './qrmodal.component.html',
    styleUrls: ['./qrmodal.component.scss'],
})
export class QrmodalComponent implements AfterViewInit {


    subscriptions: (Subscription | any)[] = [];
    bounceBack = true;
    current = 500;
    @Input() state = 'open';
    @Input() fixed = false;
    @Input() opened = false;
    dragging: any = null;

    @Output() modalState = new EventEmitter<any>();

    constructor(public element: ElementRef,
                private cdr: ChangeDetectorRef,
                public platform: Platform,
                private util: UtilService,
                private eventsService: EventsService) {

        this.subscriptions.push(this.eventsService.subscribe('banners:list', async (banners) => {
            if (!banners || banners.length === 0) {
                this.closeModal();
            }
        }));
    }

    toggleModal(ev) {
        const noDraggable = this.util.hasSomeParentTheClass(ev.target, 'no-draggable');
        if (!noDraggable && !this.fixed && !this.opened) {
            this.state === 'open' ? this.closeModal() : this.openModal();
        }
    }

    openModal() {
        this.state = 'open';
    }

    closeModal() {
        this.state = 'close';
        this.modalState.emit({state: 'close'});
    }

    ngAfterViewInit() {
        if (!this.fixed) {
            /*const hammer = new window['Hammer'](this.element.nativeElement);
            hammer.get('pan').set({direction: window['Hammer'].DIRECTION_VERTICAL});

            hammer.on('pan', (ev) => {
                this.handlePan(ev);
            });*/
        } else {
            this.dragging = {'min-height': '55px  !important;', height: '55px', 'padding-top': '25px'};
        }
        this.cdr.detectChanges();
    }


    handlePan(ev) {

        const noDraggable = this.util.hasSomeParentTheClass(ev.target, 'no-draggable');

        if (!noDraggable) {
            const newTop = ev.center.y;
            const newBottom = this.platform.height() - newTop;

            if (this.bounceBack && ev.isFinal) {
                if (newBottom - this.current > 10) {
                    this.state = 'open'
                    this.modalState.emit({state: 'close'});

                }

                if (this.current - newBottom > 10) {
                    this.state = 'close'
                    this.modalState.emit({state: 'close'});

                }

                if (newBottom < 500) {
                    this.dragging = null;
                }
                this.current = newBottom;
            } else {

                this.dragging = {'min-height': '70px  !important;', 'height': newBottom + "px"};  //null;
                this.state = 'open'
            }
        }

    }

    // @ViewChild('content') content: ElementRef
    //
    // @HostListener('document:click', ['$event'])
    // andClickEvent(event) {
    //     if (!this.content.nativeElement.contains(event.target) && !this.opened) {
    //         if (this.state === 'open') {
    //             this.closeModal()
    //         }
    //     }
    // }


}
