import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {Tienda} from '../../interfaces/interfaces';
import {Subscription} from 'rxjs';
import {EventsService} from '../../services/events.service';
import {DataService} from '../../services/data.service';
import * as moment from 'moment';
import {Router} from '@angular/router';

@Component({
    selector: 'app-public-tabs',
    templateUrl: './public-tabs.component.html',
    styleUrls: ['./public-tabs.component.scss'],
})
export class PublicTabsComponent implements OnInit {

    @Input() tienda: Tienda;
    @Input() mesa: any;
    @Output() garzon = new EventEmitter<any>();
    ordenes = [];

    subscriptions: (Subscription | any)[] = [];
    showball = false;
    animated = false;
    ordenActive = false;
    usuarioTienda: boolean;

    constructor(
        private dataService: DataService,
        private eventsService: EventsService,
        private router: Router) {
    }

    async ngOnInit() {
        this.showball = (await this.dataService.getItems()).length;
        this.animated = false;
        await this.hasOrden();
        this.subscriptions.push(this.eventsService.subscribe('items:update', async () => {
            let previus = this.showball;
            this.showball = (await this.dataService.getItems()).length;
            this.animated = !previus && this.showball;
        }));
        this.usuarioTienda = await this.dataService.getStorageData('usuarioTienda');
    }

    call() {
        this.garzon.emit();
    }

    async ngOnChanges() {
        await this.hasOrden();
    }

    async hasOrden() {
        console.log('public tabs getOrdenes');
        this.ordenes = await this.dataService.getOrdenes();
        let ordenesProceso = this.ordenes.filter(
            orden => (!orden.cerrado && moment.duration(moment().diff(orden.fechaMoment)).asHours() < 72));

        this.ordenActive = ordenesProceso.length > 0;
    }

    async goToPerfil() {
        const usuario = await this.dataService.getStorageData('usuario');
        // console.log(usuario);
        // return;
        const route = usuario ? '/secured/usuario-form' : '/login';

        await this.router.navigate([route]);
    }

    async goToWaitingList() {
        await this.router.navigate(['/waiting']);
    }
}
