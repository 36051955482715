import { Pedido, Item } from './../../interfaces/interfaces';
import { Component, Input, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { UiService } from 'src/app/services/ui.service';
import { ApiService } from 'src/app/services/api.service';
import { AlertController, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-carrito',
  templateUrl: './carrito.component.html',
  styleUrls: ['./carrito.component.scss'],
})
export class CarritoComponent implements OnInit {
  @Input() pedido: Pedido;
  total = 0;
  itemsCarrito = 0;
  tokenValidacion:any = null;
  constructor(
    private alertController: AlertController,
    private modalController: ModalController,
    private dataService: DataService,
    private apiService: ApiService,
    private uiService: UiService,

  ) { }

  ngOnInit() {
    console.log(this.pedido);
    this.calcularTotal();
    this.calcularItemsCarrito();
    this.tokenValidacion = this.uuidv4()
  }

  calcularItemsCarrito(){
    this.itemsCarrito = 0;
    for (const items of this.pedido.items) {
      this.itemsCarrito += items.cantidad;
    }
  }

  calcularTotal() {
    this.total = 0;
    for (const item of this.pedido.items) {
      this.total += item.precioFinal * item.cantidad;

      for (const alternativa in item.alternativas) {
        if (Object.prototype.hasOwnProperty.call(item.alternativas, alternativa)) {
          const element = item.alternativas[alternativa];
          if (element.cantidad > 0) {
            this.total += parseInt(element.precio);
          }
        }
      }
    }
  }

  eliminar(item: Item) {
    const filtered = this.pedido.items.filter((i) =>  i.productoId != item.productoId);
    this.pedido.items = filtered;
    this.calcularTotal();
    this.calcularItemsCarrito();
  }
  uuidv4() {
    // @ts-ignore
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        // tslint:disable-next-line:no-bitwise
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}
  async sendOrder() {
    const pedidoData = this.dataService.getPedidoData(this.pedido);
    console.log(pedidoData);
    const sessionId = await this.dataService.getStorageData('sessionId');
    pedidoData.garzon_sessionId = sessionId;
    const loading = await this.uiService.presentLoading('Enviando datos...');
    pedidoData.tokenValidacion = this.tokenValidacion ;
    const result = await this.apiService.post('/jwt/pedido/new', pedidoData);

    if (result) {
      console.log(result);
      this.modalController.dismiss(result);
    }
    await loading.dismiss();
  }

  async editarComentario(item){

    const alert = await this.alertController.create({
    
      header: 'Observaciones',
      inputs: [
        {
          name: 'obs',
          type: 'textarea',
          value: item.observaciones,
          placeholder: 'Observaciones'
        }
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'Guardar',
          handler: (d) => {
            item.observaciones = d.obs;
            console.log('Confirm Ok',d);
          }
        }
      ]
    });

    await alert.present();

  }

  async editarPersonas(){
    let radios = [];
        
    for (let index = 1; index < 21; index++) {
        radios.push( {
            name: 'radio'+index,
            type: 'radio',
            label: index,
            value: index,
            checked: this.pedido.personas == index ,
            handler: (s) => {
              console.log('Radio 1 selected',index,s);
            } 
          },)
    }
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Cantidad de comensales',
      inputs: radios,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'Guardar',
          handler: async (cantidad) => {
            this.pedido.personas = cantidad;
            console.log('Confirm Ok', cantidad);
          }
        }
      ]
    });
    await alert.present();
  }

  close() {
    this.modalController.dismiss();
  }
}
