import { ReservaService } from 'src/app/services/reserva.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { PubSub } from 'aws-amplify';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { Pedido, Item, Orden, Tienda, Usuario } from 'src/app/interfaces/interfaces';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { EventsService } from 'src/app/services/events.service';
import { UiService } from 'src/app/services/ui.service';
import { InformeClientePage } from '../informe-cliente/informe-cliente.page';
@Component({
  selector: 'app-pedido-detalle',
  templateUrl: './pedido-detalle.page.html',
  styleUrls: ['./pedido-detalle.page.scss'],
})
export class PedidoDetallePage implements OnInit {
  @Input() orden: Orden;
  @Input() ordenes:any = [];
  subscriptions: (Subscription | any)[] = [];
  @Input() lastUpdated:any = {
    orden:moment('2021-01-01 00:00').utc().format('YYYY-MM-DDTHH:mm'), 
    pedido:moment('2021-01-01 00:00').utc().format('YYYY-MM-DDTHH:mm'), 
    notificacion: moment('2021-01-01 00:00').utc().format('YYYY-MM-DDTHH:mm')
  };
  @Output() closeEvent = new EventEmitter<boolean>();
  @Input() isModal?: boolean = true;
  id;
  // pedido: Pedido;
  usuario: Usuario;
  tienda: any = {};
  pedidos: Pedido[] = [];
  sessionId;
  tiempoBox = false;
  forzarPago = false;
  decimales = '1.0-0';
  locale = 'es-CL';
  simboloMoneda = '$';
  tiempo = [5,10,15,20];
  activo:any = 0; 
  constructor(
    private modal: ModalController,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private dataService: DataService,
    private uiService: UiService,
    private navCtrl: NavController,
    private router: Router,
    private modalCtrl: ModalController,
    private alertController: AlertController,
    private reservaService: ReservaService,
    private eventsService: EventsService) {
}

async verCliente(pedido: any){
  console.log('pedido', pedido)
  if (pedido.usuarioId){

      const modal = await this.modalCtrl.create({
          component: InformeClientePage,
          cssClass: 'cliente-modal',
          swipeToClose: true, 
          // presentingElement: await this.modalController.getTop(),
          componentProps: {
               usuario: pedido
          }
      });
      await modal.present();
  }


}

    getIcon(item){
        if (item.pedidoEstado=='Recibido')
        return 'assets/icons/pending.svg';
        
        if (item.pedidoEstado=='Aceptado')
        return 'assets/icons/preparation.svg';

        if (item.pedidoEstado=='Cerrado')
        return 'assets/icons/pay_inactive.svg';
    }
    async eliminar(item) {
        console.log('eliminar', item)
        const alert = await this.alertController.create({
          cssClass: 'my-custom-class',
          header: 'Eliminar item ',
          message: 'Seguro de eliminar estos items?',
          buttons: [
            {
              text: 'Cancelar',
              role: 'cancel',
              cssClass: 'secondary',
              handler: (blah) => {
                console.log('Confirm Cancel: blah');
              }
            }, {
              text: 'Si',
              handler: async () => {
                  for (let index = 0; index < item.items.length; index++) {

                    item.items[index].cantidad  = 0;
                    const itemData = this.dataService.getItemData(item.items[index]);
                    const result = await this.apiService.put('/jwt/item/edit/' + item.items[index].id, itemData);
                      
                  }
               
                
              }
            }
          ]
        });
    
        await alert.present();
      }
    async editarCantidad(item,pedido) {
      if (this.ordenes[this.activo].pedidoEstado!="Recibido"){
        return;
      }
        console.log(item,pedido)
        let radios = [];
        
        for (let index = 0; index < 10; index++) {
            radios.push( {
                name: 'radio'+index,
                type: 'radio',
                label: index,
                value: index,
                checked: item.cantidad == index ,
                handler: (s) => {
                  console.log('Radio 1 selected',index,s);
                } 
              },)
        }
        const alert = await this.alertController.create({
          cssClass: 'my-custom-class',
          header: 'Editar Cantidad',
          inputs: radios,
          buttons: [
            {
              text: 'Cancelar',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                console.log('Confirm Cancel');
              }
            }, {
              text: 'Guardar',
              handler: async (cantidad) => {
                item.cantidad  = cantidad;
                const itemData = this.dataService.getItemData(item);
                const result = await this.apiService.put('/jwt/item/edit/' + item.id, itemData);
                console.log('Confirm Ok', cantidad);
              }
            }
          ]
        });
    
        await alert.present();
      }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription: Subscription, index: number) => {
            subscription.unsubscribe();
        });
    }

    async dataSubscribe() {
      console.log('this.ordenes[this.activo]', this.ordenes[this.activo])
        this.tienda = await this.dataService.getTienda(this.ordenes[this.activo].tiendaId) || await this.dataService.getStorageData('tienda');
        console.log(this.tienda);
        this.tiempoBox = this.tienda && this.tienda.mostrarTiempo && !this.ordenes[this.activo].rechazado &&
            ((!this.ordenes[this.activo].listo && this.ordenes[this.activo].tipoEntrega !== 'servir') || (!this.ordenes[this.activo].id && this.ordenes[this.activo].tipoEntrega === 'servir'));

        if (this.tienda.tiempoListo) {
            if (this.tiempoBox && !this.ordenes[this.activo].aceptado) {
                this.tiempoBox = false;
                this.ordenes[this.activo].tiempoEntrega = this.tienda.tiempoListo;
            }
        }

        this.subscriptions.push(this.eventsService.subscribe('ordenes:update', () => {
            this.loadData();
        }));

        this.subscriptions.push(this.eventsService.subscribe('pedido:rechazar', (pedido = null) => {
            console.log(pedido);
            const pedidos = pedido ? [pedido] : null;
            this.enviar('rechazado', pedidos);
        }));

        this.subscriptions.push(this.eventsService.subscribe('pedido:forzarPago', (pedido = null) => {
            // console.log(pedido);
            // const pedidos = pedido ? [pedido] : null;
            // this.enviar('rechazado', pedidos);
            this.forzarPago = true;
            this.tiempoBox = false;
        }));

        this.subscriptions.push(PubSub.subscribe(['pedidos:update:tienda-' + this.tienda.id]).subscribe({
            next: data => {
                console.log('Message received', data, data.value);
                this.reloadData();
            },
            error: error => {
                console.error(error);
                this.eventsService.publish('pubsub:error', error);
            },
            complete: () => console.log('Done'),
        }));
    }

    async loadData() {
        console.log('this.ordenes', this.ordenes)
        this.sessionId = await this.dataService.getStorageData('sessionId');
        this.usuario = await this.dataService.getStorageData('usuario');
        console.log('data-ver-pedido', this.orden);

        if (this.orden && !this.ordenes[this.activo].id) {
            const ordenId = this.ordenes[this.activo].mesaId + '-' + this.ordenes[this.activo].pedidoEstado;
            this.orden = await this.dataService.getOrden(ordenId);
            if (!this.orden) {
                console.log('Orden no existe', ordenId);
                return;
            }
            this.ordenes[this.activo].fechaMoment = moment(this.ordenes[this.activo].created);
        }

        // console.log(this.ordenes[this.activo].medioPago);
        // this.pedido = await this.dataService.getPedido(this.id);
        // this.pedidos = this.pedido.pedidos;
        // this.pedidos.reverse();
        // console.log(this.pedido, this.pedidos);

        // if (!this.pedido) {
        //   this.uiService.presentToast('Pedido no existe');
        //   console.log('nav6');
        //   this.navCtrl.navigateBack('pedido-lista');
        //   return;
        // }
        // else{
        //   if (this.pedidos.length === 0) {
        //     this.uiService.presentToast('Pedido sin pedidos');
        //     console.log('nav5');
        //     this.navCtrl.navigateBack('pedido-lista');
        //     return;
        //   }
        //   else{
        //     this.tienda = await this.dataService.getTienda(this.pedido.tiendaId);
        //   }
        // }

        // this.pedido.monto = 0;
        // this.pedidos.forEach((pedido: Pedido, aIdx: number) => {
        //   if (this.usuario){
        //     if (pedido.usuarioId === this.usuario.id) {
        //       pedido.propio = true;
        //     }
        //   }
        //   else if (pedido.session === this.sessionId){
        //     pedido.propio = true;
        //   }
        //
        //   // if (pedido.propio || !this.pedido.propios){
        //   //   this.pedido.monto += pedido.total;
        //   // }
        // });

        // this.decimales = await this.dataService.getStorageData('decimales');
        // this.locale = await this.dataService.getStorageData('locale');
        // this.simboloMoneda = await this.dataService.getStorageData('simboloMoneda');

        this.decimales = this.tienda.decimalesMoneda ? '1.2-2' : '1.0-0';
        this.locale = this.tienda.decimalesMoneda ? 'en-US' : 'es-CL';
        this.simboloMoneda = this.tienda.simboloMoneda || '$';

        let ordenes = await this.dataService.getOrdenes();
        for (let index = 0; index < ordenes.length; index++) {
             for (let index2 = 0; index2 < this.ordenes.length; index2++) {
                 if (this.ordenes[index2].key == ordenes[index].key && this.ordenes[index2].pedidoEstado == ordenes[index].pedidoEstado){
                    this.ordenes[index2] =  ordenes[index]
                 }
                 
             }
            
        }
    }

    async doRefresh($event: any) {
        await this.reloadData();
        $event.target.complete();
    }

    async reloadData() {
        // const loading = await this.uiService.presentLoading('Cargando datos...');
        const data: any = this.usuario ? {usuarioId: this.usuario.id} : {sessionId: this.sessionId};
        // TODO: arreglar como traerme todos los pedidos de la mesa para que los pueda pagar
        // data.mesa = this.pedido ? this.pedido.mesaId : null;
        console.log(data);
        await this.apiService.post('/jwt/pedido/lite', data);
        // loading.dismiss();
    }

    async repetirPedido() {
        const loading = await this.uiService.presentLoading('Cargando datos...');
        await this.apiService.get('/jwt/tienda/' + this.ordenes[this.activo].tiendaSlug);
        const items = [];
        // TODO: no agregar item si el productos no existe, revisar alternativas tambien
        this.pedidos.forEach((pedido: Pedido, aIdx: number) => {
            pedido.items.forEach((item: Item, iIdx: number) => {
                item.id = iIdx;
                items.push(item);
            });
        });
        await this.dataService.setStorageData('items', items);
        await loading.dismiss();
        await this.router.navigate(['/item-lista']);
    }

    moment(fecha: string) {
        return moment(fecha);
    }

    async dismiss() {
        // using the injected ModalController this page
        // can "dismiss" itself and optionally pass back data

        if (this.isModal) {
          await this.modalCtrl.dismiss({
              dismissed: true
          });
        }else {
          this.closeEvent.emit(true);
        }
    }

    // tiempoButton(minutos: number) {
    //     this.ordenes[this.activo].tiempoEntrega = minutos;
    // }

    // medioPagoButton(medioPago) {
    //     this.ordenes[this.activo].medioPago = 'Presencial: ' + medioPago;
    // }

    async enviar(estado, pedidos = null) {
        // console.log(pedidos);
        // console.log(moment().format());
        // console.log(moment().toISOString());
        //
        // return;
        const now = moment().format();
        if (!pedidos) {
            pedidos = this.ordenes[this.activo].pedidos;
        }

        if (estado === 'pagado' && !this.ordenes[this.activo].id && !this.forzarPago && !this.tienda.pagoForzosoSinClave) {
            await this.modalClave('confirmar el pago para', 'pedido:forzarPago');
            return;
        }
        if (estado === 'pagado' && !this.ordenes[this.activo].id && !this.forzarPago && this.tienda.pagoForzosoSinClave==true) {
          this.forzarPago = true;
          this.tiempoBox = false;
          await this.uiService.presentToast('Seleccione Método de Pago');
          return;
      }
       
        // console.log(pedidos, this.ordenes[this.activo].pedidos);
        // return;

        // let first = true;
        const ids = [];
        let pedido = null;
        for (const oPedido of pedidos) {
            if (!oPedido.rechazado) {
                ids.push(oPedido.id);
                pedido = oPedido;
            }
        }

        if (!pedido) {
            return;
        }

        const pedidoData: any = {};

        if (this.ordenes[this.activo].medioPago && this.ordenes[this.activo].tipoEntrega === 'servirPrepago') {
            if (!this.ordenes[this.activo].pagado) {
                pedidoData.pagado = true;
            }
            pedidoData.medioPago = this.ordenes[this.activo].medioPago;
        }

        if (estado.startsWith('aceptado')) {
            pedidoData.aceptado = true;
            pedidoData.tiempoEntrega = this.ordenes[this.activo].tiempoEntrega;
            if (estado === 'aceptado-pos') {
                pedidoData.pos = true;
            }
        } else if (estado === 'rechazado') {
            pedidoData.rechazado = true;
            pedidoData.cerrado = true;
        } else if (estado === 'pagado') {
            pedidoData.pagado = true;
            pedidoData.cerrado = true;
        } else if (estado === 'listo') {
            pedidoData.listo = true;
        } else if (estado === 'entregado') {
            pedidoData.entregado = true;
            pedidoData.cerrado = true;
        } else if (estado === 'habilitarPago') {
            pedidoData.habilitarPago = true;
        } else {
            return;
        }

        if (estado === 'pagado') {
            pedidoData.medioPago = this.ordenes[this.activo].medioPago;
        }

        const data = {ids, pedidoData};
        console.log(data);
        
        const loading = await this.uiService.presentLoading('Enviando datos...');
        const result = await this.apiService.put('/jwt/pedido/edit/estados?lite=true', data);
        


        console.log(result);
        if (result) {
            // first = false;
            // const response = await this.dataService.getData('postResponse');
            // console.log(response);
            // await this.dataService.storePedidos(response.pedidos);
            await this.uiService.presentToast('Enviado exitosamente');

            let notis = [];
            for (let index = 0; index < this.ordenes[this.activo].notificaciones.length; index++) {
                notis.push(this.ordenes[this.activo].notificaciones[index])
                
            }
            if (notis.length>0)
            await this.apiService.post('/jwt/notificacion/leer', notis);

            await this.dismiss();
        }
        await loading.dismiss();

    }

    async rechazar() {
        if (!this.ordenes[this.activo].aceptado) {
            await this.uiService.presentAlertConfirm('Está seguro que desea rechazar este pedido? Está acción no se puede revertir.', 'Atención', 'pedido:rechazar');
        } else {
            await this.rechazarPedido();
        }
    }

    async rechazarPedido(pedido = null) {
        await this.modalClave('rechazar', 'pedido:rechazar', pedido);
    }

    async modalClave(accion = 'rechazar', event = 'pedido:rechazar', pedido = null) {
        let header = 'Confirmar Pago';
        if (accion === 'rechazar') {
            header = 'Rechazar Pedido';
        }

        const message = 'Está seguro que desea ' + accion + ' este pedido? Está acción no se puede revertir.';
        await this.uiService.presentClaveSupervisor(this.tienda, message, header, event, pedido);
    }

    isCerrable() {
        const orden = this.ordenes[this.activo];
        const tienda = this.tienda;

        const now = moment();
        let isCerrable = false;
        // console.log(tienda);
        if (orden.aceptado && !orden.pagado && !orden.rechazado && tienda.tiempoCierreManual > 0) {
            const aceptado = moment(orden.aceptado).add(tienda.tiempoCierreManual, 'minutes');
            // console.log(aceptado, now);
            if (now.isAfter(aceptado)) {
                isCerrable = true;
            }
        }

        return isCerrable;
    }

    isCambioColor(orden: Orden, tienda: Tienda) {
        return this.dataService.isCambioColor(orden, tienda);
    }

    async itemTapped(item: Item) {
        if (this.ordenes[this.activo].id) {
            return;
        }

        await this.dismiss();
        await this.dataService.setStorageData('item' + item.id, item);
        await this.dataService.setStorageData('ordenEditar' + item.id, this.ordenes[this.activo].mesaId + '-' + this.ordenes[this.activo].pedidoEstado);
        console.log(item);
        await this.router.navigate(['item-form/item/' + item.id]);
    }
  setOrdenActiva(i){
    this.activo = i
  }
   
  async ngOnInit() {
    console.log(this.ordenes)
    for (let index = 0; index < this.ordenes.length; index++) {
       
       let items = 0;
       for (let index2 = 0; index2 < this.ordenes[index].pedidos.length; index2++) {
        items = items + this.ordenes[index].pedidos[index2].items.length;
         
       }
       this.ordenes[index].totalItems = items;
      
    }

    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    await this.dataSubscribe();
    await this.loadData();

  }

  close(){
    if (this.isModal) {
      this.modal.dismiss();
    }else {
      this.closeEvent.emit(true);
    }

  }

  masTiempo(){
    for (let i = 0; i < 4; i++) {
        let last = this.tiempo[this.tiempo.length -1];
        this.tiempo.push(last + 5);
        this.tiempo.shift();
    }
  }

  menosTiempo(){
    for (let i = 0; i < 4; i++) {
        let first = this.tiempo[0];
        if (first == 5) return;
        this.tiempo.push(first - 5);
        this.tiempo.shift();
    }
  }

  async customTime(){
    const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        header: 'Tiempo de entrega',
        inputs: [
          {
            name: 'tiempo',
            type: 'number',
          }
        ],
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              console.log('Confirm Cancel');
            }
          }, {
            text: 'Confirmar',
            handler: ({tiempo}) => {
      
                if (!isNaN(tiempo))
                  this.ordenes[this.activo].tiempoEntrega = parseInt(tiempo);
                console.log(this.ordenes);
                
            }
          }
        ]
      });
  
      await alert.present();
  }

  async productoEntregado(producto, estado){
    console.log(producto.estado);
    const resp = await this.apiService.post(`/jwt/tienda/item/${producto.id}`, {estado: producto.estado == estado ? null : estado});
    if (resp == true){
      producto.estado = producto.estado == estado ? null : estado;
    }
  }
}
