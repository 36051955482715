import { DetalleReservaComponent } from './../detalle-reserva/detalle-reserva.component';
import { ListaReservasClienteComponent } from './../lista-reservas-cliente/lista-reservas-cliente.component';
import { ReservaService } from './../../services/reserva.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { FormularioReservaComponent } from '../formulario-reserva/formulario-reserva.component';
import { ModalController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { ApiService } from 'src/app/services/api.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';

export interface Data {
  movies: string;
}

@Component({
  selector: 'app-tabla-reservas',
  templateUrl: './tabla-reservas.component.html',
  styleUrls: ['./tabla-reservas.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TablaReservasComponent implements OnInit {
  @Input() type : string;
  @ViewChild(DatatableComponent) tableComp: DatatableComponent;
  latestWidthOfTable: any;
  public data: Data;
  public columns: any;
  public rows: any;
  mesas: any;
  lugares: any;
  columnsAux = [];

  selected: any[] = [];
  day: string;
  fecha: any;
  id: string;
  tienda: any;
  slug: string;
  constructor(
    private http: HttpClient,
    private dataService: DataService,
    private reservaService: ReservaService,
    private activatedRoute: ActivatedRoute,
    private modalController: ModalController,
    private apiService: ApiService,
  ) { 
    this.columns = [
      { name: 'name' }
    ];
  }

  async ngOnInit() {
    this.slug = this.activatedRoute.snapshot.paramMap.get('slug');
    // this.id = this.activatedRoute.snapshot.paramMap.get('id');
    // this.tienda = await this.dataService.getTienda(this.id);
    const resp = await this.apiService.getTiendaSlug(this.slug);
    this.tienda = resp.tiendas[0];
    this.id = this.tienda.id;
    this.day = moment().format("DD-MM-yyyy");

    if (this.type == 'mesas') {
      this.mesas = await this.reservaService.getHorasTipoDisponibles(parseInt(this.id), this.day, this.type);
      this.rows = this.mesas.mesas;
    } else {
      this.lugares = await this.reservaService.getHorasTipoDisponibles(parseInt(this.id), this.day, this.type);
      this.rows = this.lugares.lugares;
    }
    console.log(this.rows);

    for (const key in this.rows[0]) {
      if (key !== "id" && key !== "name") {
        this.columns.push({name: key})
        this.columnsAux.push(key);
      }
    }

    console.log(this.columnsAux);
    
  }

  ngAfterContentChecked() {
    if (this.tableComp && this.tableComp.element.clientWidth !== this.latestWidthOfTable) {
      this.latestWidthOfTable = this.tableComp.element.clientWidth;
      this.tableComp.recalculate();
      window.dispatchEvent(new Event('resize'));
    }
 }

  onSelect(row, col) {
    const data = {id:row.id, name:row.name};
    const date = this.getFullDate(col);
    console.log(date);
    const aux = row[col];
    console.log(aux);
    
    if (aux.disponible) {
      this.newReserva(data, date);
    } else {
      aux.reserva.fecha = this.fecha;
      this.openReserva(aux.reserva);
    }
  }

  getFullDate(time){

    this.fecha = moment().format('YYYY-MM-DD');
    // this.fecha = new Date(this.fecha + ' ' +  time);
    this.fecha =  moment(`${this.fecha} ${time}`, 'YYYY-MM-DD HH:mm').format();
    console.log(this.fecha);
    return this.fecha;
    
    // const dateObj = this.fecha.toDate();
    // const dateStr = dateObj.toISOString().split('T').shift();
    // console.log(dateStr);
    
    // return moment(dateStr + ' ' + time).toDate();
  }

  
  async newReserva(value, date){
    const modal = await this.modalController.create({
      component: FormularioReservaComponent,
      swipeToClose: true,
      mode:'ios',
      presentingElement: await this.modalController.getTop(),
      componentProps:{
        tienda: this.tienda,
        fecha: date,
        tipo: this.type,
        data: value,
      }
    });
    await modal.present();
    
    const { data } = await modal.onDidDismiss();
    
    if (data.status == "reload") {
      this.ngOnInit();
    }

  }

  async openReserva(reserva){
    const modal = await this.modalController.create({
      component: DetalleReservaComponent,
      swipeToClose: true,
      mode:'ios',
      presentingElement: await this.modalController.getTop(),
      componentProps:{
        reserva: reserva,
        tipo: this.type
      }
    });
    await modal.present();

    const { data } = await modal.onDidDismiss();
    
    if (data.status == "reload") {
      this.ngOnInit();
    }

  }

  onActivate(event) {
    console.log('Event: activate', event);
  }

}
