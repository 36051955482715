import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-gamificacion-alerta',
  templateUrl: './gamificacion-alerta.component.html',
  styleUrls: ['./gamificacion-alerta.component.scss'],
})
export class GamificacionAlertaComponent {
  @Input() win: boolean;
  @Input() tienda: any;
  @Input() cupon: any;
  constructor() { }

}
