import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { createAnimation, ModalController } from '@ionic/angular';
import { PedidoDetallePage } from 'src/app/pages/pedido-detalle/pedido-detalle.page';

@Component({
  selector: 'app-card-notification',
  templateUrl: './card-notification.component.html',
  styleUrls: ['./card-notification.component.scss'],
})
export class CardNotificationComponent implements OnInit {
  @Input() notificacion : any;
  @Input() isModal : any;
  @Output() seeOrderEvent = new EventEmitter<any[]>();
  constructor(private modalController: ModalController,) { }

  ngOnInit() {
    console.log(this.notificacion);
    
    // const animationArray = [];
    // const elemArray = document.querySelectorAll('.card');
    // for (let i = 0; i <= elemArray.length; i++){
    //   const animation = createAnimation('')
    //   .addElement(elemArray[i])
    //   .easing('cubic-bezier(0, 0.55, 0.45, 1)')
    //   .duration(800)
    //   .delay(i * 320)
    //   .fromTo('transform', 'translateX(100%)', 'translateX(0%)');

    //   animationArray.push(animation);
    // }
    // animationArray.forEach((animation: Animation) => {
    //   animation.play();
    // });

  }

  async seeOrder(){
    console.log('click');
    
    if (this.isModal) {
      const modal = await this.modalController.create({
        component: PedidoDetallePage,
        swipeToClose: true,
        mode:'ios',
        presentingElement: await this.modalController.getTop(),
        componentProps: {
          'ordenes':  [this.notificacion.orden] ,
          //lastUpdated: this.lastUpdated
        }
      });
      await modal.present();
    } else {
      this.seeOrderEvent.emit([this.notificacion.orden]);
    }
  }

}
