import { Injectable } from '@angular/core';
import * as moment from "moment";
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {Platform} from "@ionic/angular";

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  subscriptions: (Subscription | any)[] = [];

  constructor(private platform: Platform) { }

  async checkTour(tt,cb){
    const mindate = moment().add('days', 30).toDate().getTime().toString();
    if (window.localStorage) {
      const tour = window.localStorage.getItem(tt);
      if (!tour || tour>mindate) //cb();
      await window.localStorage.setItem(tt, moment().toDate().getTime().toString());
    }
  }

  getOffset( el ) {
    var _x = 0;
    var _y = 0;
    while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
      _x += el.offsetLeft - el.scrollLeft;
      _y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
    }
    return { top: _y, left: _x };
  }

  hasSomeParentTheClass(element, classname) {
    if (element.className && element.className.split(' ').indexOf(classname) >= 0) return true;
    return element.parentNode && this.hasSomeParentTheClass(element.parentNode, classname);
  }

  async showWelcome(cb){


    const width = this.platform.width();
    const height = this.platform.height();
    const ratio = width / height;
    const isDesktop = width >= 900 && ratio > 1;

    console.log("URL PRE UTILS",window.location.pathname)

    let tour = null;

    const just = moment().add( -3,'seconds').toDate().getTime().toString();

    const mindate = moment().add(-30,'days').toDate().getTime().toString();
    if (window.localStorage) {
      tour = window.localStorage.getItem('welcometour');
      if(!tour) await window.localStorage.setItem('welcometour', moment().toDate().getTime().toString());
    }
    if ((!tour || tour<mindate || tour>just ) && !isDesktop){
      cb();
    }

  }

}
