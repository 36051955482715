import { ReservaService } from 'src/app/services/reserva.service';
import { ModalController } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';
import { Reserva } from 'src/app/interfaces/interfaces';
// import { CallNumber } from '@ionic-native/call-number/ngx';

@Component({
  selector: 'app-detalle-reserva',
  templateUrl: './detalle-reserva.component.html',
  styleUrls: ['./detalle-reserva.component.scss'],
})
export class DetalleReservaComponent implements OnInit {
  @Input() reserva: any;
  @Input() tipo: string;
  constructor(
    private modalController: ModalController,
    private reservaService: ReservaService,
    // private callNumber: CallNumber
  ) { }

  ngOnInit() {
    console.log(this.reserva);
    
  }

  async close(status){
    await this.modalController.dismiss({status});
  }

  async delete(id){
    await this.reservaService.deleteReserva(id);
    await this.close('reload');
  }

  async checkin(id){
    await this.reservaService.editarEstado(id,'completado');
    await this.close('reload');
  }

  

  async call(telefono){
    // this.callNumber.callNumber("18001010101", true)
    //   .then(res => console.log('Launched dialer!', res))
    //   .catch(err => console.log('Error launching dialer', err));
  }
}
