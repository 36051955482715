import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Tienda} from "../../interfaces/interfaces";
import {TranslateService} from "@ngx-translate/core";
import {UtilService} from "../../services/util.service";

@Component({
  selector: 'app-tour-pedido',
  templateUrl: './tour-pedido.component.html',
  styleUrls: ['./tour-pedido.component.scss'],
})
export class TourPedidoComponent implements OnInit {

  @Output() onclick = new EventEmitter<any>();
  text: string;
  showing= false;
  tour = 'tour_pedido'

  constructor(private translate: TranslateService, private util: UtilService) {}

  async ngOnInit() {
    await this.util.checkTour(this.tour,this.callback.bind(this))
  }

  callback(){
    this.showing = true;
    this.translate.get(this.tour).subscribe((translated: string) => {
      this.text = translated;
    });
  }

  action() {
    this.showing = false;
    this.onclick.emit(false);
  }

}
